import { useEffect, useState } from "react";
import clsx from "clsx";

import { isEqual } from "lodash";
import BigNumber from "bignumber.js";
import { GasPrice, SigningStargateClient } from "@cosmjs/stargate";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import {
  selectAssets,
  selectChains,
  selectContracts,
  selectGRVT8Balance,
  selectIsWalletLoading,
  selectUser,
  selectWalletInfo,
} from "@axvdex/state/wallet/walletSelectors";
import { loadState, WHITE_LIST_PERSISTED_STATE_KEYS } from "@axvdex/state/persist";
import {
  executeClaimAirdrop,
  executeGRVT8BurnAction,
  executeIbcTransfer,
  updateGRVT8Balance,
} from "@axvdex/state/wallet/walletThunks";
import useLanguage from "@axvdex/hooks/useLanguage";
import { IContract } from "@axvdex/utils/interfaces";
import { IWalletInfo } from "@axvdex/state/wallet/initialState";
import { hasPermitStored } from "@axvdex/utils/localStorage";
import rpcClientQuerySmartContractWrapper from "@axvdex/utils/rpcClientQuerySmartContractWrapper";
import styles from "../styles/Dashboard.module.scss";
import CustomLoader from "./common/CustomLoader";
import Button from "./common/Button";
import PollModal from "./modals/PollModal";
import CustomModal from "./common/CustomModal";

function DashboardAirdrop() {
  const [isLoading, setIsLoading] = useState(true);
  const [showNotConnectedData, setshowNotConnectedData] = useState(false);
  const walletInfo = useAppSelector(selectWalletInfo, isEqual);
  const isLoadingWallet = useAppSelector(selectIsWalletLoading);
  const contracts = useAppSelector(selectContracts);
  const [airdropsBalance, setAirdropsBalance] = useState({});

  useEffect(() => {
    if (!isLoadingWallet && !walletInfo.isConnected) {
      setIsLoading(false);
      setshowNotConnectedData(true);
    } else {
      setshowNotConnectedData(false);
      setIsLoading(true);
    }
  }, [walletInfo.isConnected, isLoadingWallet]);

  useEffect(() => {
    if (!isLoadingWallet && walletInfo.isConnected) {
      Object.values(contracts).forEach(async (contract: IContract) => {
        if (contract.label.includes("airdrop") && !contract.config?.is_stopped) {
          getAirdropBalance(contract.address, contract.contextChainId);
        }
      });
    }
  }, [walletInfo.isConnected, isLoadingWallet, contracts]);

  const getAirdropBalance = async (address, chainId) => {
    setIsLoading(true);
    if (!walletInfo.connectedChains[chainId]) {
      setIsLoading(false);
      return;
    }

    const response = await rpcClientQuerySmartContractWrapper(
      walletInfo.connectedChains[chainId].signingClient,
      address,
      {
        claim_info: { address: walletInfo.connectedChains[chainId].address },
      }
    );
    setAirdropsBalance(prevState => ({ ...prevState, [address]: response }));
    setIsLoading(false);
  };

  if (!walletInfo.isConnected && !loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet)) return null;
  return (
    <section style={{ padding: "0.75rem", paddingLeft: "1.25rem", paddingRight: "1.25rem" }}>
      {isLoading ? (
        <CustomLoader size="xs" />
      ) : (
        <AirdropComponent
          airdropsBalance={airdropsBalance}
          airdrops={Object.values(contracts)
            .filter(
              contract =>
                contract.label.includes("airdrop") &&
                !contract.config?.is_stopped &&
                walletInfo.connectedChains[contract.contextChainId]
            )
            .filter(contract =>
              contract.extraFields?.contextEnvs?.includes(process.env.REACT_APP_SUB_MODE?.toLowerCase())
            )}
          walletInfo={walletInfo}
          getAirdropBalance={getAirdropBalance}
        />
      )}
    </section>
  );
}

const AirdropComponent = ({
  airdropsBalance,
  airdrops,
  walletInfo,
  getAirdropBalance,
}: {
  airdropsBalance: {
    [key: string]: {
      address: string;
      pending: string;
      claimed?: string;
    };
  };
  airdrops: IContract[];
  walletInfo: IWalletInfo;
  getAirdropBalance: any;
}) => {
  const { i18 } = useLanguage();
  const user = useAppSelector(selectUser);
  const assets = useAppSelector(selectAssets);
  const AXV = Object.values(assets).find(asset => "AXV" === asset.symbol);
  const [pollModalOpen, setPollModalOpen] = useState(false);
  const [airdropsModalOpen, setAirdropsModalOpen] = useState(false);

  const getTotalPendingBalances = () =>
    Object.values(airdropsBalance)
      .reduce((acc, airdrop) => {
        return acc.plus(airdrop.pending);
      }, BigNumber(0))
      .toString(10);

  const getTotalClaimedBalances = () => {
    let claimed = null;
    Object.values(airdropsBalance).forEach(airdropBalance => {
      airdropBalance.claimed && (claimed = BigNumber(claimed || 0).plus(airdropBalance.claimed));
    });
    return claimed;
  };

  const ClaimComponent = () => {
    return (
      <>
        {"0" === getTotalPendingBalances() && !getTotalClaimedBalances() ? (
          <div style={{ alignSelf: "center", marginLeft: "1em" }}>
            <div className="buttonContainer">
              <Button
                btnColor="dark-medium"
                title={i18("Claim Airdrop", "dashboard.airdrop.buttonTitle")}
                onClick={() => {
                  setAirdropsModalOpen(true);
                }}
              >
                <span>{i18("Show airdrop list", "dashboard.airdrop.showList")}</span>
              </Button>
            </div>
          </div>
        ) : "0" !== getTotalPendingBalances() ? (
          <div style={{ alignSelf: "center", marginLeft: "1em" }}>
            <div className="buttonContainer">
              <Button
                btnColor="gradient"
                title={i18("Claim Airdrop", "dashboard.airdrop.buttonTitle")}
                onClick={() => {
                  setAirdropsModalOpen(true);
                }}
              >
                <>
                  {i18(
                    `Claim: ${
                      AXV &&
                      BigNumber(getTotalPendingBalances()).div(Math.pow(10, AXV.decimals)).decimalPlaces(2).toString(10)
                    } AXV`,
                    "dashboard.airdrop.buttonText",
                    {
                      amount:
                        AXV &&
                        BigNumber(getTotalPendingBalances())
                          .div(Math.pow(10, AXV.decimals))
                          .decimalPlaces(2)
                          .toString(10),
                    }
                  )}
                </>
              </Button>
            </div>
          </div>
        ) : (
          <div style={{ alignSelf: "center", marginLeft: "1rem" }}>
            <div className="buttonContainer">
              <Button
                btnColor="dark-medium"
                title={i18("Claimed", "dashboard.airdrop.successfullyClaimed")}
                onClick={() => {
                  setAirdropsModalOpen(true);
                }}
              >
                <>
                  {i18(
                    "Claimed: " +
                      BigNumber(getTotalClaimedBalances())
                        .div(Math.pow(10, AXV.decimals))
                        .decimalPlaces(2)
                        .toString(10) +
                      " AXV",
                    "dashboard.airdrop.claimedAXV",
                    {
                      amount: BigNumber(getTotalClaimedBalances())
                        .div(Math.pow(10, AXV.decimals))
                        .decimalPlaces(2)
                        .toString(10),
                    }
                  )}
                </>
              </Button>
            </div>
          </div>
        )}
      </>
    );
  };

  const suggestAXVTokenToWallet = async () => {
    const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
    if (!window[walletExtension]) {
      // throw new Error("Please install " + walletExtension + " extension");
      console.log(
        i18(
          'Use "Connect Wallet" before trying to add tokens to wallet view',
          "help.tokens.addTokensToWallet.btn.action.log"
        )
      );
      return;
    }
    const walletExtensionClient =
      "cosmostation" === walletExtension ? window.cosmostation.providers.keplr : window[walletExtension];
    const suggestToken =
      "leap" === walletExtension ? walletExtensionClient.suggestCW20Token : walletExtensionClient.suggestToken;
    try {
      const chainIds = [];
      for (const chainId in walletInfo.connectedChains) {
        chainIds.push(chainId);
      }
      await suggestToken(AXV.contextChainId, AXV.address);
    } catch {
      console.log("Wallet closed, suggestToken() aborted");
    }
  };

  if (!hasPermitStored(walletInfo)) return null;
  return (
    <div className="flexbox" style={{ fontSize: "1rem", marginRight: "1em" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            require(`../assets/tokens/logo-axv.svg`).default
          }
          alt={i18("AXV", "symbol.axv")}
        />
        <span style={{ marginLeft: "1em", marginRight: ".5em" }}>
          {i18("Airdrop", "dashboard.airdrop.pendingAirdrop")}
        </span>
        {!user.isUserDetailsLoad ? (
          <CustomLoader size="xs" />
        ) : !user.surveys || !user.surveys[0] ? (
          <Button
            style={{ marginLeft: "1em" }}
            btnColor="gradient"
            title={i18("Check Eligibility", "dashboard.airdrop.checkEligibilityText")}
            onClick={async () => {
              await suggestAXVTokenToWallet();
              setPollModalOpen(true);
            }}
          >
            <span style={{ marginRight: "0.5em" }}>
              {i18("Check Eligibility", "dashboard.airdrop.checkEligibilityText")}
            </span>
          </Button>
        ) : (
          <ClaimComponent />
        )}
      </div>
      <AirdropsModal
        airdropsModalOpen={airdropsModalOpen}
        setAirdropsModalOpen={setAirdropsModalOpen}
        airdrops={airdrops}
        airdropsBalance={airdropsBalance}
        getAirdropBalance={getAirdropBalance}
      />
      <PollModal pollModalOpen={pollModalOpen} setPollModalOpen={setPollModalOpen} />
    </div>
  );
};

const AirdropsModal = ({
  airdropsModalOpen,
  setAirdropsModalOpen,
  airdrops,
  airdropsBalance,
  getAirdropBalance,
}: any) => {
  const { i18 } = useLanguage();
  const dispatch = useAppDispatch();
  const walletInfo = useAppSelector(selectWalletInfo);
  const chains = useAppSelector(selectChains);
  const assets = useAppSelector(selectAssets);
  const [loadings, setLoadings] = useState({});
  const [elegibleToIBCTransfer, setElegibleToIBCTransfer] = useState({});
  const [exchangeAssetAirdropsBalance, setExchangeAssetAirdropsBalance] = useState({});

  const handleClaim = async (airdrop: any, amount?: string) => {
    setLoadings({ ...loadings, [airdrop.address]: true });
    walletInfo &&
      (await dispatch(
        executeClaimAirdrop({
          chainId: airdrop.contextChainId,
          airdropAddr: airdrop.address,
          axvAddr: airdrop.config.token,
          exchangeAsset: airdrop.config?.exchange_asset,
          exchangeAssetAmount: amount,
          i18,
        })
      ));
    await getAirdropBalance(airdrop.address, airdrop.contextChainId);
    setLoadings({ ...loadings, [airdrop.address]: false });
  };

  useEffect(() => {
    if (airdropsModalOpen) getExchangeAssetAirdropsBalance();
  }, [airdropsModalOpen]);

  const getExchangeAssetAirdropsBalance = async (airdrop?: any) => {
    if (!airdrop) {
      Object.values(airdrops).forEach(async (airdrop: any) => {
        if ("exchange_asset" === airdrop.config?.operation_mode) {
          setExchangeAssetAirdropsBalance({
            ...exchangeAssetAirdropsBalance,
            [airdrop.address]: (
              await walletInfo.connectedChains[airdrop.contextChainId].signingClient.getBalance(
                walletInfo.connectedChains[airdrop.contextChainId].address,
                airdrop.config?.exchange_asset?.native_token?.denom
              )
            ).amount,
          });
        }
      });
    } else if ("exchange_asset" === airdrop.config?.operation_mode) {
      setExchangeAssetAirdropsBalance({
        ...exchangeAssetAirdropsBalance,
        [airdrop.address]: (
          await walletInfo.connectedChains[airdrop.contextChainId].signingClient.getBalance(
            walletInfo.connectedChains[airdrop.contextChainId].address,
            airdrop.config?.exchange_asset?.native_token?.denom
          )
        ).amount,
      });
    }
  };

  const renderExchangeAssetAirdropAction = (airdrop: any) => {
    const AXV = assets[airdrop.config.token];
    // if airdrop to be claimed
    if (exchangeAssetAirdropsBalance[airdrop.address] && exchangeAssetAirdropsBalance[airdrop.address] !== "0")
      return (
        <>
          {loadings[airdrop.address] ? (
            <CustomLoader size="xs" />
          ) : (
            <Button
              btnColor={"gradient"}
              onClick={async () => await handleClaim(airdrop, exchangeAssetAirdropsBalance[airdrop.address])}
            >
              {i18(
                `Claim: ${
                  AXV &&
                  BigNumber(exchangeAssetAirdropsBalance[airdrop.address])
                    .div(Math.pow(10, AXV.decimals))
                    .decimalPlaces(2)
                    .toString(10)
                } AXV`,
                "dashboard.airdrop.buttonText",
                {
                  amount:
                    AXV &&
                    BigNumber(exchangeAssetAirdropsBalance[airdrop.address])
                      .div(Math.pow(10, AXV.decimals))
                      .decimalPlaces(2)
                      .toString(10),
                }
              )}
            </Button>
          )}
        </>
      );

    // if airdrop already claimed
    if (
      airdropsBalance[airdrop.address] &&
      airdropsBalance[airdrop.address].claimed &&
      "0" !== airdropsBalance[airdrop.address].claimed
    )
      return (
        <span style={{ alignContent: "center" }}>
          {i18(
            "Claimed: " +
              BigNumber(airdropsBalance[airdrop.address].claimed)
                .div(Math.pow(10, AXV.decimals))
                .decimalPlaces(2)
                .toString(10) +
              " AXV",
            "dashboard.airdrop.claimedAXV",
            {
              amount: BigNumber(airdropsBalance[airdrop.address].claimed)
                .div(Math.pow(10, AXV.decimals))
                .decimalPlaces(2)
                .toString(10),
            }
          )}
        </span>
      );

    // if not qualified
    if (
      !airdrop.extraFields?.exchangeAssetInfo?.ibcTransferFromBaseChain ||
      (airdrop.extraFields?.exchangeAssetInfo?.ibcTransferFromBaseChain &&
        elegibleToIBCTransfer[airdrop.extraFields?.exchangeAssetInfo?.base_chain_id] &&
        "0" === elegibleToIBCTransfer[airdrop.extraFields?.exchangeAssetInfo?.base_chain_id]?.amount)
    )
      return <span style={{ alignContent: "center" }}>{i18("Not qualified", "dashboard.airdrop.notQualified")}</span>;

    // check elegibility button
    // only show this button if ibcTransferFromBaseChain is true
    if (
      airdrop.extraFields?.exchangeAssetInfo?.ibcTransferFromBaseChain &&
      !elegibleToIBCTransfer[airdrop.extraFields?.exchangeAssetInfo?.base_chain_id] &&
      (!exchangeAssetAirdropsBalance[airdrop.address] || "0" === exchangeAssetAirdropsBalance[airdrop.address])
    )
      return (
        <>
          {loadings[airdrop.address] ? (
            <CustomLoader size="xs" />
          ) : (
            <Button
              btnColor={"dark-medium"}
              onClick={async () => {
                const chainId = airdrop.extraFields?.exchangeAssetInfo?.base_chain_id;
                const baseDenom = airdrop.extraFields?.exchangeAssetInfo?.denom_trace?.base_denom;
                if (!chainId || !baseDenom) {
                  console.log("Chain ID / baseDenom not found");
                  return;
                }
                const chainInfo = chains[airdrop.contextChainId];
                if (!chainInfo) {
                  console.log("chainInfo not found");
                  return;
                }

                setLoadings({ ...loadings, [airdrop.address]: true });
                try {
                  // get the balance of this exchange asset on the destination chain
                  await getExchangeAssetAirdropsBalance(airdrop);

                  const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
                  let walletExtensionClient = window[walletExtension];

                  if ("cosmostation" === walletExtension) {
                    walletExtensionClient = window.cosmostation.providers.keplr;
                  }

                  await walletExtensionClient.experimentalSuggestChain(chainInfo);
                  const offlineSigner = await walletExtensionClient.getOfflineSignerAuto(chainId);
                  const acc_address = (await offlineSigner.getAccounts())[0].address;
                  const ibcClient = await SigningStargateClient.connectWithSigner(chainInfo.rpc, offlineSigner);
                  const balance = await ibcClient.getBalance(acc_address, baseDenom);
                  setElegibleToIBCTransfer({
                    ...elegibleToIBCTransfer,
                    [chainId]: balance,
                  });
                } catch (e) {
                  console.log(e);
                }

                setLoadings({ ...loadings, [airdrop.address]: false });
              }}
            >
              Check Eligibility
            </Button>
          )}
        </>
      );

    // ibc transfer
    // only show this button if ibcTransferFromBaseChain is true
    if (
      airdrop.extraFields?.exchangeAssetInfo?.ibcTransferFromBaseChain &&
      elegibleToIBCTransfer[airdrop.extraFields?.exchangeAssetInfo?.base_chain_id] &&
      elegibleToIBCTransfer[airdrop.extraFields?.exchangeAssetInfo?.base_chain_id]?.amount !== "0"
    )
      return (
        <>
          {loadings[airdrop.address] ? (
            <CustomLoader size="xs" />
          ) : (
            <Button
              btnColor={"gradient"}
              onClick={async () => {
                const chainId = airdrop.extraFields?.exchangeAssetInfo?.base_chain_id;
                const baseDenom = airdrop.extraFields?.exchangeAssetInfo?.denom_trace?.base_denom;
                if (!chainId || !baseDenom) {
                  console.log("Chain ID / baseDenom not found");
                  return;
                }
                const chainInfo = chains[chainId];
                if (!chainInfo) {
                  console.log("chainInfo not found");
                  return;
                }

                const srcChainInfo = chains[airdrop.contextChainId];

                setLoadings({ ...loadings, [airdrop.address]: true });
                try {
                  const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
                  let walletExtensionClient = window[walletExtension];

                  if ("cosmostation" === walletExtension) {
                    walletExtensionClient = window.cosmostation.providers.keplr;
                  }

                  await walletExtensionClient.experimentalSuggestChain(chainInfo);
                  const offlineSigner = await walletExtensionClient.getOfflineSignerAuto(chainId);
                  const acc_address = (await offlineSigner.getAccounts())[0].address;
                  const ibcClient = await SigningStargateClient.connectWithSigner(chainInfo.rpc, offlineSigner);

                  await dispatch(
                    executeIbcTransfer({
                      sendingClient: ibcClient,
                      sendingAddrInput: acc_address,
                      // for deposit the destination is the wallet in archway
                      destUserAddress: walletInfo.connectedChains[airdrop.contextChainId].address,
                      denomToSend: baseDenom,
                      amount: elegibleToIBCTransfer[airdrop.extraFields?.exchangeAssetInfo?.base_chain_id].amount,
                      channel: srcChainInfo.ibcDenoms.find(ibcDenom => ibcDenom.base_chain_id === chainInfo.chainId)
                        ?.this_chain_channel_id,
                      fee: {
                        denom: GasPrice.fromString(chainInfo.defaultFee).denom,
                        amount: GasPrice.fromString(chainInfo.defaultFee).amount.toString(),
                      },
                      // on deposit, we want to check if the packet has timed out on the from chain
                      srcChain: {
                        chainId: chainInfo.chainId,
                        restURL: chainInfo.rest,
                        explorerURL: chainInfo.explorerURL,
                        rpcURL: chainInfo.rpc,
                        isEVM: chainInfo.isEVM,
                      },
                      // on deposit, we want to check if the packet has arrived to archway
                      dstChain: {
                        chainId: srcChainInfo.chainId,
                        restURL: srcChainInfo.rest,
                        explorerURL: srcChainInfo.explorerURL,
                        rpcURL: srcChainInfo.rpc,
                        isEVM: srcChainInfo.isEVM,
                      },
                      i18,
                    })
                  );

                  // get the balance of this exchange asset on the destination chain
                  await getExchangeAssetAirdropsBalance(airdrop);
                } catch (e) {
                  console.log(e);
                }

                setLoadings({ ...loadings, [airdrop.address]: false });
              }}
            >
              {"IBC Transfer " +
                BigNumber(elegibleToIBCTransfer[airdrop.extraFields?.exchangeAssetInfo?.base_chain_id].amount)
                  .div(Math.pow(10, AXV.decimals))
                  .decimalPlaces(2)
                  .toString(10) +
                " Tokens"}
            </Button>
          )}
        </>
      );
  };

  return (
    <>
      <CustomModal
        isOpen={airdropsModalOpen}
        onClose={() => setAirdropsModalOpen(false)}
        className={clsx(styles.dashboardAirdrops, "dashboardAirdrops")}
      >
        <section className="sectionModalHeader">
          <h2 className="h2">{i18("AXV Airdrops", "dashboard.airdrop.dialog.title")}</h2>
        </section>
        <div style={{ fontSize: "1em", marginBottom: "1em", marginLeft: "1em" }}>
          <li style={{ listStyle: "none", margin: "1em 0" }}>
            {Object.values(airdrops).map((airdrop: any) => (
              <ul
                key={airdrop.address}
                className={`withGradientBorderBottom`}
                style={{ display: "flex", justifyContent: "space-between", paddingLeft: "0em", paddingBottom: "1em" }}
              >
                <span style={{ alignContent: "center" }}>{airdrop.extraFields.description}</span>
                {(!airdrop.config?.operation_mode || "eligible_wallet" === airdrop.config?.operation_mode) && (
                  <>
                    {!airdropsBalance[airdrop.address]?.claimed &&
                      "0" === airdropsBalance[airdrop.address]?.pending && (
                        <span style={{ alignContent: "center" }}>
                          {i18("Not qualified", "dashboard.airdrop.notQualified")}
                        </span>
                      )}
                    {airdropsBalance[airdrop.address] &&
                      airdropsBalance[airdrop.address]?.claimed &&
                      "0" !== airdropsBalance[airdrop.address]?.claimed && (
                        <span style={{ alignContent: "center" }}>
                          {i18(
                            "Claimed: " +
                              BigNumber(airdropsBalance[airdrop.address].claimed)
                                .div(Math.pow(10, assets[airdrop.config.token].decimals))
                                .decimalPlaces(2)
                                .toString(10) +
                              " AXV",
                            "dashboard.airdrop.claimedAXV",
                            {
                              amount: BigNumber(airdropsBalance[airdrop.address].claimed)
                                .div(Math.pow(10, assets[airdrop.config.token].decimals))
                                .decimalPlaces(2)
                                .toString(10),
                            }
                          )}
                        </span>
                      )}
                    {"0" !== airdropsBalance[airdrop.address]?.pending &&
                      (loadings[airdrop.address] ? (
                        <CustomLoader size="xs" />
                      ) : (
                        <Button btnColor={"gradient"} onClick={async () => await handleClaim(airdrop)}>
                          {airdropsBalance[airdrop.address] &&
                            i18(
                              `Claim: ${BigNumber(airdropsBalance[airdrop.address].pending || 0)
                                .div(Math.pow(10, assets[airdrop.config.token].decimals))
                                .decimalPlaces(2)
                                .toString(10)} AXV`,
                              "dashboard.airdrop.buttonText",
                              {
                                amount: BigNumber(airdropsBalance[airdrop.address].pending || 0)
                                  .div(Math.pow(10, assets[airdrop.config.token].decimals))
                                  .decimalPlaces(2)
                                  .toString(10),
                              }
                            )}
                        </Button>
                      ))}
                  </>
                )}
                {"exchange_asset" === airdrop.config?.operation_mode && renderExchangeAssetAirdropAction(airdrop)}
              </ul>
            ))}
          </li>
        </div>
        <div className="buttonContainer" style={{ justifyContent: "right" }}>
          <Button
            btnColor="gradientText"
            text={i18("Cancel", "managetokens.cancelBtn.text")}
            title={i18("Cancel and close", "managetokens.cancelBtn.title")}
            onClick={() => setAirdropsModalOpen(false)}
          />
        </div>
      </CustomModal>
    </>
  );
};

export default DashboardAirdrop;
