import { SigningArchwayClient } from "@archwayhq/arch3.js";
import { Coin, Keplr } from "@keplr-wallet/types";
import { TxRaw } from "cosmjs-types/cosmos/tx/v1beta1/tx";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AminoTypes,
  calculateFee,
  createIbcAminoConverters,
  createProtobufRpcClient,
  GasPrice,
  QueryClient,
  setupAuthExtension,
  SigningStargateClient,
  StargateClient,
} from "@cosmjs/stargate";
import BigNumber from "bignumber.js";
import { MsgExecuteContract } from "cosmjs-types/cosmwasm/wasm/v1/tx";
import { PeriodicVestingAccount } from "cosmjs-types/cosmos/vesting/v1beta1/vesting";
import { QueryClientImpl } from "cosmjs-types/cosmos/auth/v1beta1/query";
import { Tendermint37Client } from "@cosmjs/tendermint-rpc";
import {
  CosmWasmClient,
  createWasmAminoConverters,
  MsgExecuteContractEncodeObject,
  SigningCosmWasmClient,
} from "@cosmjs/cosmwasm-stargate";
import { SecretNetworkClient } from "secretjs";
import { MsgTransfer } from "cosmjs-types/ibc/applications/transfer/v1/tx";
import { accountFromNibiru, createCosmWasmBatchClient, executeCosmosContractTx, getCosmosTxFees } from "@axvdex_utils";
import { IAsset, IAssetTemplate, IChain, IContract, IFarm, IPool } from "@axvdex/utils/interfaces";
import { signHackedAmino } from "@axvdex/utils/aminoFeeGrantHack";
import parseWalletErrorMessage from "@axvdex/utils/parseWalletErrorMessage";
import rpcClientQuerySmartContractWrapper, { sendLogError } from "@axvdex/utils/rpcClientQuerySmartContractWrapper";
import signAndBroadcastEVM from "@axvdex/utils/signAndBroadcastEVM";
import { getIbcPacketInfoFromEventResponse, ibcTransferPacketCheck } from "@axvdex/utils/ibcHelpers";
import { checkedTimeoutPromise, checkedTimeoutPromises } from "@axvdex/utils/promises";
import { IWalletConnectedChainInfo, IWalletInfo } from "./initialState";
import { getListOfPools } from "api/pools";
import { AsyncThunkConfig } from "state";
import { loadState, persistState, removeState, WHITE_LIST_PERSISTED_STATE_KEYS } from "state/persist";
import {
  addConnectedChainToWalletInfo,
  clearWallet,
  initWalletInfo,
  removeAssetBalances,
  removeConnectedChainFromWalletInfo,
  sendToast,
  setBackgroundTemporaryState,
  setGRVT8Balance,
  setLoadingWallet,
  setMyDashboardGrid,
  setMyGlobalSettings,
  setPoolInfo,
  setStateBalances,
  setStateFarmLpBalances,
  setStateLpBalances,
  setStateUser,
  setUserSwapLogs,
  setVestingLockedAmount,
  updateAssetBalances,
  updateAssetsUnmints,
  updateConnectionStatus,
  updateFarmsLpBalance,
  updateMyAssets,
  updatePools,
  updatePoolsLpBalance,
  updatePriceWatch,
  updateUser,
  updatingUser,
} from "state/wallet/walletSlice";
import {
  getUser,
  getUserComponentBalances,
  getUserLogs,
  postFeeGrantTx,
  postSignature,
  postTransactions,
  updateUserAssets,
  updateUserDashboardGrid,
  updateUserGlobalSettings,
  updateUserPriceWatch,
} from "api/user";

let connectingWallet = false;
let changingAccount = false;

export const connectWallet = async (dispatch, chainsToConnect, showModal) => {
  if (connectingWallet) throw Error("Already connecting wallet...");
  connectingWallet = true;
  try {
    const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
    if (!window[walletExtension]) {
      throw new Error("Please install " + walletExtension + " extension");
    }

    const wallet: IWalletInfo = {
      name: "",
      pubKey: "",
      connectedChains: {},
      connectionStatus: "",
      isConnected: false,
    };
    let walletNativeBalances: Coin[] = [];
    const userChainsRejected = [];

    // enable chains, seperate function so we can use it to enable multiple chains at once
    await enableChains(walletExtension, chainsToConnect);

    for (const chain of chainsToConnect) {
      const chainConnectionData = await connectToChain(dispatch, walletExtension, chain);
      if ("" === wallet.name && chainConnectionData.walletName) {
        wallet.name = chainConnectionData.walletName;
        wallet.pubKey = chainConnectionData.pubKey;
      }
      if (chainConnectionData.chainConnectionDetails)
        wallet.connectedChains[chain.chainId] = chainConnectionData.chainConnectionDetails;
      if (chainConnectionData.userRejectedConnection) userChainsRejected.push(chain.chainId);
      walletNativeBalances = walletNativeBalances.concat(chainConnectionData.chainWalletNativeBalances);
    }
    if ("cosmostation" === walletExtension) {
      window.cosmostation.cosmos.on("accountChanged", async () => {
        if (changingAccount) return;
        changingAccount = true;
        // console.log("Key store changed. Refetching account info...");
        await handleAccountChange(dispatch, chainsToConnect, showModal);
        changingAccount = false;
      });
    } else {
      window.removeEventListener(walletExtension + "_keystorechange", () => {});
      window.addEventListener(walletExtension + "_keystorechange", async () => {
        if (changingAccount) return;
        changingAccount = true;
        // console.log("Key store changed. Refetching account info...");
        await handleAccountChange(dispatch, chainsToConnect, showModal);
        changingAccount = false;
      });
    }

    connectingWallet = false;
    wallet.connectionStatus = "CONNECTED";
    wallet.isConnected = true;
    return { wallet, walletNativeBalances, userChainsRejected };
  } catch (e) {
    connectingWallet = false;
    sendLogError(e.message);
    console.error(e);
    throw e;
  }
};

export const connectToChain = async (dispatch: any, walletExtension: string, chain: IChain) => {
  const chainConnectionData = {
    walletName: null,
    pubKey: null,
    chainId: chain.chainId,
    chainConnectionDetails: null,
    chainWalletNativeBalances: [],
    userRejectedConnection: false,
  };

  let walletExtensionClient = window[walletExtension] as Keplr;

  console.debug("Connecting to " + chain.prefix + " with " + walletExtension + "...");
  // get list of RPCs to try to connect to:
  const rpcs = [chain.rpc, ...(chain.fallbackRPCs || [])];

  for (const rpc of rpcs) {
    console.debug("[" + chain.chainId + "] Trying rpc: " + rpc);
    try {
      if ("cosmostation" === walletExtension) {
        walletExtensionClient = window.cosmostation.providers.keplr;
      }
      if ("leap" !== walletExtension) {
        // leap mobile seems to block on this function
        await walletExtensionClient.experimentalSuggestChain(chain);
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // get supported chains by leap, if it already has the chain we are trying to add, it skips the experimentalSuggestChain
        const suportedChains = await window.leap.getSupportedChains();
        if (!suportedChains.includes(chain.chainName.toLocaleLowerCase())) {
          await walletExtensionClient.experimentalSuggestChain(chain);
        }
      }
      await walletExtensionClient.enable(chain.chainId);
      walletExtensionClient.defaultOptions = {
        sign: {
          preferNoSetFee: true,
          preferNoSetMemo: true,
        },
      };
      const accountRaw = await checkedTimeoutPromise(walletExtensionClient.getKey(chain.chainId));

      chainConnectionData.walletName = accountRaw.name;
      chainConnectionData.pubKey = Buffer.from(accountRaw.pubKey).toString("base64");

      const offlineSigner = await walletExtensionClient.getOfflineSignerAuto(chain.chainId);
      const account = (await checkedTimeoutPromise(offlineSigner.getAccounts()))[0];

      // Fee grant to only work on archway for now, so only do relevant requests for vesting + fee grant on archway
      try {
        const [archwayClient, client, starClient] = await checkedTimeoutPromises([
          "archway" === chain.prefix
            ? SigningArchwayClient.connectWithSigner(rpc, offlineSigner as any, {
                broadcastPollIntervalMs: process.env.REACT_APP_BROADCAST_POLL_INTERVAL
                  ? parseInt(process.env.REACT_APP_BROADCAST_POLL_INTERVAL)
                  : undefined,
              })
            : undefined,
          createCosmWasmBatchClient(
            rpc,
            offlineSigner as any,
            {
              dispatchInterval: 50,
              batchSizeLimit: 10,
            },
            {
              broadcastPollIntervalMs: process.env.REACT_APP_BROADCAST_POLL_INTERVAL
                ? parseInt(process.env.REACT_APP_BROADCAST_POLL_INTERVAL)
                : undefined,
              gasPrice: GasPrice.fromString(chain.defaultFee),
            }
          ),
          // [ONCHAIN_REQUEST] 3 requests to check status of mainnet-apis-sentry-XXXX
          SigningStargateClient.connectWithSigner(rpc, offlineSigner as any, {
            accountParser: "nibi" === chain.prefix ? accountFromNibiru : undefined,
            aminoTypes: new AminoTypes({
              ...createIbcAminoConverters(),
              ...createWasmAminoConverters(),
            }),
          }),
        ]);

        starClient.registry.register("/cosmwasm.wasm.v1.MsgExecuteContract", MsgExecuteContract);
        starClient.registry.register("/ibc.applications.transfer.v1.MsgTransfer", MsgTransfer);

        try {
          // if this fails we know this acc does not exist onchain yet, so it can be eligible for fee grant
          const [accDetails, nativeLockedAmount] = await checkedTimeoutPromises([
            // [ONCHAIN_REQUEST] 1 request to get acc info from chain
            "archway" === chain.prefix ? starClient.getSequence(account.address) : Promise.resolve(null),
            // [ONCHAIN_REQUEST] 2 requests to connect to node + get account info with more details
            "archway" === chain.prefix ? tryGetLockedNativeDenom(account.address, rpc) : Promise.resolve(null),
          ]);
          if ("archway" === chain.prefix && "0" !== nativeLockedAmount) {
            dispatch(setVestingLockedAmount(nativeLockedAmount));
          }

          chainConnectionData.chainConnectionDetails = {
            address: account.address,
            archwayClient: archwayClient,
            signingClient: client,
            signingStargateClient: starClient,
            connectAccountNumber: "archway" === chain.prefix ? accDetails?.accountNumber : null,
            connectSequence: "archway" === chain.prefix ? accDetails?.sequence : null,
            signerType: "undefined" !== (typeof offlineSigner as any).signDirect ? "direct" : "amino",
            chainState: chain,
          };
        } catch (e) {
          chainConnectionData.chainConnectionDetails = {
            address: account.address,
            archwayClient: archwayClient,
            signingClient: client,
            signingStargateClient: starClient,
            connectAccountNumber: null,
            connectSequence: null,
            signerType: "undefined" !== (typeof offlineSigner as any).signDirect ? "direct" : "amino",
            chainState: chain,
          };
        }
        // [ONCHAIN_REQUEST] 1 request to get acc all native balances
        chainConnectionData.chainWalletNativeBalances = [
          ...(await checkedTimeoutPromise(starClient.getAllBalances(account.address))),
        ];
      } catch (e) {
        sendLogError(e.message);
        console.error(e);
        throw e;
      }
      console.debug("[" + chain.chainId + "] Connected to rpc: " + rpc);
      break;
    } catch (e) {
      // when user rejects the connection on the wallet we just break the loop so the wallet does not pop up more times
      if ("Request rejected" === e.message) {
        console.error("[" + chain.chainId + "] Connection rejected by the user");
        chainConnectionData.userRejectedConnection = true;
        break;
      }
      console.error("[" + chain.chainId + "] Failed to connect with rpc: " + rpc);
    }
  }
  return chainConnectionData;
};

export const enableChains = async (walletExtension: string, chains: IChain[]) => {
  let walletExtensionClient = window[walletExtension] as Keplr;

  // cosmoshub-4 is always needed for the permit signing
  const chainsToEnable = ["cosmoshub-4"];
  for (const chain of chains) {
    // console.debug("Connecting to " + chain.prefix + " with " + walletExtension + "...");
    // get list of RPCs to try to connect to:
    const rpcs = [chain.rpc, ...(chain.fallbackRPCs || [])];
    for (const rpc of rpcs) {
      if ("cosmostation" === walletExtension) {
        walletExtensionClient = window.cosmostation.providers.keplr;
      }
      if ("leap" !== walletExtension) {
        // leap mobile seems to block on this function
        await walletExtensionClient.experimentalSuggestChain(chain);
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // get supported chains by leap, if it already has the chain we are trying to add, it skips the experimentalSuggestChain
        const suportedChains = await window.leap.getSupportedChains();
        if (!suportedChains.includes(chain.chainName.toLocaleLowerCase())) {
          await walletExtensionClient.experimentalSuggestChain(chain);
        }
      }
      chainsToEnable.push(chain.chainId);
    }
  }

  await walletExtensionClient.enable(chainsToEnable);
};

export const tryGetLockedNativeDenom = async (address: string, rpc: string) => {
  // this function is only relevant for archway
  const tmClient = await Tendermint37Client.connect(rpc);
  const queryClient = QueryClient.withExtensions(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    tmClient!,
    setupAuthExtension
  );

  // locked at 2023-09-03
  // archway1sz96l6kzjax8fy7qywqgn0qa65c4u77397muny { denom: 'aarch', amount: '72033282984325328092' } = 2625
  // archway1t96nhcc9uuvkr9dlmuqkvg0myqkaut9p599ddk { denom: 'aarch', amount: '3501000000000000000000' } = 2625
  // archway1zewgssdsgdyqzwm0ckzhh20azju4hlp6ukyfju { denom: 'aarch', amount: '5001000000000000000000' } = 3750
  // archway1rugdvgs6vttk55dcj7xa3ptv9p532y7gjef3wz { denom: 'aarch', amount: '2501000000000000000000' } = 1875
  // archway1lkdcfz9ukhcmhvcfrzxy357ysp2u6txzsvqdkc { denom: 'aarch', amount: '2768816100000000000' } = 375
  // archway1a92wa7xvrvr8gkvr8mct2hjnsv4r8rphhnjpc0 { denom: 'aarch', amount: '501000000000000000000' } = 375
  // archway1yfss2hezldzxxs8qp83azrenlwdx3tyfsscuck { denom: 'aarch', amount: '1013257900000000000' } = 750
  // archway1whq6ktvgvlk63nj5qz7dfhz6zfhcadhfeh53p8 { denom: 'aarch', amount: '1001000000000000000000' } = 750
  // archway1ggg5klf4e4wxgc4f8z0vmc9pcxh5s5nvjw28tp { denom: 'aarch', amount: '501000000000000000000' } = 375
  // Uncomment to test one mainnet wallet that has locked up assets:
  // const [acc] = await Promise.all([queryClient.auth.account("archway1t96nhcc9uuvkr9dlmuqkvg0myqkaut9p599ddk")]);
  const [acc] = await Promise.all([queryClient.auth.account(address)]);

  if ("/cosmos.vesting.v1beta1.PeriodicVestingAccount" === acc.typeUrl) {
    // the wallets that had NOT delegated, have bad balance with available + locked
    // we need to get the amount of locked and subtract to the balance of native denom
    const accDetails = PeriodicVestingAccount.decode(acc.value);

    const delegatedVesting = accDetails.baseVestingAccount.delegatedVesting
      .filter(coin => "aarch" === coin.denom)
      .reduce((a, b) => a.plus(b.amount), BigNumber(0));

    // if user has delegatedVesting amount, it means it delegated its locked tokens so the balance showing is correct
    if (delegatedVesting.gt(0)) {
      return "0";
    }

    // get periods that have not yet passed
    const availableVesting = [];
    let accumulator = 0;
    accDetails.vestingPeriods.forEach(period => {
      if (
        parseInt(accDetails.startTime.toString()) + accumulator + parseInt(period.length.toString()) >
        new Date().getTime() / 1000
      )
        availableVesting.push(
          period.amount.filter(coin => "aarch" === coin.denom).reduce((a, b) => a.plus(b.amount), BigNumber(0))
        );
      accumulator += parseInt(period.length.toString());
    });

    const lockedAmount = availableVesting.reduce((a, b) => a.plus(b), BigNumber(0));

    return lockedAmount.gt(0) ? lockedAmount.toString(10) : "0";
  }
  return "0";
};

export const getFeeGrantSigners = async (chainId: string, rpc: string) => {
  const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
  let walletExtensionClient = window[walletExtension];

  if ("cosmostation" === walletExtension) {
    walletExtensionClient = window.cosmostation.providers.keplr;
  }
  const offlineSigner = await walletExtensionClient.getOfflineSignerAuto(chainId);
  const signer = await SigningStargateClient.connectWithSigner(rpc, offlineSigner, {
    aminoTypes: new AminoTypes({
      ...createWasmAminoConverters(),
    }),
    broadcastPollIntervalMs: process.env.REACT_APP_BROADCAST_POLL_INTERVAL
      ? parseInt(process.env.REACT_APP_BROADCAST_POLL_INTERVAL)
      : undefined,
  });

  signer.registry.register("/cosmwasm.wasm.v1.MsgExecuteContract", MsgExecuteContract);
  const tmClient = await Tendermint37Client.connect(rpc);
  const queryClient = QueryClient.withExtensions(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    tmClient!,
    setupAuthExtension
  );

  const queryService = new QueryClientImpl(createProtobufRpcClient(queryClient));

  if (!walletExtensionClient?.signAmino) {
    throw new Error("Please install wallet extension");
  }

  const signerOff = await StargateClient.connect(rpc);

  return {
    stargateSigner: signer,
    queryService,
    offlineSigner,
    walletExtensionClient,
    signerOff,
  };
};

export const getIbcClientSigner = async (chainInfo: IChain, denom: string) => {
  const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
  let walletExtensionClient = window[walletExtension];

  if ("cosmostation" === walletExtension) {
    walletExtensionClient = window.cosmostation.providers.keplr;
  }

  for (const rpc of [chainInfo.rpc, ...(chainInfo.fallbackRPCs || [])]) {
    try {
      await checkedTimeoutPromise(walletExtensionClient.experimentalSuggestChain(chainInfo));
      const offlineSigner = await checkedTimeoutPromise(walletExtensionClient.getOfflineSignerAuto(chainInfo.chainId));
      const acc_address = (await checkedTimeoutPromise(offlineSigner.getAccounts()))[0].address;
      const ibcClient = await checkedTimeoutPromise(
        SigningStargateClient.connectWithSigner(rpc, offlineSigner, {
          gasPrice: GasPrice.fromString(chainInfo.defaultFee),
        })
      );

      let balance = null;
      // cw20 denoms can appear here, so we check for if the name of the denom starts with cw20: and query the contract assuming it's a cw20base standard
      if ("cw20:" === denom.substring(0, 5)) {
        // Secret Network is a special case as they have viewing keys to get the balance from their cw20 tokens
        if (chainInfo.chainId.includes("secret")) {
          const client = await new SecretNetworkClient({
            url: chainInfo.rest,
            chainId: chainInfo.chainId,
          });

          // return a isSecretNetworkViewingKeyError flag to identify this error
          let key = null;
          try {
            key = await walletExtensionClient.getSecret20ViewingKey(chainInfo.chainId, denom.split(":")[1]);
          } catch (e) {
            return { acc_address, balance: "0", client: ibcClient, isSecretNetworkViewingKeyError: true };
          }
          if (!key) return { acc_address, balance: "0", client: ibcClient, isSecretNetworkViewingKeyError: true };

          balance = (
            (await client.query.compute.queryContract({
              contract_address: denom.split(":")[1],
              query: { balance: { address: acc_address, key } },
            })) as any
          ).balance;
        } else {
          const client = await checkedTimeoutPromise(CosmWasmClient.connect(rpc));
          balance = await client.queryContractSmart(denom.split(":")[1], { balance: { address: acc_address } });
        }
      } else {
        balance = await checkedTimeoutPromise(ibcClient.getBalance(acc_address, denom));
      }

      return { acc_address, balance: balance.amount, client: ibcClient };
    } catch (e) {
      console.log(e);
    }
  }
};

export const disconnectWallet = createAsyncThunk<void, void, AsyncThunkConfig>(
  "wallet/disconnectWallet",
  async (_, { getState, dispatch }) => {
    const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
    // remove items from localstorage (permit)
    const walletInfo = getState().wallet.walletInfo;
    localStorage.removeItem("connected");
    const permits = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits);
    delete permits["cosmos_" + walletInfo.pubKey];
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.permits, permits);
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet, "");
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet, false);
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWalletChains, []);
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.agreedCompliance, false);
    removeState(WHITE_LIST_PERSISTED_STATE_KEYS.dashboardGrid);
    // remove wallet enable logging out
    // try catch needed as Leap does not have the .disable() function
    // clean disconnect still works in Leap because of the erase state below
    // but Leap will NOT erase the approved domain as this function is not available
    if (!/undefined/i.test(typeof window[walletExtension].disable)) {
      await window[walletExtension].disable();
    } else if (!/undefined/i.test(typeof window[walletExtension].disconnect)) {
      Object.keys(walletInfo.connectedChains).forEach(async chain => {
        window[walletExtension].disconnect(chain);
      });
    }

    // erase state
    dispatch(clearWallet());
  }
);

const handleAccountChange = async (dispatch, chainsToConnect, showModal) => {
  try {
    await dispatch(connectWalletWithDispatch({ chainsToConnect, showModal }));
    await dispatch(
      setStateUser({
        favoriteSwaps: [],
        favoritePools: [],
        favoriteAssets: [],
        priceWatch: [],
        poolsWithBalance: [],
        swapLogs: [],
        myAssets: [],
        isUserDetailsLoad: false,
        myAssetUnmints: {},
        mySoftLockups: {},
        myGlobalSettings: null,
      })
    );
    await dispatch(setStateBalances({}));
    await dispatch(setStateLpBalances({}));
    await dispatch(setStateFarmLpBalances({}));
    await dispatch(handleAuthenticationOnAccountChange({ showModal }));
  } catch (e) {
    console.error(e);
  }
};

let executingAuthenticationOnAccountChange = false;

export const handleAuthenticationOnAccountChange = createAsyncThunk<void, { showModal }, AsyncThunkConfig>(
  "wallet/handleAuthenticationOnAccountChange",
  async ({ showModal }, { dispatch, getState }) => {
    try {
      if (!executingAuthenticationOnAccountChange) {
        executingAuthenticationOnAccountChange = true;
        dispatch(updatingUser(true));
        const walletInfo = getState().wallet.walletInfo;
        const permits = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits);
        if (!permits["cosmos_" + walletInfo.pubKey]) {
          showModal("permitAuthenticationModal");
        }
        const sign = permits["cosmos_" + walletInfo.pubKey];
        try {
          const userInfo = await fetchUserInfo(sign.signature, walletInfo.pubKey);
          dispatch(updateUser(userInfo));
        } catch (e) {
          console.error(e);
        }
        executingAuthenticationOnAccountChange = false;
      }

      // snackbar.success(i18("header.permitSaved", "Success saving permit"));
    } catch (err) {
      // snackbar.error(i18("header.permitError", "Failed creating permit"));
      // console.error(err);
      dispatch(updatingUser(false));
      executingAuthenticationOnAccountChange = false;
    }
  }
);

export const retryWalletWithDispatch = createAsyncThunk<IWalletInfo | null, { chainToConnect }, AsyncThunkConfig>(
  "wallet/retryWalletWithDispatch",
  async ({ chainToConnect }, { dispatch }) => {
    try {
      const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
      if (!window[walletExtension]) throw new Error("UNINSTALLED");

      const chainConnectionData = await connectToChain(dispatch, walletExtension, chainToConnect);
      dispatch(
        updateAssetBalances(
          chainConnectionData.chainWalletNativeBalances.reduce(
            (obj, item) => Object.assign(obj, { [item.denom]: item.amount }),
            {}
          )
        )
      );
      dispatch(
        addConnectedChainToWalletInfo({
          chainId: chainToConnect.chainId,
          chainConnectionDetails: chainConnectionData.chainConnectionDetails,
        })
      );
      if (loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet)) {
        const autoConnectChains = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWalletChains);
        if (!autoConnectChains.includes(chainToConnect.chainId)) {
          persistState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWalletChains, [
            ...autoConnectChains,
            chainToConnect.chainId,
          ]);
        }
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  }
);

export const disconnectChainWithDispatch = createAsyncThunk<
  IWalletInfo | null,
  { chainToDisconnect },
  AsyncThunkConfig
>("wallet/disconnectChainWithDispatch", async ({ chainToDisconnect }, { getState, dispatch }) => {
  try {
    // disconnecting from chain will remove the walletinfo connected chain object + remove from autoconnect
    // for consistency, we also remove the asset balances of that chain as they are populated when it connects
    const { assetBalances, assets } = getState().wallet;
    dispatch(
      removeConnectedChainFromWalletInfo({
        chainId: chainToDisconnect.chainId,
      })
    );
    dispatch(
      removeAssetBalances({
        assetIdsToRemove: Object.keys(assetBalances).filter(
          assetId => assets[assetId]?.contextChainId === chainToDisconnect.chainId
        ),
      })
    );

    if (loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet)) {
      const autoConnectChains = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWalletChains);
      if (autoConnectChains.includes(chainToDisconnect.chainId)) {
        persistState(
          WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWalletChains,
          autoConnectChains.filter(chainId => chainId !== chainToDisconnect.chainId)
        );
      }
    }
  } catch (e) {
    console.error(e);
    return null;
  }
});

export const connectWalletWithDispatch = createAsyncThunk<
  IWalletInfo | null,
  { chainsToConnect; showModal },
  AsyncThunkConfig
>("wallet/connectWalletWithDispatch", async ({ chainsToConnect, showModal }, { dispatch }) => {
  try {
    dispatch(setLoadingWallet(true));
    const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
    if (!window[walletExtension]) throw new Error("UNINSTALLED");
    const { wallet, walletNativeBalances, userChainsRejected } = await connectWallet(
      dispatch,
      chainsToConnect,
      showModal
    );
    dispatch(
      initWalletInfo({
        wallet,
        assetBalances: walletNativeBalances.reduce(
          (obj, item) => Object.assign(obj, { [item.denom]: item.amount }),
          {}
        ),
      })
    );
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet, true);
    persistState(
      WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWalletChains,
      chainsToConnect.map(chain => chain.chainId).filter(chainId => !userChainsRejected.includes(chainId))
    );
    // dispatch(loadingWallet(false));
    return wallet;
  } catch (e) {
    if ("Already connecting wallet..." === e.message) return null;
    if ("UNINSTALLED" === e.message) {
      dispatch(sendToast({ type: "tx-fail", info: { msg: "Wallet not found!", toastID: "" + new Date().getTime() } }));
    }
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet, false);
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWalletChains, []);
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet, "");
    dispatch(
      updateConnectionStatus({
        isConnected: false,
        assetBalances: {},
        connectionStatus:
          "No wallet exists" === e.message
            ? "No wallet exists, please create one..."
            : "UNINSTALLED" === e.message
            ? "Install keplr extension..."
            : "Invalid chain Id" === e.message
            ? "NOT_CONNECTED"
            : "NOT_CONNECTED",
      })
    );
    return null;
  }
});

export const createUserPermit = async () => {
  const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
  let walletExtensionClient = window[walletExtension];
  if ("cosmostation" === walletExtension) {
    walletExtensionClient = window.cosmostation.providers.keplr;
  }

  // Permit now ALWAYS uses cosmoshub-4 chain for signing
  // need to get cosmos addr to sign
  const cosmosChain = "cosmoshub-4";
  await walletExtensionClient.enable(cosmosChain);
  const offlineSigner = await walletExtensionClient.getOfflineSignerAuto(cosmosChain);
  const account = (await offlineSigner.getAccounts())[0];
  return await (window[loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet)] as any).signArbitrary(
    cosmosChain,
    account.address,
    "Astrovault User Permit"
  );
};

export const executeContract = async (
  walletChainContext: IWalletConnectedChainInfo,
  contractAddress: string,
  msg,
  funds?: Coin[]
) => {
  return executeCosmosContractTx(
    walletChainContext.signingStargateClient,
    {
      prefix: walletChainContext.chainState.prefix,
      rest: walletChainContext.chainState.rest,
      mainNativeDenom: walletChainContext.chainState.feeCurrencies[0].coinMinimalDenom,
      defaultFee: walletChainContext.chainState.defaultFee,
    },
    walletChainContext.address,
    [
      {
        contractAddress: contractAddress,
        msg,
        funds: funds ? funds.sort((a, b) => a.denom.localeCompare(b.denom)) : undefined,
      },
    ]
  );
};

export const multipleExecuteContract = async (
  walletChainContext: IWalletConnectedChainInfo,
  executions: { contractAddress: string; msg: any; funds?: Coin[] }[]
) => {
  return executeCosmosContractTx(
    walletChainContext.signingStargateClient,
    {
      prefix: walletChainContext.chainState.prefix,
      rest: walletChainContext.chainState.rest,
      mainNativeDenom: walletChainContext.chainState.feeCurrencies[0].coinMinimalDenom,
      defaultFee: walletChainContext.chainState.defaultFee,
    },
    walletChainContext.address,
    executions.map(execution => {
      return {
        contractAddress: execution.contractAddress,
        msg: execution.msg,
        funds: execution.funds ? execution.funds.sort((a, b) => a.denom.localeCompare(b.denom)) : undefined,
      };
    })
  );
};

export const handleUnMint = createAsyncThunk<
  void,
  {
    derivativeAsset: IAsset;
    amount: string;
    i18: any;
  },
  AsyncThunkConfig
>("wallet/handleUnMint", async ({ derivativeAsset, amount, i18 }, { dispatch, getState }) => {
  const { walletInfo, assets } = getState().wallet;
  let isSuccessful: boolean;
  let msg: string;
  let txLink = null;
  const walletChainContext = walletInfo.connectedChains[assets[derivativeAsset.id].contextChainId];
  try {
    // make this indicator true here because user can just leave the website after they click on accept the tx. Indicator only shows if there are unmints so should be fine
    const myPortfolio = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.myPortfolio);
    persistState(WHITE_LIST_PERSISTED_STATE_KEYS.myPortfolio, {
      ...myPortfolio,
      unmintIndicator: true,
    });

    const res = await executeContract(
      walletChainContext,
      assets[derivativeAsset.id].address,
      {
        send: {
          contract: assets[derivativeAsset.id].derivativeContract,
          amount,
          msg: Buffer.from(
            JSON.stringify({
              withdrawal: {},
            })
          ).toString("base64"),
        },
      },
      []
    );
    txLink = `/${res.transactionHash}`;
    isSuccessful = true;
    msg = i18(`Success unmint xAsset`, "managetokens.derivative.unmint.msg.success");
  } catch (e) {
    const errMsg = parseWalletErrorMessage(e.message, i18, walletChainContext.chainState.feeCurrencies);
    msg = i18(`Failed unmint xAsset. Error: ${errMsg}`, "managetokens.derivative.unmint.msg.failed", { errMsg });
    isSuccessful = false;
  }

  // send toast and update relevant balances
  dispatch(
    sendToast({
      type: isSuccessful ? "tx-success" : "tx-fail",
      info: { msg, txLink, toastID: "" + new Date().getTime() },
    })
  );
  for (const assetID of [
    derivativeAsset.id,
    derivativeAsset.derivativeSource,
    walletChainContext.chainState.feeCurrencies[0].coinMinimalDenom,
  ]) {
    if (assets[assetID].isNative) {
      dispatch(
        updateNativeBalance({
          client: walletChainContext.signingClient,
          userAddress: walletChainContext.address,
          denom: assets[assetID].denom,
        })
      );
    } else {
      dispatch(
        updateTokenBalance({
          client: walletChainContext.signingClient,
          userAddress: walletChainContext.address,
          tokenAddress: assets[assetID].address,
        })
      );
    }
  }
});

export const handleClaimUnmint = createAsyncThunk<
  void,
  {
    derivativeAsset: IAsset;
    i18: any;
  },
  AsyncThunkConfig
>("wallet/handleClaimUnmint", async ({ derivativeAsset, i18 }, { dispatch, getState }) => {
  const { walletInfo, assets, contracts } = getState().wallet;
  const walletChainContext = walletInfo.connectedChains[assets[derivativeAsset.id].contextChainId];
  let isSuccessful: boolean;
  let msg: string;
  let txLink = null;
  let transactionHash = null;
  try {
    const res = await executeContract(walletChainContext, derivativeAsset.derivativeContract, { claim: {} });
    txLink = `/${res.transactionHash}`;
    transactionHash = res.transactionHash;
    isSuccessful = true;
    msg = i18(`Claim completed unmint successful!`, "managetokens.derivative.unmint.claimMsg.successful");
  } catch (e) {
    msg = i18(
      `Claim completed unmint failed! Error: ${parseWalletErrorMessage(
        e.message,
        i18,
        walletChainContext.chainState.feeCurrencies
      )}`,
      "managetokens.derivative.unmint.claimMsg.failed",
      { error: parseWalletErrorMessage(e.message, i18, walletChainContext.chainState.feeCurrencies) }
    );
    isSuccessful = false;
  }
  // send toast and update relevant balances
  dispatch(
    sendToast({
      type: isSuccessful ? "tx-success" : "tx-fail",
      info: { msg, txLink, toastID: "" + new Date().getTime() },
    })
  );
  for (const assetID of [
    derivativeAsset.id,
    derivativeAsset.derivativeSource,
    walletChainContext.chainState.feeCurrencies[0].coinMinimalDenom,
  ]) {
    if (assets[assetID].isNative) {
      dispatch(
        updateNativeBalance({
          client: walletChainContext.signingClient,
          userAddress: walletChainContext.address,
          denom: assets[assetID].denom,
        })
      );
    } else {
      dispatch(
        updateTokenBalance({
          client: walletChainContext.signingClient,
          userAddress: walletChainContext.address,
          tokenAddress: assets[assetID].address,
        })
      );
    }
  }
  // send tx to "BE" to be processed
  if (transactionHash) {
    await userTxBE(dispatch, walletInfo, [
      {
        chainId: walletChainContext.chainState.chainId,
        transactionHash,
      },
    ]);
    await dispatch(handleAuthentication());
    await dispatch(
      updateAssetsUnmint({
        client: walletChainContext.signingClient,
        assetID: derivativeAsset.id,
        userAddress: walletChainContext.address,
        derivativeAddress: derivativeAsset.derivativeContract,
        contracts,
      })
    );
  }
});

export const updateAssetsUnmint = createAsyncThunk<
  { unmints: string[]; readyUnmints: string[] },
  {
    client: SigningCosmWasmClient;
    assetID: string;
    userAddress: string;
    derivativeAddress: string;
    contracts: { [key: string]: IContract };
  },
  AsyncThunkConfig
>("wallet/updateAssetsUnmint", async ({ client, userAddress, derivativeAddress, contracts, assetID }, { dispatch }) => {
  try {
    const res = await rpcClientQuerySmartContractWrapper(client, derivativeAddress, {
      user_withdrawals: { address: userAddress },
    });
    const notReadyUnmints = [];
    const readyUnmints = res
      .filter(unmint => {
        const endDate = Object.entries(contracts).find(([_, contract]) => contract.address === derivativeAddress)[1]
          .config.is_external_source
          ? unmint.window_details.withdrawal_external_end_timestamp
          : unmint.window_details.withdrawal_estimated_end_timestamp;
        if (!endDate) {
          notReadyUnmints.push(unmint.amount);
          return false;
        }
        if (new Date().getTime() > endDate * 1000) return true;
        notReadyUnmints.push(unmint.amount);
        return false;
      })
      .map(unmint => unmint.amount);

    const readyTimestamps = res
      .map(
        unmint =>
          unmint.window_details.withdrawal_external_end_timestamp ||
          unmint.window_details.withdrawal_estimated_end_timestamp
      )
      .filter(endDate => endDate);

    const unmints = {
      unmints: notReadyUnmints,
      readyUnmints,
      readyTimestamps,
    };

    dispatch(
      updateAssetsUnmints({
        [assetID]: unmints,
      })
    );

    return unmints;
  } catch (e) {
    //console.error(e);
  }
});

export const updateNativeBalance = createAsyncThunk<
  string,
  { client: SigningCosmWasmClient | SigningStargateClient; userAddress: string; denom: string },
  AsyncThunkConfig
>("wallet/updateNativeBalance", async ({ client, userAddress, denom }, { dispatch }) => {
  const balance = await client.getBalance(userAddress, denom);
  dispatch(updateAssetBalances({ [balance.denom]: balance.amount }));
  return balance.amount;
});

export const updateTokenBalance = createAsyncThunk<
  string,
  { client: SigningCosmWasmClient; userAddress: string; tokenAddress: string },
  AsyncThunkConfig
>("wallet/updateTokenBalance", async ({ client, userAddress, tokenAddress }, { dispatch }) => {
  try {
    const response = await rpcClientQuerySmartContractWrapper(client, tokenAddress, {
      balance: { address: userAddress },
    });

    dispatch(updateAssetBalances({ [tokenAddress]: response.balance }));
    return response.balance;
  } catch (e) {
    //console.error(e);
  }
});

export const updateLpStakingBalance = createAsyncThunk<
  string,
  {
    client: SigningCosmWasmClient;
    userAddress: string;
    lpStakingAddress: string;
  },
  AsyncThunkConfig
>("wallet/updateLpStakingBalance", async ({ client, lpStakingAddress, userAddress }, { dispatch }) => {
  const response = await rpcClientQuerySmartContractWrapper(client, lpStakingAddress, {
    balance: { address: userAddress },
  });
  dispatch(updateAssetBalances({ [lpStakingAddress]: response.locked }));
  dispatch(updatePoolsLpBalance({ [lpStakingAddress]: response }));
  return response.locked;
});

export const updateFarmLpStakingBalance = createAsyncThunk<
  string,
  {
    client: SigningCosmWasmClient;
    userAddress: string;
    lpStakingAddress: string;
  },
  AsyncThunkConfig
>("wallet/updateFarmLpStakingBalance", async ({ client, lpStakingAddress, userAddress }, { dispatch }) => {
  const response = await rpcClientQuerySmartContractWrapper(client, lpStakingAddress, {
    balance: { address: userAddress },
  });
  dispatch(updateAssetBalances({ [lpStakingAddress]: response.locked }));
  dispatch(updateFarmsLpBalance({ [lpStakingAddress]: response }));
  return response.locked;
});

export const executeIbcOperatorAction = createAsyncThunk<
  void,
  {
    tradeSequenceRaw: any;
    toExecuteContract: string;
    srcChain: {
      explorerURL: string;
      chainId: string;
      restURL: string;
      rpcURL: string;
      isEVM: boolean;
    };
    dstChain: {
      explorerURL: string;
      chainId: string;
      restURL: string;
      rpcURL: string;
      isEVM: boolean;
    };
    assetBalancesToUpdate?: [
      {
        client: SigningCosmWasmClient;
        userAddress: string;
        tokens: string[];
        natives: string[];
      }
    ];
    msgBody: any;
    funds?: Coin[];
    i18: any;
  },
  AsyncThunkConfig
>(
  "wallet/executeIbcOperatorAction",
  async (
    { tradeSequenceRaw, srcChain, dstChain, toExecuteContract, assetBalancesToUpdate, msgBody, funds, i18 },
    { dispatch, getState }
  ) => {
    const { walletInfo, assets } = getState().wallet;
    const walletChainContext = walletInfo.connectedChains[srcChain.chainId];
    const crosschainHopSectionIndex = tradeSequenceRaw.findIndex(
      (section: any) => "crosschainHop" === section.actionType
    );

    const backgroundTemporaryState = {
      type: "crosschainSwap",
      data: [],
    };

    let isSuccessful = false;
    let msg = "";
    let sendTx = null;
    let timeoutTx = null;
    let receiveTx = null;
    let ackTx = null;
    let packet_sequence = null;
    let packet_src_channel = null;
    let packet_dst_channel = null;

    const fullActionQueryParams = {
      from: assets[tradeSequenceRaw[0].fromAssetID].symbol,
      fromChain: assets[tradeSequenceRaw[0].fromAssetID].contextChainId,
      to: assets[tradeSequenceRaw[tradeSequenceRaw.length - 1].toAssetID].symbol,
      toChain: assets[tradeSequenceRaw[tradeSequenceRaw.length - 1].fromAssetID].contextChainId,
      amount: BigNumber(tradeSequenceRaw[0].fromAssetAmount)
        .div(Math.pow(10, assets[tradeSequenceRaw[0].fromAssetID].decimals))
        .decimalPlaces(6)
        .toString(10),
    };

    try {
      const res = await executeContract(walletChainContext, toExecuteContract, msgBody, funds);

      // search ibc evens on response
      const ibcTransferEvent = res.events.find(({ type }) =>
        type.includes("wasm-astrovault-ibc_operator-cw20_ibc_mint_burn_transfer")
      );
      const ibcCallbackEvent = res.events.find(
        ({ type }) => type.includes("wasm-astrovault-ibc_operator-cross_swap_self_callback") // this happens only for swap events
      );

      const crossOrigTokenAmount = (ibcTransferEvent || ibcCallbackEvent).attributes.find(
        attr => "orig_amount" === attr.key
      ).value;
      const crossOrigRouteEvent = res.events.find(({ type }) => type.includes("wasm-astrovault-router-finalize_route"));

      // start with first section of the trade on origin chain
      if (crossOrigRouteEvent)
        // only show first trade if there is an originRouteEvent that actually has a trade
        backgroundTemporaryState.data.push({
          id: 1,
          status: "success",
          action: "trade",
          fromAssetID: tradeSequenceRaw[0].fromAssetID,
          fromAssetAmount: tradeSequenceRaw[0].fromAssetAmount,
          toAssetID: tradeSequenceRaw[crosschainHopSectionIndex].fromAssetID,
          toAssetAmount: crossOrigTokenAmount,
          fullActionQueryParams,
        });
      // start crosschain sequence
      backgroundTemporaryState.data.push({
        id: 2,
        status: "loading",
        action: "send",
        fromAssetID: tradeSequenceRaw[crosschainHopSectionIndex].fromAssetID,
        fromAssetAmount: crossOrigTokenAmount,
        fromAssetChain: tradeSequenceRaw[crosschainHopSectionIndex].fromAssetChain,
        toAssetID: tradeSequenceRaw[crosschainHopSectionIndex].toAssetID,
        toAssetAmount: null,
        toAssetChain: tradeSequenceRaw[crosschainHopSectionIndex].toAssetChain,
        fullActionQueryParams,
      });
      dispatch(setBackgroundTemporaryState(backgroundTemporaryState));

      sendTx = res.transactionHash;
      ({ packet_sequence, packet_src_channel, packet_dst_channel } = getIbcPacketInfoFromEventResponse(res.events));

      if (packet_sequence !== undefined && packet_src_channel !== undefined && packet_dst_channel !== undefined) {
        ({ isSuccessful, msg, timeoutTx, receiveTx, ackTx } = await ibcTransferPacketCheck(
          { packet_sequence, packet_src_channel, packet_dst_channel },
          srcChain,
          dstChain
        ));
      }
    } catch (e) {
      console.log(e);
      isSuccessful = false;
      msg = "IBC transfer failed! Error: " + parseWalletErrorMessage(e.message, i18, []);
    }
    const toastType = isSuccessful ? "ibc-transfer-success" : "ibc-transfer-fail";

    // complete the ibc sequence
    if (isSuccessful && receiveTx) {
      try {
        const receiveEvent = receiveTx.tx_responses[0].events.find(({ type }) =>
          type.includes("wasm-astrovault-ibc_operator-on_packet_receive")
        );
        const crossToAssetAmount = receiveEvent.attributes.find(({ key }) => "mint_amount" === key).value;

        // success on prev state
        backgroundTemporaryState.data = backgroundTemporaryState.data.map(state => {
          if (2 === state.id) {
            return {
              ...state,
              status: "success",
              toAssetAmount: crossToAssetAmount,
            };
          }
          return state;
        });
        // success on the trade if it exists on the target chain
        if (crosschainHopSectionIndex !== tradeSequenceRaw.length - 1) {
          const receiveRouteEvent = receiveTx.tx_responses[0].events.find(({ type }) =>
            type.includes("wasm-astrovault-router-finalize_route")
          );
          const toAssetAmount = receiveRouteEvent.attributes.find(({ key }) => "amount" === key).value;

          backgroundTemporaryState.data.push({
            id: 3,
            status: "success",
            action: "trade",
            fromAssetID: tradeSequenceRaw[crosschainHopSectionIndex].toAssetID,
            fromAssetAmount: crossToAssetAmount,
            toAssetID: tradeSequenceRaw[tradeSequenceRaw.length - 1].toAssetID,
            toAssetAmount,
            fullActionQueryParams,
          });
        }

        dispatch(setBackgroundTemporaryState(backgroundTemporaryState));
      } catch (e) {
        console.log(e);
      }
    } else if (!isSuccessful && (timeoutTx || ackTx)) {
      try {
        const failEvent = (timeoutTx || ackTx).tx_responses[0].events.find(({ type }) =>
          type.includes("wasm-astrovault-ibc_operator-on_packet_failure")
        );
        const failRefundAsset = failEvent.attributes.find(({ key }) => "refund_asset" === key).value;
        const failRefundAmount = failEvent.attributes.find(({ key }) => "refund_amount" === key).value;

        backgroundTemporaryState.data = backgroundTemporaryState.data.map(state => {
          if (2 === state.id) {
            return {
              ...state,
              status: "fail",
              toAssetAmount: null,
            };
          }
          return state;
        });

        backgroundTemporaryState.data.push({
          id: 3,
          status: "success",
          action: "refund",
          toAssetID: failRefundAsset,
          toAssetAmount: failRefundAmount,
          fromAssetChain: assets[tradeSequenceRaw[0].fromAssetID].contextChainId,
          fullActionQueryParams,
        });
        dispatch(setBackgroundTemporaryState(backgroundTemporaryState));
      } catch (e) {
        console.log(e);
      }
    }

    // update stuff if there is a sendTx, that means at least the first trade was executed
    if (sendTx) {
      try {
        backgroundTemporaryState.data = [
          ...backgroundTemporaryState.data,
          {
            id: 4,
            status: "loading",
            action: "update",
            fullActionQueryParams,
          },
        ];
        dispatch(setBackgroundTemporaryState(backgroundTemporaryState));

        if (assetBalancesToUpdate) {
          for (const assetBalanceToUpdate of assetBalancesToUpdate) {
            for (const toUpdateToken of assetBalanceToUpdate.tokens) {
              dispatch(
                updateTokenBalance({
                  client: assetBalanceToUpdate.client,
                  tokenAddress: toUpdateToken,
                  userAddress: assetBalanceToUpdate.userAddress,
                })
              );
            }
            for (const toUpdateNative of assetBalanceToUpdate.natives) {
              dispatch(
                updateNativeBalance({
                  client: assetBalanceToUpdate.client,
                  denom: toUpdateNative,
                  userAddress: assetBalanceToUpdate.userAddress,
                })
              );
            }
          }
        }

        await userTxBE(dispatch, walletInfo, [
          {
            chainId: srcChain.chainId,
            transactionHash: sendTx,
          },
          receiveTx
            ? { chainId: dstChain.chainId, transactionHash: receiveTx?.tx_responses[0].txhash }
            : {
                chainId: srcChain.chainId,
                transactionHash: ackTx?.tx_responses[0].txhash || timeoutTx?.tx_responses[0].txhash,
              },
        ]);

        backgroundTemporaryState.data = backgroundTemporaryState.data.map(state => {
          if (4 === state.id) {
            return {
              ...state,
              status: "success",
            };
          }
          return state;
        });
        dispatch(setBackgroundTemporaryState(backgroundTemporaryState));
      } catch (e) {
        console.log(e);
      }
    }

    dispatch(
      sendToast({
        type: toastType,
        info: {
          msg,
          sendLink: sendTx ? srcChain.explorerURL + sendTx : null,
          receiveLink: receiveTx ? dstChain.explorerURL + receiveTx.tx_responses[0].txhash : null,
          timeoutLink: timeoutTx ? srcChain.explorerURL + timeoutTx.tx_responses[0].txhash : null,
          ackLink: ackTx ? srcChain.explorerURL + ackTx.tx_responses[0].txhash : null,
          toastID: "" + new Date().getTime(),
        },
      })
    );
  }
);

export const executeIbcTransfer = createAsyncThunk<
  void,
  {
    sendingClient: SigningStargateClient;
    sendingAddrInput: string;
    destUserAddress: string;
    denomToSend: string;
    amount: string;
    channel: string;
    fee: {
      denom: string;
      amount: string;
    };
    srcChain: {
      explorerURL: string;
      chainId: string;
      restURL: string;
      rpcURL: string;
      isEVM: boolean;
    };
    dstChain: {
      explorerURL: string;
      chainId: string;
      restURL: string;
      rpcURL: string;
      isEVM: boolean;
    };
    assetBalancesToUpdate?: {
      client: SigningCosmWasmClient;
      userAddress: string;
      tokens: string[];
      natives: string[];
    }[];
    cw20_ics20?: {
      ibcRecvChannel: string;
      ibcSendChannel: string;
      cw20Address: string;
      ics20Address: string;
      chainId: string;
      ics20CodeHash?: string;
    };
    isWithdrawal?: boolean;
    i18: any;
  },
  AsyncThunkConfig
>(
  "wallet/executeIbcTransfer",
  async (
    {
      sendingClient,
      sendingAddrInput,
      destUserAddress,
      denomToSend,
      amount,
      channel,
      fee,
      srcChain,
      dstChain,
      assetBalancesToUpdate,
      cw20_ics20,
      isWithdrawal,
      i18,
    },
    { dispatch, getState }
  ) => {
    let isSuccessful: boolean;
    let msg = "";
    let sendLink = null;
    let timeoutLink = null;
    let receiveLink = null;
    let ackLink = null;
    let packet_sequence = null;
    let packet_src_channel = null;
    let packet_dst_channel = null;
    const walletInfo = getState().wallet.walletInfo;

    let executionFee = fee;
    let executionGas = "200000";

    if (walletInfo.connectedChains[srcChain.chainId]) {
      // for deployed chains try to get fees for gas = 300000
      const fee = await getCosmosTxFees(
        {
          prefix: walletInfo.connectedChains[srcChain.chainId].chainState.prefix,
          rest: walletInfo.connectedChains[srcChain.chainId].chainState.rest,
          mainNativeDenom: walletInfo.connectedChains[srcChain.chainId].chainState.feeCurrencies[0].coinMinimalDenom,
          defaultFee: walletInfo.connectedChains[srcChain.chainId].chainState.defaultFee,
        },
        "300000"
      );

      executionFee = fee.amount[0];
      executionGas = "300000";
    }

    try {
      let res = null;
      if (!cw20_ics20) {
        const msgTransfer: MsgTransfer = {
          sourcePort: "transfer",
          sourceChannel: channel,
          token: {
            denom: denomToSend,
            amount,
          },
          sender: sendingAddrInput,
          receiver: destUserAddress,
          memo: "",
          timeoutTimestamp: BigInt(
            String((Date.now() + ("LOCAL" === process.env.REACT_APP_MODE ? 600000000 : 600000)) * 1000000)
          ),
          timeoutHeight: {
            revisionNumber: BigInt(0),
            revisionHeight: BigInt(0),
          },
        };

        const msg = {
          typeUrl: "/ibc.applications.transfer.v1.MsgTransfer",
          value: msgTransfer,
        };

        if (!srcChain.isEVM) {
          res = await sendingClient.signAndBroadcast(sendingAddrInput, [msg], {
            amount: [executionFee],
            gas: executionGas,
          });
        } else {
          res = await signAndBroadcastEVM(
            sendingClient,
            {
              amount: [executionFee],
              gas: executionGas,
            },
            {
              chainId: srcChain.chainId,
              restURL: srcChain.restURL,
            },
            sendingAddrInput,
            [msg]
          );
        }
        sendLink = srcChain.explorerURL + res.transactionHash;
        ({ packet_sequence, packet_src_channel, packet_dst_channel } = getIbcPacketInfoFromEventResponse(res.events));
      }

      // for cw20_ics20 where we are going FROM the chain with cw20_ics20
      if (cw20_ics20 && srcChain.chainId === cw20_ics20.chainId) {
        // for cw20_ics20 ibc transfers we need to get a signing client for the origin chain and execute a 'send' on the contract...
        const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
        let walletExtensionClient = window[walletExtension];
        if ("cosmostation" === walletExtension) {
          walletExtensionClient = window.cosmostation.providers.keplr;
        }
        const offlineSigner = await walletExtensionClient.getOfflineSignerAuto(cw20_ics20.chainId);
        if (cw20_ics20.chainId.includes("secret")) {
          const client = await new SecretNetworkClient({
            url: srcChain.restURL,
            chainId: cw20_ics20.chainId,
            wallet: offlineSigner,
            walletAddress: sendingAddrInput,
          });
          res = await client.tx.compute.executeContract(
            {
              sender: sendingAddrInput,
              contract_address: cw20_ics20.cw20Address,
              msg: {
                send: {
                  recipient: cw20_ics20.ics20Address,
                  recipient_code_hash: cw20_ics20.ics20CodeHash,
                  amount,
                  msg: Buffer.from(
                    JSON.stringify({
                      channel: cw20_ics20.ibcRecvChannel,
                      remote_address: destUserAddress,
                      timeout: 600,
                    })
                  ).toString("base64"),
                },
              },
            },
            { gasLimit: 100000 }
          );
          sendLink = srcChain.explorerURL + res.transactionHash;
          ({ packet_sequence, packet_src_channel, packet_dst_channel } = getIbcPacketInfoFromEventResponse(
            undefined,
            res.arrayLog
          ));
        } else {
          const client = await SigningCosmWasmClient.connectWithSigner(srcChain.rpcURL, offlineSigner);

          res = await client.execute(
            sendingAddrInput,
            cw20_ics20.cw20Address,
            {
              send: {
                contract: cw20_ics20.ics20Address,
                amount,
                msg: Buffer.from(
                  JSON.stringify({ channel: cw20_ics20.ibcRecvChannel, remote_address: destUserAddress })
                ).toString("base64"),
              },
            },
            { amount: [executionFee], gas: executionGas }
          );

          sendLink = srcChain.explorerURL + res.transactionHash;
          ({ packet_sequence, packet_src_channel, packet_dst_channel } = getIbcPacketInfoFromEventResponse(res.events));
        }
      }

      // for cw20_ics20 where we are going TO the chain with cw20_ics20
      if (cw20_ics20 && srcChain.chainId !== cw20_ics20.chainId) {
        // in here we are at a cw20-ics20 ibc withdrawal
        const msgTransfer: MsgTransfer = {
          sourcePort: "transfer",
          sourceChannel: cw20_ics20.ibcSendChannel, // we get the channel from the asset specific object
          token: {
            denom: denomToSend,
            amount,
          },
          sender: sendingAddrInput,
          receiver: destUserAddress,
          memo: "",
          timeoutTimestamp: BigInt(String((Date.now() + 600000) * 1000000)),
          timeoutHeight: {
            revisionNumber: BigInt(0),
            revisionHeight: BigInt(0),
          },
        };

        const msg = {
          typeUrl: "/ibc.applications.transfer.v1.MsgTransfer",
          value: msgTransfer,
        };

        res = await sendingClient.signAndBroadcast(sendingAddrInput, [msg], {
          amount: [executionFee],
          gas: executionGas,
        });

        sendLink = srcChain.explorerURL + res.transactionHash;
        ({ packet_sequence, packet_src_channel, packet_dst_channel } = getIbcPacketInfoFromEventResponse(res.events));
      }

      if (packet_sequence !== undefined && packet_src_channel !== undefined && packet_dst_channel !== undefined) {
        const res = await ibcTransferPacketCheck(
          { packet_sequence, packet_src_channel, packet_dst_channel },
          srcChain,
          dstChain
        );

        isSuccessful = res.isSuccessful;
        msg = res.msg;
        timeoutLink = res.timeoutTx ? srcChain.explorerURL + res.timeoutTx.tx_responses[0].txhash : null;
        receiveLink = res.receiveTx ? dstChain.explorerURL + res.receiveTx.tx_responses[0].txhash : null;
        ackLink = res.ackTx ? dstChain.explorerURL + res.ackTx.tx_responses[0].txhash : null;
        console.log({ msg, sendLink, receiveLink, timeoutLink, ackLink, toastID: "" + new Date().getTime() });
      } else {
        throw new Error("IBC transfer failed! Error: " + parseWalletErrorMessage("No packet sequence", i18, []));
      }
    } catch (e) {
      isSuccessful = false;
      msg = "IBC transfer failed! Error: " + parseWalletErrorMessage(e.message, i18, []);
    }
    const toastType = isSuccessful ? "ibc-transfer-success" : "ibc-transfer-fail";

    console.log({
      type: toastType,
      info: { msg, sendLink, receiveLink, timeoutLink, ackLink, toastID: "" + new Date().getTime() },
    });

    dispatch(
      sendToast({
        type: toastType,
        info: { msg, sendLink, receiveLink, timeoutLink, ackLink, toastID: "" + new Date().getTime() },
      })
    );
    if (assetBalancesToUpdate) {
      for (const assetBalanceToUpdate of assetBalancesToUpdate) {
        for (const toUpdateToken of assetBalanceToUpdate.tokens) {
          dispatch(
            updateTokenBalance({
              client: assetBalanceToUpdate.client,
              tokenAddress: toUpdateToken,
              userAddress: assetBalanceToUpdate.userAddress,
            })
          );
        }
        for (const toUpdateNative of assetBalanceToUpdate.natives) {
          dispatch(
            updateNativeBalance({
              client: assetBalanceToUpdate.client,
              denom: toUpdateNative,
              userAddress: assetBalanceToUpdate.userAddress,
            })
          );
        }
      }
    }
  }
);

export const myAssetsUpdateAction = createAsyncThunk<void, { updatedData: string[] }, AsyncThunkConfig>(
  "wallet/myAssetsUpdate",
  async ({ updatedData }, { dispatch, getState }) => {
    const { walletInfo } = getState().wallet;
    const res = await updateUserAssets(
      { myAssets: updatedData.filter(data => !data.includes("dummyRow")) },
      {
        pubkey: walletInfo.pubKey,
        signature: loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)["cosmos_" + walletInfo.pubKey],
      }
    );
    dispatch(updateMyAssets(res.data));
  }
);

export const executePoolAction = createAsyncThunk<
  void,
  {
    poolData: any;
    simulationValues: any;
    isDeposit: boolean;
    i18: any;
  },
  AsyncThunkConfig
>("wallet/executePoolAction", async ({ poolData, simulationValues, isDeposit, i18 }, { dispatch, getState }) => {
  const { walletInfo } = getState().wallet;
  const walletChainContext = walletInfo.connectedChains[poolData.contextChainId];

  let isSuccessful: boolean;
  let msg: string;
  let txLink = null;
  let res = null;
  try {
    res = await executeContract(
      walletChainContext,
      isDeposit ? poolData.address : poolData.lp_staking,
      simulationValues.tx,
      simulationValues.funds
    );
    txLink = `${walletChainContext.chainState.explorerURL}/${res.transactionHash}`;
    isSuccessful = true;
    msg = `${isDeposit ? "Deposit" : "Withdrawal"} on ${poolData.tokenPair} successful!`;

    try {
      const pools = {};
      const poolList = await getListOfPools();
      poolList.data?.forEach(pool => {
        pools[pool.address] = pool;
      });
      dispatch(updatePools(pools));
    } catch {
      console.log("Failed to update pool list");
    }
  } catch (e) {
    console.log(e);
    msg = `${isDeposit ? "Deposit" : "Withdrawal"} on ${poolData.tokenPair} failed! Error: ${parseWalletErrorMessage(
      e.message,
      i18,
      walletChainContext.chainState.feeCurrencies
    )}`;
    isSuccessful = false;
  }
  const toastType = isSuccessful ? "tx-success" : "tx-fail";

  let updateSourceAssetBalance = true;

  // update asset balances of this pool
  for (const poolAsset of poolData.poolAssets) {
    if (poolAsset.info.token) {
      dispatch(
        updateTokenBalance({
          client: walletChainContext.signingClient,
          tokenAddress: poolAsset.info.token.contract_addr,
          userAddress: walletChainContext.address,
        })
      );
    } else {
      if (
        walletChainContext.chainState.feeCurrencies.find(
          feeCurrency => feeCurrency.coinMinimalDenom === poolAsset.info.native_token.denom
        )
      )
        updateSourceAssetBalance = false;

      dispatch(
        updateNativeBalance({
          client: walletChainContext.signingClient,
          denom: poolAsset.info.native_token.denom,
          userAddress: walletChainContext.address,
        })
      );
    }
  }

  // update native asset as it is used for gas fees
  if (updateSourceAssetBalance) {
    if (walletChainContext.chainState.feeCurrencies)
      for (const feeCurrency of walletChainContext.chainState.feeCurrencies) {
        dispatch(
          updateNativeBalance({
            client: walletChainContext.signingClient,
            denom: feeCurrency.coinMinimalDenom,
            userAddress: walletChainContext.address,
          })
        );
      }
  }
  // update lp staking balance
  dispatch(
    updateLpStakingBalance({
      client: walletChainContext.signingClient,
      userAddress: walletChainContext.address,
      lpStakingAddress: poolData.lp_staking,
    })
  );

  // update the pool values locally directly from blockchain
  dispatch(
    updatePoolInfo({
      client: walletChainContext.signingClient,
      poolAddress: poolData.address,
    })
  );

  // send notification
  dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));

  userTxBE(dispatch, walletInfo, [
    {
      chainId: poolData.contextChainId,
      transactionHash: res.transactionHash,
    },
  ]);
});

export const executeFarmAction = createAsyncThunk<
  void,
  {
    chainId: string;
    farmData: any;
    amount: string;
    isDeposit: boolean;
    i18: any;
    notClaimRewards?: boolean;
  },
  AsyncThunkConfig
>(
  "wallet/executeFarmAction",
  async ({ chainId, farmData, amount, isDeposit, i18, notClaimRewards = true }, { dispatch, getState }) => {
    const { wallet } = getState();
    const walletChainContext = wallet.walletInfo.connectedChains[chainId];
    let isSuccessful: boolean;
    let msg: string;
    let txLink = null;
    let res = null;
    try {
      const incAsset = wallet.assets[farmData.config.inc_token];
      res = incAsset.isNative
        ? await executeContract(
            walletChainContext,
            farmData.address,
            isDeposit
              ? {
                  receive: {
                    sender: walletChainContext.address,
                    amount,
                    msg: Buffer.from(
                      JSON.stringify({
                        deposit: {
                          not_claim_rewards: notClaimRewards,
                        },
                      })
                    ).toString("base64"),
                  },
                }
              : {
                  withdrawal: {
                    amount,
                    not_claim_rewards: notClaimRewards,
                  },
                },
            isDeposit ? [{ denom: incAsset.denom, amount }] : []
          )
        : await executeContract(
            walletChainContext,
            isDeposit ? incAsset.address : farmData.address,
            isDeposit
              ? {
                  send: {
                    contract: farmData.address,
                    amount,
                    msg: Buffer.from(
                      JSON.stringify({
                        deposit: {
                          not_claim_rewards: notClaimRewards,
                        },
                      })
                    ).toString("base64"),
                  },
                }
              : {
                  withdrawal: {
                    amount,
                    not_claim_rewards: notClaimRewards,
                  },
                },
            []
          );
      txLink = `${walletChainContext.chainState.explorerURL}/${res.transactionHash}`;
      isSuccessful = true;
      msg = `${isDeposit ? "Deposit" : "Withdrawal"} on farm successful!`;

      try {
        const pools = {};
        const poolList = await getListOfPools();
        poolList.data?.forEach(pool => {
          pools[pool.address] = pool;
        });
        dispatch(updatePools(pools));
      } catch {
        console.log("Failed to update pool list");
      }
    } catch (e) {
      console.log(e);
      msg = `${isDeposit ? "Deposit" : "Withdrawal"} on farm failed! Error: ${parseWalletErrorMessage(
        e.message,
        i18,
        walletChainContext.chainState.feeCurrencies
      )}`;
      isSuccessful = false;
    }
    const toastType = isSuccessful ? "tx-success" : "tx-fail";

    // update asset balances of this pool
    dispatch(
      updateTokenBalance({
        client: walletChainContext.signingClient,
        tokenAddress: farmData.config.inc_token,
        userAddress: walletChainContext.address,
      })
    );

    // update native asset as it is used for gas fees
    if (walletChainContext.chainState.feeCurrencies)
      for (const feeCurrency of walletChainContext.chainState.feeCurrencies) {
        dispatch(
          updateNativeBalance({
            client: walletChainContext.signingClient,
            denom: feeCurrency.coinMinimalDenom,
            userAddress: walletChainContext.address,
          })
        );
      }

    // update lp staking balance
    dispatch(
      updateFarmLpStakingBalance({
        client: walletChainContext.signingClient,
        userAddress: walletChainContext.address,
        lpStakingAddress: farmData.address,
      })
    );

    // send notification
    dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));

    userTxBE(dispatch, wallet.walletInfo, [
      {
        chainId,
        transactionHash: res.transactionHash,
      },
    ]);
  }
);

export const collectRewardsAction = createAsyncThunk<
  void,
  {
    poolsData: IPool[];
    farmsData: IFarm[];
    i18: any;
  },
  AsyncThunkConfig
>("wallet/collectRewardsAction", async ({ poolsData, farmsData, i18 }, { dispatch, getState }) => {
  const { walletInfo } = getState().wallet;
  let isSuccessful = false;
  let msg = "";
  let txLink = null;

  const claimsPerChain: {
    [key: string]: any[];
  } = {};

  poolsData.forEach(poolData => {
    const msg = {
      contractAddress: poolData.lp_staking,
      msg: {
        withdrawal: {
          amount: "0",
        },
      },
    };
    if (claimsPerChain[poolData.contextChainId]) claimsPerChain[poolData.contextChainId].push(msg);
    else claimsPerChain[poolData.contextChainId] = [msg];
  });

  farmsData.forEach(farmData => {
    const msg = {
      contractAddress: farmData.address,
      msg: {
        withdrawal: {
          amount: "0",
        },
      },
    };
    if (claimsPerChain[farmData.contextChainId]) claimsPerChain[farmData.contextChainId].push(msg);
    else claimsPerChain[farmData.contextChainId] = [msg];
  });
  for (const [chainId, msgs] of Object.entries(claimsPerChain)) {
    let res = null;
    const walletChainContext = walletInfo.connectedChains[chainId];
    try {
      // Ledger has a tx size limit, so split request into chunks of 6 messages
      if ("amino" === walletChainContext.signerType) {
        const chunkSize = 6;
        for (let i = 0; i < msgs.length; i += chunkSize) {
          const chunk = msgs.slice(i, i + chunkSize);
          res = await multipleExecuteContract(walletChainContext, chunk);
        }
      } else {
        res = await multipleExecuteContract(walletChainContext, msgs);
      }
      txLink = `${walletChainContext.chainState.explorerURL}/${res.transactionHash}`;
      isSuccessful = true;
      msg = `Collect Rewards from pools/farms successful for chain: ${chainId}!`;
    } catch (e) {
      msg = `Collect Rewards from pools/farms failed for chain: ${chainId}! Error: ${parseWalletErrorMessage(
        e.message,
        i18,
        walletInfo.connectedChains[chainId].chainState.feeCurrencies
      )}`;
      isSuccessful = false;
    }
    const toastType = isSuccessful ? "tx-success" : "tx-fail";

    // update assets that should receive rewards
    let updateSourceAssetBalance = true;
    for (const poolData of poolsData) {
      for (const reward_source of poolData.lp_staking_info.reward_sources) {
        if (reward_source.reward_asset.token) {
          dispatch(
            updateTokenBalance({
              client: walletChainContext.signingClient,
              tokenAddress: reward_source.reward_asset.token.contract_addr,
              userAddress: walletChainContext.address,
            })
          );
        } else {
          if (
            walletInfo.connectedChains[chainId].chainState.feeCurrencies.find(
              feeCurrency => feeCurrency.coinMinimalDenom === reward_source.reward_asset.native_token.denom
            )
          )
            updateSourceAssetBalance = false;

          dispatch(
            updateNativeBalance({
              client: walletChainContext.signingClient,
              denom: reward_source.reward_asset.native_token.denom,
              userAddress: walletChainContext.address,
            })
          );
        }
      }
    }

    for (const farmData of farmsData) {
      for (const reward_source of farmData.reward_sources) {
        if (reward_source.reward_asset.token) {
          dispatch(
            updateTokenBalance({
              client: walletChainContext.signingClient,
              tokenAddress: reward_source.reward_asset.token.contract_addr,
              userAddress: walletChainContext.address,
            })
          );
        } else {
          if (
            walletInfo.connectedChains[chainId].chainState.feeCurrencies.find(
              feeCurrency => feeCurrency.coinMinimalDenom === reward_source.reward_asset.native_token.denom
            )
          )
            updateSourceAssetBalance = false;

          dispatch(
            updateNativeBalance({
              client: walletChainContext.signingClient,
              denom: reward_source.reward_asset.native_token.denom,
              userAddress: walletChainContext.address,
            })
          );
        }
      }
    }

    // update native asset as it is used for gas fees
    if (updateSourceAssetBalance) {
      for (const feeCurrency of walletInfo.connectedChains[chainId].chainState.feeCurrencies) {
        dispatch(
          updateNativeBalance({
            client: walletChainContext.signingClient,
            denom: feeCurrency.coinMinimalDenom,
            userAddress: walletChainContext.address,
          })
        );
      }
    }

    // update lp staking balance
    for (const poolData of poolsData) {
      dispatch(
        updateLpStakingBalance({
          client: walletChainContext.signingClient,
          userAddress: walletChainContext.address,
          lpStakingAddress: poolData.lp_staking,
        })
      );
    }
    for (const farmData of farmsData) {
      dispatch(
        updateFarmLpStakingBalance({
          client: walletChainContext.signingClient,
          userAddress: walletChainContext.address,
          lpStakingAddress: farmData.address,
        })
      );
    }

    // send notification
    dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));
  }
});

export const executeGRVT8BurnAction = createAsyncThunk<
  void,
  {
    chainId: string;
    cashbackAddr: string;
    axvAddr: string;
    amount: string;
    i18: any;
  },
  AsyncThunkConfig
>("wallet/executeGRVT8BurnAction", async ({ chainId, cashbackAddr, axvAddr, amount, i18 }, { dispatch, getState }) => {
  const { walletInfo } = getState().wallet;
  const walletChainContext = walletInfo.connectedChains[chainId];
  let isSuccessful: boolean;
  let msg: string;
  let txLink = null;
  let res = null;
  try {
    res = await executeContract(walletChainContext, cashbackAddr, { burn: { amount } });
    txLink = `${walletChainContext.chainState.explorerURL}/${res.transactionHash}`;
    isSuccessful = true;
    msg = `GRVT8 burn successful!`;
  } catch (e) {
    msg = `GRVT8 burn failed! Error: ${parseWalletErrorMessage(
      e.message,
      i18,
      walletChainContext.chainState.feeCurrencies
    )}`;
    isSuccessful = false;
  }
  const toastType = isSuccessful ? "tx-success" : "tx-fail";

  // update source denom due to gas fees payed
  if (walletChainContext.chainState.feeCurrencies)
    for (const feeCurrency of walletChainContext.chainState.feeCurrencies) {
      dispatch(
        updateNativeBalance({
          client: walletChainContext.signingClient,
          denom: feeCurrency.coinMinimalDenom,
          userAddress: walletChainContext.address,
        })
      );
    }

  // update Cashback and AXV balances as these are the assets affected by this tx
  dispatch(
    updateTokenBalance({
      client: walletChainContext.signingClient,
      tokenAddress: cashbackAddr,
      userAddress: walletChainContext.address,
    })
  );
  dispatch(
    updateTokenBalance({
      client: walletChainContext.signingClient,
      tokenAddress: axvAddr,
      userAddress: walletChainContext.address,
    })
  );

  // send notification
  dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));

  userTxBE(dispatch, walletInfo, [
    {
      chainId,
      transactionHash: res.transactionHash,
    },
  ]);
});

export const executeClaimAirdrop = createAsyncThunk<
  void,
  {
    chainId: string;
    airdropAddr: string;
    axvAddr: string;
    exchangeAsset?: any;
    exchangeAssetAmount?: any;
    i18: any;
  },
  AsyncThunkConfig
>(
  "wallet/executeClaimAirdrop",
  async ({ chainId, airdropAddr, axvAddr, exchangeAsset, exchangeAssetAmount, i18 }, { dispatch, getState }) => {
    const { walletInfo } = getState().wallet;
    const walletChainContext = walletInfo.connectedChains[chainId];
    let isSuccessful: boolean;
    let msg: string;
    let txLink = null;
    let res = null;
    try {
      if (!exchangeAsset) {
        res = await executeContract(walletChainContext, airdropAddr, { wallet_claim: {} });
      } else if (exchangeAsset.native_token) {
        res = await executeContract(walletChainContext, airdropAddr, { exchange_asset_claim: {} }, [
          { denom: exchangeAsset.native_token.denom, amount: exchangeAssetAmount },
        ]);
      }
      txLink = `${walletChainContext.chainState.explorerURL}/${res.transactionHash}`;
      isSuccessful = true;
      msg = `Airdrop claim successful!`;
    } catch (e) {
      msg = `Airdrop claim failed! Error: ${parseWalletErrorMessage(
        e.message,
        i18,
        walletChainContext.chainState.feeCurrencies
      )}`;
      isSuccessful = false;
    }
    const toastType = isSuccessful ? "tx-success" : "tx-fail";

    if (walletChainContext.chainState.feeCurrencies)
      for (const feeCurrency of walletChainContext.chainState.feeCurrencies) {
        dispatch(
          updateNativeBalance({
            client: walletChainContext.signingClient,
            denom: feeCurrency.coinMinimalDenom,
            userAddress: walletChainContext.address,
          })
        );
      }

    // update AXV balances as these are the assets affected by this tx
    dispatch(
      updateTokenBalance({
        client: walletChainContext.signingClient,
        tokenAddress: axvAddr,
        userAddress: walletChainContext.address,
      })
    );

    // send notification
    dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));
  }
);

export const executeAssetAction = createAsyncThunk<
  void,
  {
    chainId: string;
    simulationValues: any;
    pools: { [key: string]: IPool };
    assets: { [key: string]: IAsset };
    contracts: { [key: string]: IContract };
    fromAsset: any;
    toAsset: any;
    granterAddress?: string;
    walletSignerType?: string;
    customSigner?: {
      signingClient: SigningStargateClient;
      address: string;
      chainState: IChain;
    };
    i18: any;
  },
  AsyncThunkConfig
>(
  "wallet/executeAssetAction",
  async (
    {
      chainId,
      simulationValues,
      pools,
      assets,
      contracts,
      fromAsset,
      toAsset,
      granterAddress,
      walletSignerType,
      i18,
      customSigner,
    },
    { dispatch, getState }
  ) => {
    const { walletInfo, chains } = getState().wallet;
    const walletChainContext = walletInfo.connectedChains[chainId];
    let isSuccessful: boolean;
    let msg: string;
    let txLink = null;
    let res = null;
    try {
      if (granterAddress && simulationValues.tx.contract) {
        res = await executeFeeGrantTx(
          walletChainContext.archwayClient,
          walletChainContext.address,
          simulationValues.tx.contract.toExecuteContract,
          simulationValues.tx.contract.msgBody,
          granterAddress,
          chainId,
          chains[chainId].rpc,
          walletSignerType,
          simulationValues.tx.contract.funds
        );
      }
      if (!granterAddress && simulationValues.tx.contract) {
        res = await executeContract(
          walletChainContext,
          simulationValues.tx.contract.toExecuteContract,
          simulationValues.tx.contract.msgBody,
          simulationValues.tx.contract.funds
        );
      }
      if (!granterAddress && simulationValues.tx.nativeTransfer) {
        res = await (customSigner?.signingClient || walletChainContext.signingClient).sendTokens(
          customSigner?.address || walletChainContext.address,
          simulationValues.tx.nativeTransfer.toAddress,
          simulationValues.tx.nativeTransfer.amount,
          calculateFee(
            Math.round(150000),
            (customSigner || walletChainContext).chainState.defaultFeeTransfer ||
              (customSigner || walletChainContext).chainState.defaultFee
          ),
          undefined
        );
      }
      txLink = `${customSigner?.chainState?.explorerURL || walletChainContext?.chainState?.explorerURL}/${
        res.transactionHash
      }`;
      isSuccessful = true;
      msg = `${simulationValues.actionType} from ${fromAsset.symbol} to ${toAsset.symbol} successful!`;
    } catch (e) {
      console.log(e);
      msg = `${simulationValues.actionType} from ${fromAsset.symbol} to ${
        toAsset.symbol
      } failed! Error: ${parseWalletErrorMessage(
        e.message,
        i18,
        customSigner?.chainState?.feeCurrencies || walletChainContext?.chainState?.feeCurrencies
      )}`;
      isSuccessful = false;
    }
    const toastType = isSuccessful ? "tx-success" : "tx-fail";

    let updateSourceAssetBalance = true;

    // update balance on both assets
    for (const asset of [fromAsset, toAsset]) {
      if (asset.isNative) {
        if (
          (customSigner?.chainState?.feeCurrencies || walletChainContext.chainState.feeCurrencies).find(
            feeCurrency => feeCurrency.coinMinimalDenom === asset.denom
          )
        )
          updateSourceAssetBalance = false;
        dispatch(
          updateNativeBalance({
            client: customSigner?.signingClient || walletChainContext.signingClient,
            userAddress: customSigner?.address || walletChainContext.address,
            denom: asset.denom,
          })
        );
      } else {
        dispatch(
          updateTokenBalance({
            client: walletChainContext.signingClient,
            userAddress: walletChainContext.address,
            tokenAddress: asset.address,
          })
        );
      }
    }

    // update native asset as it is used for gas fees
    if (updateSourceAssetBalance) {
      if (customSigner?.chainState.feeCurrencies || walletChainContext.chainState.feeCurrencies)
        for (const feeCurrency of customSigner?.chainState.feeCurrencies ||
          walletChainContext.chainState.feeCurrencies) {
          dispatch(
            updateNativeBalance({
              client: customSigner?.signingClient || walletChainContext.signingClient,
              denom: feeCurrency.coinMinimalDenom,
              userAddress: customSigner?.address || walletChainContext.address,
            })
          );
        }
    }

    // update the state of the pools where this swap was executed
    if (simulationValues.route)
      for (const route of simulationValues.route) {
        dispatch(
          updatePoolInfo({
            client: walletChainContext.signingClient,
            poolAddress: route.p,
          })
        );
      }

    // send notification
    dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));

    userTxBE(dispatch, walletInfo, [
      {
        chainId,
        transactionHash: res.transactionHash,
      },
    ]);
  }
);

export const increasePoolAllowance = createAsyncThunk<
  { allowance: string } | null,
  {
    poolData: any;
    tokenAddress: string;
    i18: any;
  },
  AsyncThunkConfig
>("wallet/increasePoolAllowance", async ({ poolData, tokenAddress, i18 }, { dispatch, getState }) => {
  const { walletInfo } = getState().wallet;
  const walletChainContext = walletInfo.connectedChains[poolData.contextChainId];

  let isSuccessful: boolean;
  let msg: string;
  let txLink = null;
  try {
    const res = await executeContract(walletChainContext, tokenAddress, {
      increase_allowance: {
        spender: poolData.address,
        amount: "340282366920938463463374607431768211454",
      },
    });
    txLink = `${walletChainContext.chainState.explorerURL}/${res.transactionHash}`;
    // if (res.rawLog) throw Error(res.rawLog);
    msg = "";
    isSuccessful = true;
  } catch (e) {
    msg = parseWalletErrorMessage(e.message, i18, walletChainContext.chainState.feeCurrencies);
    isSuccessful = false;
  }
  const toastType = isSuccessful ? "tx-success" : "tx-fail";

  // update native asset as it is used for gas fees
  if (walletChainContext.chainState.feeCurrencies)
    for (const feeCurrency of walletChainContext.chainState.feeCurrencies) {
      dispatch(
        updateNativeBalance({
          client: walletChainContext.signingClient,
          denom: feeCurrency.coinMinimalDenom,
          userAddress: walletChainContext.address,
        })
      );
    }

  // send notification
  dispatch(sendToast({ type: toastType, info: { msg, txLink, toastID: "" + new Date().getTime() } }));

  return isSuccessful ? { allowance: "340282366920938463463374607431768211454" } : { allowance: "0" };
});

export const fetchUserInfo = async (signature = "", pubKey: string, noLogsFetch = false) => {
  const userSignature = signature || loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)["cosmos_" + pubKey];
  try {
    // setIsUserLoading(true);

    if (!userSignature) return;
    const common = {
      pubkey: pubKey,
      signature: userSignature,
    };

    if (noLogsFetch) {
      const { data } = await getUser(common);
      return data;
    }

    const [{ data }, userLogs, userComponentBalances] = await Promise.all([
      getUser(common),
      getUserLogs(common),
      getUserComponentBalances(common),
    ]);
    return { ...data, swapLogs: userLogs.data, ...userComponentBalances.data };
  } catch (err) {
    // if 401 code it can mean the permit is not stored on the DB but is on localstorage,
    // so we try to update on the API and redo the query
    if ("Unauthorized" === err.message) {
      const data = await postSignature({
        pub_key: { type: "tendermint/PubKeySecp256k1", value: pubKey },
        signature: userSignature,
      });
      if (data.ok) {
        persistState(WHITE_LIST_PERSISTED_STATE_KEYS.permits, {
          ...loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits),
          [pubKey]: userSignature,
        });
        const common = {
          pubkey: pubKey,
          signature: userSignature,
        };
        const [{ data }, userLogs, userComponentBalances] = await Promise.all([
          getUser(common),
          getUserLogs(common),
          getUserComponentBalances(common),
        ]);

        return { ...data, swapLogs: userLogs.data, ...userComponentBalances.data };
      }
    }
  }
};

const fetchUserLogs = async (signature = "", pubKey: string) => {
  try {
    // setIsUserLoading(true);
    const userSignature = signature || loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)["cosmos_" + pubKey];
    if (!userSignature) return;
    const common = {
      pubkey: pubKey,
      signature: userSignature,
    };
    const userLogs = await getUserLogs(common);

    return userLogs.data;
  } catch (err) {
    console.log(err);
    // snackbar.error(i18("header.userDataFailed", "Failed fetching details"));
  } finally {
    // setIsUserLoading(false);
  }
};

export const getUserSwapLogs = createAsyncThunk<void, string, AsyncThunkConfig>(
  "wallet/getUserSwapLogs",
  async (signature, { dispatch, getState }) => {
    const {
      walletInfo: { pubKey },
    } = getState().wallet;
    const swapLogs = await fetchUserLogs(signature, pubKey);
    dispatch(setUserSwapLogs(swapLogs));
  }
);

export const handleAuthentication = createAsyncThunk<void, void, AsyncThunkConfig>(
  "wallet/handleAuthentication",
  async (_, { dispatch, getState }) => {
    if (executingAuthenticationOnAccountChange) return;
    try {
      // permit is stored on localstorage of the browser
      const walletInfo = getState().wallet.walletInfo;
      const permits = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits);
      let sign: { signature: string };
      if (!permits["cosmos_" + walletInfo.pubKey]) {
        const signature = await createUserPermit();
        const data = await postSignature(signature);

        if (data.ok) {
          persistState(WHITE_LIST_PERSISTED_STATE_KEYS.permits, {
            ...loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits),
            ["cosmos_" + signature.pub_key.value]: signature.signature,
          });
        }
        sign = signature;
      } else {
        sign = permits["cosmos_" + walletInfo.pubKey];
      }

      const userInfo = await fetchUserInfo(sign.signature, walletInfo.pubKey);
      dispatch(updateUser(userInfo));
      // snackbar.success(i18("header.permitSaved", "Success saving permit"));
    } catch (err) {
      // snackbar.error(i18("header.permitError", "Failed creating permit"));
      console.error(err);
    }
  }
);

export const updatePoolInfo = createAsyncThunk<
  void,
  {
    client: SigningCosmWasmClient;
    poolAddress: string;
  },
  AsyncThunkConfig
>("wallet/updatePoolInfo", async ({ client, poolAddress }, { dispatch }) => {
  const info = await client.queryContractSmart(poolAddress, {
    pool: {},
  });

  dispatch(
    setPoolInfo({
      poolAddress: poolAddress,
      amounts: info.assets.map(asset => asset.amount),
      total_share: info.total_share,
    })
  );
});

export const updateUserPriceOrder = createAsyncThunk<void, { priceWatchList: any[] }, AsyncThunkConfig>(
  "wallet/onPriceWatchDragEnd",
  async ({ priceWatchList }, { dispatch, getState }) => {
    const { pubKey } = getState().wallet.walletInfo;
    const permits = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits);
    if (permits["cosmos_" + pubKey]) {
      try {
        const watchList = [...priceWatchList];

        await updateUserPriceWatch(
          { priceWatch: watchList },
          {
            pubkey: pubKey,
            signature: permits["cosmos_" + pubKey],
          }
        );

        // snackbar.success(i18("dashboard.priceWatch.order.saved", "Success saving price order"));

        dispatch(updatePriceWatch(watchList));
      } catch (err) {
        console.error(err);
        // snackbar.error(i18("dashboard.priceWatch.not.order.saved", "Price order not saved. Please try later"));
      }
    }
  }
);
export const removePriceWatch = createAsyncThunk<void, string, AsyncThunkConfig>(
  "wallet/removePriceWatch",
  async (assetId, { dispatch, getState }) => {
    const { pubKey } = getState().wallet.walletInfo;
    const { priceWatch } = getState().wallet.user;
    if (!priceWatch?.length) return;
    const priceWatchList = priceWatch.filter(item => item.assetId !== assetId);
    try {
      const watchList = [...priceWatchList];

      await updateUserPriceWatch(
        { priceWatch: watchList },
        {
          pubkey: pubKey,
          signature: loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)["cosmos_" + pubKey],
        }
      );

      // snackbar.success(i18("dashboard.priceWatch.order.saved", "Success saving price order"));

      dispatch(updatePriceWatch(watchList));
    } catch (err) {
      console.error(err);
      // snackbar.error(i18("dashboard.priceWatch.not.order.saved", "Price order not saved. Please try later"));
    }
  }
);

export const updateGRVT8Balance = createAsyncThunk<
  string,
  {
    client: SigningCosmWasmClient;
    grvt8Contract: IContract;
    walletAddress: string;
  },
  AsyncThunkConfig
>("wallet/updateGRVT8Balance", async ({ client, grvt8Contract, walletAddress }, { dispatch }) => {
  const res = await rpcClientQuerySmartContractWrapper(client, grvt8Contract.address, {
    balance: { address: walletAddress },
  });

  const balance = BigNumber(res.balance).div(Math.pow(10, grvt8Contract.config.decimals)).toString(10);

  dispatch(
    setGRVT8Balance({
      grvt8Address: grvt8Contract.address,
      amount: balance,
    })
  );

  return balance;
});

export const executeFeeGrantTx = async (
  client: SigningArchwayClient,
  walletAddress: string,
  contractAddress: string,
  executeMsg,
  granterAddress: string,
  chainId: string,
  rpc: string,
  walletSignerType?: string,
  funds?: Coin[]
) => {
  const msgSend = MsgExecuteContract.fromPartial({
    msg: new Uint8Array(Buffer.from(JSON.stringify(executeMsg))),
    sender: walletAddress,
    contract: contractAddress,
    funds,
  });

  const { stargateSigner, queryService } = await getFeeGrantSigners(chainId, rpc);

  const estimatedGasFee = 3200000;
  const { estimatedFee } = await client.getEstimateTxFees(estimatedGasFee);
  let accNumber: number;

  const acc = await stargateSigner.getAccount(walletAddress);
  if (null === acc) {
    const accounts = await queryService.Accounts({
      pagination: {
        countTotal: true,
        limit: BigInt(1),
        key: new Uint8Array(),
        offset: BigInt(0),
        reverse: false,
      },
    });
    accNumber = parseInt(accounts.pagination.total.toString()) - 1;
  } else {
    accNumber = acc.accountNumber;
  }

  const msg: MsgExecuteContractEncodeObject = {
    typeUrl: "/cosmwasm.wasm.v1.MsgExecuteContract",
    value: msgSend,
  };

  let txRaw: TxRaw;
  const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
  let walletExtensionClient = window[walletExtension];

  if ("cosmostation" === walletExtension) {
    walletExtensionClient = window.cosmostation.providers.keplr;
  }

  walletExtensionClient.defaultOptions = {
    sign: {
      preferNoSetFee: true,
      preferNoSetMemo: true,
      disableBalanceCheck: true,
    },
  };
  if (!walletSignerType || "direct" === walletSignerType) {
    txRaw = await stargateSigner.sign(
      walletAddress,
      [msg],
      {
        ...estimatedFee,
        granter: granterAddress,
        payer: "",
      },
      "",
      {
        accountNumber: accNumber,
        sequence: 0,
        chainId,
      }
    );
  } else {
    // AMINO hack sign fix
    const aminoOfflineSigner = walletExtensionClient.getOfflineSignerOnlyAmino(chainId);
    txRaw = await signHackedAmino(
      stargateSigner,
      aminoOfflineSigner,
      walletAddress,
      [msg],
      {
        ...estimatedFee,
        granter: granterAddress,
        payer: "",
      },
      "",
      {
        accountNumber: accNumber,
        sequence: 0,
        chainId,
      }
    );
  }

  walletExtensionClient.defaultOptions = {
    sign: {
      preferNoSetFee: true,
      preferNoSetMemo: true,
      disableBalanceCheck: false,
    },
  };

  return await postFeeGrantTx(
    {
      txRaw: {
        bodyBytes: Array.from(txRaw.bodyBytes),
        authInfoBytes: Array.from(txRaw.authInfoBytes),
        signatures: [Array.from(txRaw.signatures[0])],
      },
    },
    chainId
  );
};

export const updateUserGlobalSettingsFields = createAsyncThunk<
  void,
  {
    noDisplayNicknames?: boolean;
    menuPosition?: string;
    dashboardMyAssetsActiveFilters?: { display: string; sorting: string };
    dashboardPortfolioActiveFilters?: { display: string; filter: string; xMerge: boolean; groupChains: boolean };
    slippageTolerance?: {
      type: string;
      amount: string;
    };
    assetTemplates?: IAssetTemplate[];
    tradeIsolatedPoolTrading?: boolean;
  },
  AsyncThunkConfig
>("wallet/updateUserGlobalSettingsFields", async (toUpdateFields = {}, { dispatch, getState }) => {
  const { walletInfo, user } = getState().wallet;
  const globalSettings = { ...user.myGlobalSettings, ...toUpdateFields };
  try {
    await updateUserGlobalSettings(
      { myGlobalSettings: globalSettings },
      {
        pubkey: walletInfo.pubKey,
        signature: loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)["cosmos_" + walletInfo.pubKey],
      }
    );
  } catch (e) {
    console.log(e);
  }
  dispatch(setMyGlobalSettings(globalSettings));
});

export const deleteMyDashboardGrid = createAsyncThunk<void, void, AsyncThunkConfig>(
  "wallet/deleteMyDashboardGrid",
  async (_, { dispatch, getState }) => {
    const { walletInfo } = getState().wallet;
    localStorage.removeItem(WHITE_LIST_PERSISTED_STATE_KEYS.dashboardGrid);
    try {
      updateUserDashboardGrid(
        { myDashboardGrid: null },
        {
          pubkey: walletInfo.pubKey,
          signature: loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits)["cosmos_" + walletInfo.pubKey],
        }
      );
    } catch (e) {
      console.log(e);
    }
    dispatch(setMyDashboardGrid(null));
  }
);

export const secretNetworkCreateViewingKey = async (secretViewingKey: {
  restURL: string;
  chainId: string;
  sendingAddr: string;
  contract_address: string;
}) => {
  const walletExtension: string = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.connectedWallet);
  let walletExtensionClient = window[walletExtension];
  if ("cosmostation" === walletExtension) {
    walletExtensionClient = window.cosmostation.providers.keplr;
  }
  await walletExtensionClient.suggestToken(secretViewingKey.chainId, secretViewingKey.contract_address);

  // const offlineSigner = await walletExtensionClient.getOfflineSignerAuto(secretViewingKey.chainId);
  // const client = await new SecretNetworkClient({
  //   url: secretViewingKey.restURL,
  //   chainId: secretViewingKey.chainId,
  //   wallet: offlineSigner,
  //   walletAddress: secretViewingKey.sendingAddr,
  // });
  // const random = new Uint8Array(32);
  // crypto.getRandomValues(random);
  // const key = Buffer.from(random).toString("hex");

  // const res = await client.tx.compute.executeContract(
  //   {
  //     sender: secretViewingKey.sendingAddr,
  //     contract_address: secretViewingKey.contract_address,
  //     msg: {
  //       set_viewing_key: {
  //         key,
  //       },
  //     },
  //   },
  //   { gasLimit: 150000 }
  // );

  // console.log(res);
};

// send tx to BE, so it's updated for this user on the DB and trigger update User info
export const userTxBE = async (
  dispatch: any,
  walletInfo: IWalletInfo,
  txs: {
    chainId: string;
    transactionHash: string;
  }[]
) => {
  const permits = loadState(WHITE_LIST_PERSISTED_STATE_KEYS.permits);
  const promises = [];
  if (permits["cosmos_" + walletInfo.pubKey]) {
    for (const tx of txs) {
      promises.push(
        postTransactions(
          { txHash: tx.transactionHash, chainId: tx.chainId },
          {
            pubkey: walletInfo.pubKey,
            signature: permits["cosmos_" + walletInfo.pubKey],
            chainId: tx.chainId,
          }
        )
      );
    }
    await Promise.all(promises);
    const userInfo = await fetchUserInfo(permits["cosmos_" + walletInfo.pubKey], walletInfo.pubKey);
    dispatch(updateUser(userInfo));
  }
};
