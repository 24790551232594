import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BigNumber from "bignumber.js";
import { AlertTriangle } from "react-feather";
import { GasPrice } from "@cosmjs/stargate";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import {
  selectAssetBalances,
  selectAssets,
  selectChains,
  selectGlobalConfig,
  selectWalletInfo,
} from "@axvdex/state/wallet/walletSelectors";
import {
  executeIbcTransfer,
  getIbcClientSigner,
  updateNativeBalance,
  updateTokenBalance,
} from "@axvdex/state/wallet/walletThunks";
import { getFeeGrantOptions } from "@axvdex/api/user";
import imgSanitize from "@axvdex/utils/imgSanitize";
import useLanguage from "@axvdex/hooks/useLanguage";
import { makeLists } from "../forms/tradeHelpers";
import Button from "../common/Button";
import CustomSelect, { ListItemsProps } from "../form-element/CustomSelect";

export default function FeegrantContainer({ setFeeGrantAssetsToTrade, chainId }: any) {
  const { i18 } = useLanguage();
  const navigate = useNavigate();
  const walletInfo = useAppSelector(selectWalletInfo);
  const chains = useAppSelector(selectChains);
  const assets = useAppSelector(selectAssets);
  const assetBalances = useAppSelector(selectAssetBalances);
  const globalConfig = useAppSelector(selectGlobalConfig);
  const dispatch = useAppDispatch();
  const [feeGrantOptions, setFeeGrantOptions] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState<ListItemsProps>({
    label: "ATOM",
    value:
      Object.values(assets).find(asset => "ATOM" === asset.symbol).symbol +
      "_" +
      chains[Object.values(assets).find(asset => "ATOM" === asset.symbol).contextChainId].displayName,
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    optionPrefix: <img src={require(`../../assets/tokens/logo-atom.svg`).default} alt={""} />,
  });
  const [selectedAssetState, setSelectedAssetState] = useState(null);
  const [ibcDepositDetails, setIbcDepositDetails] = useState(null);
  const [ibcDepositLoading, setIbcDepositLoading] = useState<boolean>(false);

  const walletChainContext = walletInfo?.connectedChains[chainId];

  useEffect(() => {
    getFeeGrant();
  }, []);

  const getFeeGrant = async () => {
    const options = await getFeeGrantOptions(chainId);
    setFeeGrantOptions(options.data.feeGrants);
  };

  useEffect(() => {
    walletInfo.isConnected && Object.keys(assets).length > 0 && feeGrantOptions && getSelectedAssetBalance();
  }, [selectedAsset, walletInfo, feeGrantOptions]);

  const getIBCDepositDetails = async (selectedAsset, newSelectedAssetState) => {
    setIbcDepositDetails(null);
    const feeGrantOptionAsset = feeGrantOptions.assets.find(
      feeGrantAsset => feeGrantAsset.symbol === selectedAsset.label
    );

    const srcChain = chains[feeGrantOptionAsset.chainId];
    const destChain = chains[chainId];

    const { acc_address, balance, client } = await getIbcClientSigner(
      srcChain,
      newSelectedAssetState.denom_trace.base_denom
    );

    setIbcDepositDetails({
      srcChain,
      destChain,
      senderAddr: acc_address,
      senderBalance: balance,
      senderClient: client,
    });
  };

  const getSelectedAssetBalance = async () => {
    if (!assetBalances[walletChainContext.chainState.feeCurrencies[0].coinMinimalDenom]) {
      dispatch(
        updateNativeBalance({
          client: walletChainContext.signingClient,
          denom: walletChainContext.chainState.feeCurrencies[0].coinMinimalDenom,
          userAddress: walletChainContext.address,
        })
      );
    }
    for (const id in assets) {
      if (assets[id].symbol === selectedAsset.label) {
        setSelectedAssetState(assets[id]);
        if (!assetBalances[id]) {
          if (!assets[id].isNative) {
            dispatch(
              updateTokenBalance({
                client: walletChainContext.signingClient,
                tokenAddress: assets[id].address,
                userAddress: walletChainContext.address,
              })
            );
          } else {
            dispatch(
              updateNativeBalance({
                client: walletChainContext.signingClient,
                denom: assets[id].denom,
                userAddress: walletChainContext.address,
              })
            );
          }
        }
        getIBCDepositDetails(selectedAsset, assets[id]);
        break;
      }
    }
  };

  // useEffect(() => {
  //   if (
  //     feeGrantOptions &&
  //     assetBalances[walletChainContext.chainState.feeCurrencies[0].coinMinimalDenom] === "0" &&
  //     feeGrantOptions.enabled
  //   )
  //     window.scrollTo({ top: 999999999999999, behavior: "smooth" });
  // }, [feeGrantOptions]);

  if (
    !feeGrantOptions ||
    assetBalances[walletChainContext.chainState.feeCurrencies[0].coinMinimalDenom] !== "0" ||
    (feeGrantOptions && !feeGrantOptions.enabled)
  )
    return <></>;

  const feeGrantAsset = feeGrantOptions.assets.find(feeGrantAsset => feeGrantAsset.symbol === selectedAsset.label);

  const displayDeposit =
    selectedAssetState &&
    assetBalances[selectedAssetState.id] &&
    BigNumber(assetBalances[selectedAssetState.id]).lt(
      BigNumber(feeGrantAsset.tradeMinAmount || feeGrantAsset.minAmount)
    ) &&
    ibcDepositDetails;
  let depositAmount, depositSrcChain;
  if (displayDeposit) {
    depositAmount = BigNumber(feeGrantAsset.minAmount).div(Math.pow(10, selectedAssetState.decimals));
    depositSrcChain = ibcDepositDetails ? ibcDepositDetails.srcChain.displayName : "";
  }

  const displayTrade =
    selectedAssetState &&
    assetBalances[selectedAssetState.id] &&
    BigNumber(assetBalances[selectedAssetState.id]).gte(
      BigNumber(feeGrantAsset.tradeMinAmount || feeGrantAsset.minAmount)
    );
  let tradeAmount;
  if (displayTrade) {
    tradeAmount = BigNumber(feeGrantAsset.tradeMinAmount || feeGrantAsset.minAmount).div(
      Math.pow(10, selectedAssetState.decimals)
    );
  }

  const displayBalance =
    ibcDepositDetails && BigNumber(ibcDepositDetails.senderBalance).lt(feeGrantAsset.minAmount) && !ibcDepositLoading;
  let balanceAmount, balanceSrcChain;
  if (displayBalance) {
    balanceAmount = BigNumber(ibcDepositDetails.senderBalance).div(Math.pow(10, selectedAssetState.decimals));
    balanceSrcChain = ibcDepositDetails ? ibcDepositDetails.srcChain.displayName : "";
  }

  const handleSelectAsset = (newValue: ListItemsProps) => {
    setSelectedAsset(newValue);
  };

  return (
    <section className="feeGrantSection withGradientBorder">
      <div className="feeGrantSectionHeader">
        <p>{i18("Bootstrap your wallet using our fee grant on Archway.", "trade.feeGrant.header")}</p>

        <CustomSelect
          name="available_fee_grant_assets"
          items={feeGrantOptions.assets.map(feeGrantAsset => {
            return {
              label: feeGrantAsset.symbol,
              value: feeGrantAsset.symbol + "_" + chains[feeGrantAsset.chainId].displayName,
              optionPrefix: <img src={imgSanitize(feeGrantAsset.symbol)} alt={""} />,
            };
          })}
          value={selectedAsset}
          hiddenLabel={true}
          onChange={handleSelectAsset}
          labelText={i18("Select fee grant", "trade.feeGrant.selectAsset.label")}
          required
        />
      </div>

      <div className="feeGrantSectionActions">
        {displayDeposit && (
          <>
            <Button
              btnColor="gradient"
              text={
                ibcDepositLoading
                  ? i18("Executing...", "trade.feeGrant.executing")
                  : i18(
                      `Deposit ${depositAmount} ${selectedAsset.label} via IBC from ${depositSrcChain}`,
                      `trade.feeGrant.deposit`,
                      {
                        amount: depositAmount,
                        symbol: selectedAsset.label,
                        chain: depositSrcChain,
                      }
                    )
              }
              onClick={async () => {
                setIbcDepositLoading(true);
                const channel = ibcDepositDetails.destChain.ibcDenoms.find(
                  (ibcDenom: any) => ibcDenom.base_denom === selectedAssetState.denom_trace.base_denom
                )?.base_chain_channel_id;
                if (!channel) throw new Error("Channel not found");

                Object.values(assets).find(asset => asset.symbol === selectedAsset.label);
                feeGrantOptions.assets.find(feeGrantAsset => feeGrantAsset.symbol === selectedAsset.label);
                await dispatch(
                  executeIbcTransfer({
                    sendingClient: ibcDepositDetails.senderClient,
                    sendingAddrInput: ibcDepositDetails.senderAddr,
                    // for deposit the destination is the wallet in archway
                    destUserAddress: walletChainContext.address,
                    denomToSend: selectedAssetState.denom_trace.base_denom,
                    amount: feeGrantOptions.assets.find(feeGrantAsset => feeGrantAsset.symbol === selectedAsset.label)
                      .minAmount,
                    channel,
                    fee: {
                      denom: GasPrice.fromString(ibcDepositDetails.srcChain.defaultFee).denom,
                      amount: BigNumber(GasPrice.fromString(ibcDepositDetails.srcChain.defaultFee).amount.toString())
                        .times(Math.pow(10, selectedAssetState.decimals))
                        .decimalPlaces(0)
                        .toString(10),
                    },
                    // on deposit, we want to check if the packet is a timeout on the "from" chain
                    srcChain: {
                      chainId: ibcDepositDetails.srcChain.chainId,
                      restURL: ibcDepositDetails.srcChain.rest,
                      explorerURL: ibcDepositDetails.srcChain.explorerURL,
                      rpcURL: ibcDepositDetails.srcChain.rpc,
                      isEVM: ibcDepositDetails.srcChain.isEVM,
                    },
                    // on deposit, we want to check if the packet has arrived to archway
                    dstChain: {
                      chainId: ibcDepositDetails.destChain.chainId,
                      restURL: ibcDepositDetails.destChain.rest,
                      explorerURL: ibcDepositDetails.destChain.explorerURL,
                      rpcURL: ibcDepositDetails.destChain.rpc,
                      isEVM: ibcDepositDetails.destChain.isEVM,
                    },
                    // on deposit, we need to only update the balance of the destination asset, with the denom of it on archway chain
                    assetBalancesToUpdate: [
                      {
                        client: walletChainContext.signingClient,
                        userAddress: walletChainContext.address,
                        tokens: [],
                        natives: [selectedAssetState.denom],
                      },
                    ],
                    i18,
                  })
                );
                setIbcDepositLoading(false);
              }}
              disabled={
                ibcDepositLoading ||
                !ibcDepositDetails ||
                (ibcDepositDetails &&
                  BigNumber(ibcDepositDetails.senderBalance).lt(
                    feeGrantOptions.assets.find(feeGrantAsset => feeGrantAsset.symbol === selectedAsset.label).minAmount
                  ))
              }
            />

            {displayBalance && (
              <small className="feeGrantSectionActionsBalance">
                <span className="feeGrantSectionActionsIcon">
                  <AlertTriangle />
                </span>
                <span>
                  {i18(
                    `Balance: ${balanceAmount} ${selectedAsset.label} on ${balanceSrcChain}`,
                    "trade.feeGrant.balance",
                    {
                      amount: balanceAmount,
                      symbol: selectedAsset.label,
                      chain: balanceSrcChain,
                    }
                  )}
                </span>
              </small>
            )}
          </>
        )}
        {displayTrade && (
          <Button
            btnColor="gradient"
            text={i18(`Trade ${tradeAmount} ${selectedAsset.label} to ARCH`, "trade.feeGrant.tradeToArch", {
              amount: tradeAmount,
              symbol: selectedAsset.label,
            })}
            onClick={() => {
              const { assetList, chainList } = makeLists(chains, assets, assetBalances, [], globalConfig, {});
              const fromAsset = assetList.find(
                tradeToken => tradeToken.value === selectedAsset.label + "_" + chains[chainId].displayName
              );
              const fromChain = chainList.find(
                tradeChain => tradeChain.value === walletChainContext.chainState.chainId
              );
              const toAsset = assetList.find(
                tradeToken =>
                  tradeToken.value ===
                  walletChainContext.chainState.feeCurrencies[0].coinDenom + "_" + chains[chainId].displayName
              );
              const toChain = chainList.find(tradeChain => tradeChain.value === walletChainContext.chainState.chainId);
              const feeGrantAsset = feeGrantOptions.assets.find(
                feeGrantAsset => feeGrantAsset.symbol === selectedAsset.label
              );
              setFeeGrantAssetsToTrade({
                fromAsset,
                fromChain,
                toChain,
                toAsset,
                fromAmount: BigNumber(feeGrantAsset.tradeMinAmount || feeGrantAsset.minAmount)
                  .div(Math.pow(10, selectedAssetState.decimals))
                  .toString(10),
              });
              navigate(`/trade`);
            }}
          />
        )}
      </div>
    </section>
  );
}
