import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { NumericFormat } from "react-number-format";
import { ExternalLink, Settings, X } from "react-feather";
import BigNumber from "bignumber.js";
import { findIndex } from "lodash";
import { fromBech32 } from "@cosmjs/encoding";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";
import { getRouterHops } from "@axvdex/utils/swapScripts/preComputedRouterHops";
import {
  executeAssetAction,
  executeIbcOperatorAction,
  executeIbcTransfer,
  getIbcClientSigner,
  retryWalletWithDispatch,
  updateNativeBalance,
  updateTokenBalance,
  updateUserGlobalSettingsFields,
} from "@axvdex/state/wallet/walletThunks";
import { getFeeGrantOptions } from "@axvdex/api/user";
import { sendToast, setBackgroundTemporaryState, updateAssetBalances } from "@axvdex/state/wallet/walletSlice";
import { useQuery } from "@axvdex/hooks/useQuery";

import estimatedFees, {
  GAS_ESTIMATION_STATIC_MINT_DERIVATIVE,
  GAS_ESTIMATION_STATIC_TRADE_1_HOP_STABLE,
  GAS_ESTIMATION_STATIC_TRADE_1_HOP_STANDARD,
  GAS_ESTIMATION_STATIC_TRADE_1_HOP_HYBRID,
  GAS_ESTIMATION_STATIC_TRADE_ROUTER_OVERHEAD,
} from "@axvdex/utils/estimatedFees";
// import { fromHumanAmountToTokenBalance } from "@axvdex/utils/formatNumber";
import { simulatedTradeConverterToVisualizer } from "@axvdex/utils/getTradeSequence";

import useLanguage from "@axvdex/hooks/useLanguage";
import {
  ISimulationReturn,
  simulatedActionToHumanReadable,
  simulation,
} from "@axvdex/utils/simulationScripts/simulation";
import simulateIbcTransferWithSkipAPI from "@axvdex/utils/simulationScripts/simulateIbcTransferWithSkipAPI";
import { responsiveBreakpoints } from "@axvdex/constants";
import { WHITE_LIST_PERSISTED_STATE_KEYS } from "@axvdex/state/persist";
import TradeVisualiserModal from "../modals/TradeVisualiserModal";
import Button from "../common/Button";
import CustomSelect, { ListItemsProps } from "../form-element/CustomSelect";
import CustomNumericInput from "../form-element/CustomNumericInput";
//import CustomInputButton from "../form-element/CustomInputButton";
import CustomSwitch from "../form-element/CustomSwitch";
import UserBalance from "../user/UserBalance";
//import UserRewardsAmount from "../user/UserRewardsAmount";
import { ReactComponent as IcnTrade } from "../../assets/icons/icn-exchange-vertical.svg";
import { SwapDetailsContainer } from "../trade/ActionContainers/SwapDetailsContainer";
import { SwapSettingsContainer } from "../trade/ActionContainers/SwapSettingsContainer";
import { TransferDetailsContainer } from "../trade/ActionContainers/TransferDetailsContainer";
import { IbcTransferDetailsContainer } from "../trade/ActionContainers/IbcTransferDetailsContainer";
import { CrosschainSwapStatusContainer } from "../trade/StatusContainers/CrosschainSwapStatusContainer";
import ActionsRowGroupButtons, { depositTargetItems } from "../trade/ActionsRowGroupButtons";
import CustomLoader from "../common/CustomLoader";
import { makeLists, TradeInfo } from "./tradeHelpers";
import { useAppDispatch, useAppSelector } from "state";
import {
  selectAssetBalances,
  selectAssets,
  selectContracts,
  selectFavouriteAssets,
  selectGlobalSettings,
  selectPools,
  selectGlobalConfig,
  selectUserLoading,
  selectWalletInfo,
  selectChains,
  selectBackgroundTemporaryState,
} from "state/wallet/walletSelectors";

function TradeForm({
  feeGrantAssetsToTrade,
  setTitle,
  tradeInfo,
  setTradeInfo,
  isTransactionStarts,
  setIsTransactionStarts,
}: any) {
  const highFeeConfirm = 15;

  const { i18 } = useLanguage();

  const TradeFromTokenSlippageList = useMemo(
    () => [
      { id: "2", name: "trade_from_token_slippage", label: "2%" },
      { id: "3", name: "trade_from_token_slippage", label: "3%" },
      { id: "4", name: "trade_from_token_slippage", label: "4%" },
      { id: "custom", name: "trade_from_token_slippage", label: i18("Custom", "trade.form.slippage.custom") },
    ],
    [i18]
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobileBreakpoint = useMediaQuery(responsiveBreakpoints.mobileSm);
  const chainsStored = useAppSelector(selectChains);
  const poolsStored = useAppSelector(selectPools);
  const assetsStored = useAppSelector(selectAssets);
  const walletInfo = useAppSelector(selectWalletInfo);
  const contracts = useAppSelector(selectContracts);
  const assetBalances = useAppSelector(selectAssetBalances);
  const favouriteAssets = useAppSelector(selectFavouriteAssets);
  const isUpdatingUser = useAppSelector(selectUserLoading);
  const globalConfig = useAppSelector(selectGlobalConfig);
  const globalSettings = useAppSelector(selectGlobalSettings);
  const backgroundTemporaryState = useAppSelector(selectBackgroundTemporaryState);

  const [updateBalancesExecuting, setUpdateBalancesExecuting] = useState(false);
  const [walletChainExternalContext, setWalletChainExternalContext] = useState({});
  const [shouldValueBeInUSD, setShouldValueBeInUSD] = useState(false);
  const [simulationValues, setSimulationValues] = useState<ISimulationReturn>(null);
  const [skipAPIError, setSkipAPIError] = useState<any>(null);
  const [exchangeRateSwitchFrom, setExchangeRateSwitchFrom] = useState(true);
  const [marketImpactSwitch, setMarketImpactSwitch] = useState(true);
  const [openSlippage, setOpenSlippage] = useState(false);
  const [slippage, setSlippage] = useState("2");
  const [slippageType, setSlippageType] = useState("hardcoded");
  const [isolatedPoolTrading, setIsolatedPoolTrading] = useState(false);
  const [toAddr, setToAddr] = useState(null);
  const [isLoadingExternalAssetData, setIsLoadingExternalAssetData] = useState(false);

  const handleToggleSlippage = useCallback(() => {
    setOpenSlippage(openSlippage => !openSlippage);
  }, []);

  const [chainOptions, setChainOptions] = useState([]);
  const [assetOptions, setAssetOptions] = useState([]);
  const [fromSelectedChain, setFromSelectedChain] = useState<ListItemsProps>({
    label: "",
    value: "",
    optionPrefix: "",
    optionSuffix: "",
  });
  const [fromSelectedToken, setFromSelectedToken] = useState<ListItemsProps>({
    label: "",
    value: "",
    optionPrefix: "",
    optionSuffix: "",
  });
  const [toSelectedChain, setToSelectedChain] = useState<ListItemsProps>({
    label: "",
    value: "",
    optionPrefix: "",
    optionSuffix: "",
  });
  const [toSelectedToken, setToSelectedToken] = useState<ListItemsProps>({
    label: "",
    value: "",
    optionPrefix: "",
    optionSuffix: "",
  });

  const [fromTokenAmount, setFromTokenAmount] = useState("");
  const [toTokenAmount, setToTokenAmount] = useState("0");
  const [largeFeeComponent, setLargeFeeComponent] = useState({
    visible: false,
    activated: false,
  });

  const query = useQuery();

  // when render this first time, check if there is some temporary state in the background, redirect the user to the link of that state
  useEffect(() => {
    if (
      backgroundTemporaryState &&
      backgroundTemporaryState.crosschainSwap &&
      backgroundTemporaryState.crosschainSwap.length > 0 &&
      backgroundTemporaryState.crosschainSwap[0].fullActionQueryParams
    ) {
      const { from, fromChain, to, toChain, amount } = backgroundTemporaryState.crosschainSwap[0].fullActionQueryParams;
      navigate(`/trade?from=${from}&fromChain=${fromChain}&to=${to}&toChain=${toChain}&amount=${amount}`);
    }
  }, []);

  useEffect(() => {
    // when globalSettings change we update the slippage tolerance defaults with it if configured
    if (globalSettings && globalSettings.slippageTolerance) {
      setSlippage(globalSettings.slippageTolerance.amount);
      setSlippageType(globalSettings.slippageTolerance.type);
    }
    if (globalSettings && globalSettings.tradeIsolatedPoolTrading) {
      setIsolatedPoolTrading(globalSettings.tradeIsolatedPoolTrading);
    }
  }, [globalSettings]);

  // if there is feeGrantAssets coming in the state...
  useEffect(() => {
    if (feeGrantAssetsToTrade) {
      setFromSelectedChain(feeGrantAssetsToTrade.fromChain);
      setFromSelectedToken(feeGrantAssetsToTrade.fromAsset);
      setToSelectedToken(feeGrantAssetsToTrade.toAsset);
      setToSelectedChain(feeGrantAssetsToTrade.toChain);
      setFromTokenAmount(feeGrantAssetsToTrade.fromAmount);
    }
  }, [feeGrantAssetsToTrade]);

  useEffect(() => {
    // generate full lists of possible assets and chains
    const { chainList, assetList } = makeLists(
      chainsStored,
      assetsStored,
      assetBalances,
      favouriteAssets,
      globalConfig,
      walletChainExternalContext
    );
    setAssetOptions(assetList);
    setChainOptions(chainList);

    // check if there is nothing selected, if so auto select first chain, and its native currency on from fields
    // if (
    //   fromSelectedToken.value === "" &&
    //   fromSelectedChain.value === "" &&
    //   chainList.length > 0 &&
    //   chainsStored[chainList[0].value]?.stakeCurrency?.coinMinimalDenom
    // ) {
    //   setFromSelectedChain(chainList[0]);
    //   defaultTokenSelectAfterChainChange(assetList, chainList[0], setFromSelectedToken);
    // }

    // refresh the selected assets with updated data (mainly assetBalances)
    if (fromSelectedToken.value !== "") {
      setFromSelectedToken(assetList.find(asset => asset.value === fromSelectedToken.value));
    }
    if (toSelectedToken.value !== "") {
      setToSelectedToken(assetList.find(asset => asset.value === toSelectedToken.value));
    }
  }, [chainsStored, assetsStored, assetBalances, favouriteAssets, globalConfig]);

  // every time the chain changes, check if balances need to be fetched
  useEffect(() => {
    if (walletInfo.isConnected && fromSelectedChain.value !== "") {
      updateBalances(assetOptions.filter(asset => asset.extraFields.contextChainId === fromSelectedChain.value));
    }
    if (walletInfo.isConnected && toSelectedChain.value !== "") {
      updateBalances(assetOptions.filter(asset => asset.extraFields.contextChainId === toSelectedChain.value));
    }
  }, [walletInfo, assetsStored, fromSelectedChain, toSelectedChain]);

  // initial fill of the form with the query params
  // update stuff when query params change (handles navigate when user is already on the same page with assets selected)
  useEffect(() => {
    if (!isUpdatingUser) {
      const { fromTokenOption, fromChainOption, toTokenOption, toChainOption, value } = getParamsFromQuery();

      // to prevent multiple rerenders, we compare the value that is already selected with the one coming from the query
      // special cases for externalAssets that need to get their client and balance from external chains and the wallet needs to be connected
      if (
        (fromTokenOption && fromTokenOption.value !== fromSelectedToken.value) ||
        (fromTokenOption?.extraFields?.externalAsset && !fromTokenOption?.extraFields?.walletChainExternalContext)
      ) {
        handleFromTokenChange(fromTokenOption, false);
      }

      if (fromChainOption && fromChainOption?.value !== fromSelectedChain?.value) {
        setFromSelectedChain(fromChainOption);
      }
      if (
        toTokenOption &&
        (toTokenOption.value !== toSelectedToken.value ||
          (toTokenOption?.extraFields?.externalAsset && !toTokenOption?.extraFields?.walletChainExternalContext))
      ) {
        handleToTokenChange(toTokenOption);
      }
      if (toChainOption && toChainOption?.value !== toSelectedChain?.value) {
        setToSelectedChain(toChainOption);
      }
      if (value && value !== fromTokenAmount) setFromTokenAmount(value || "");
    }
  }, [query, favouriteAssets, walletInfo.isConnected, chainsStored]);

  useEffect(() => {
    if (fromSelectedToken.label !== "" && toSelectedToken.label !== "" && !isLoadingExternalAssetData) {
      const {
        amount,
        fromAsset,
        fromAssetWalletChainContext,
        amountForSwap,
        toAsset,
        toAssetWalletChainContext,
        slippageTolerance,
      } = getPreFormatedSimulatedDataFromForm();

      try {
        const res = simulation(
          chainsStored,
          poolsStored,
          assetsStored,
          contracts,
          globalConfig.statusCounters[fromAsset.contextChainId]?.estimatedFeesReference,
          getRouterHops(),
          fromAsset,
          fromAssetWalletChainContext,
          amountForSwap.toString(),
          toAsset,
          toAssetWalletChainContext,
          {
            slippageTolerance,
            // if coming from fee grant it should be max of 3 hops
            // if isolated pool trading only 1 hop is possible
            maxHops: feeGrantAssetsToTrade ? 3 : isolatedPoolTrading ? 1 : null,
            toAddress: toAddr,
          }
        );
        const askAmount = BigNumber(res.askAmount).decimalPlaces(6, BigNumber.ROUND_FLOOR).toString(10);
        setSimulationValues(res);
        setTitle(simulatedActionToHumanReadable(res.actionType, i18));
        setToTokenAmount(askAmount || "0");
        setTradeInfo((prevState: TradeInfo) => {
          return {
            ...prevState,
            fromAsset,
            toAsset,
            fromFieldValue: amountForSwap,
            toFieldValue: Number(askAmount || 0),
            fromFieldValueInUSD: shouldValueBeInUSD
              ? amount / prevState.fromAsset.price
              : amount * prevState.fromAsset.price,
            toFieldValueInUSD: (Number(askAmount) || 0) * prevState?.toAsset?.price || 0,
          };
        });
        navigate(
          `/trade?from=${fromAsset.symbol}&fromChain=${fromAsset.contextChainId}&to=${toAsset.symbol}&toChain=${toAsset.contextChainId}`,
          { replace: true, state: { noScrollToTop: true } }
        );
      } catch (err) {
        console.error(err);
        setSimulationValues(null);
        setToTokenAmount("0");
        setTradeInfo((prevState: TradeInfo) => {
          return {
            ...prevState,
            fromAsset,
            toAsset,
            fromFieldValue: amountForSwap,
            toFieldValue: 0,
            fromFieldValueInUSD: shouldValueBeInUSD
              ? amount / prevState.fromAsset.price
              : amount * prevState.fromAsset.price,
            toFieldValueInUSD: 0,
          };
        });
        navigate(
          `/trade?from=${fromAsset.symbol}&fromChain=${fromAsset.contextChainId}&to=${toAsset.symbol}&toChain=${toAsset.contextChainId}&amount=${amount}`,
          { replace: true, state: { noScrollToTop: true } }
        );
      }
    }
  }, [
    fromTokenAmount,
    fromSelectedToken,
    toSelectedToken,
    shouldValueBeInUSD,
    slippage,
    poolsStored,
    isolatedPoolTrading,
    toAddr,
  ]);

  // load from last selection localstorage state after lists are created and only if nothing is selected by other methods
  useEffect(() => {
    if (
      !isUpdatingUser &&
      chainOptions.length > 0 &&
      assetOptions.length > 0 &&
      fromSelectedChain.value === "" &&
      fromSelectedToken.value === "" &&
      toSelectedChain.value === "" &&
      toSelectedToken.value === ""
    ) {
      const { fromTokenOption, fromChainOption, toTokenOption, toChainOption } = getParamsFromQuery();
      if (fromTokenOption || fromChainOption || toTokenOption || toChainOption) return;

      const lastSelectionOnTradePage = JSON.parse(
        localStorage.getItem(WHITE_LIST_PERSISTED_STATE_KEYS.lastSelectionOnTradePage)
      );
      if (lastSelectionOnTradePage) {
        const { fromSelectedChainValue, fromSelectedTokenValue, toSelectedChainValue, toSelectedTokenValue } =
          lastSelectionOnTradePage;
        if (fromSelectedChainValue) {
          const fromSelectedChain = chainOptions.find(chain => chain.value === fromSelectedChainValue);
          fromSelectedChain && setFromSelectedChain(fromSelectedChain);
        }
        if (fromSelectedTokenValue) {
          const fromSelectedToken = assetOptions.find(token => token.value === fromSelectedTokenValue);
          fromSelectedToken && setFromSelectedToken(fromSelectedToken);
        }
        if (toSelectedChainValue) {
          const toSelectedChain = chainOptions.find(chain => chain.value === toSelectedChainValue);
          toSelectedChain && setToSelectedChain(toSelectedChain);
        }
        if (toSelectedTokenValue) {
          const toSelectedToken = assetOptions.find(token => token.value === toSelectedTokenValue);
          toSelectedToken && setToSelectedToken(toSelectedToken);
        }
      }
    }
  }, [chainOptions, assetOptions]);

  // every time any selection changes, we save it to persistent state (localstorage)
  // so we can load it next time user visits the page with empty selections
  useEffect(() => {
    if (!isUpdatingUser && chainOptions.length > 0 && assetOptions.length > 0) {
      localStorage.setItem(
        WHITE_LIST_PERSISTED_STATE_KEYS.lastSelectionOnTradePage,
        JSON.stringify({
          fromSelectedChainValue: fromSelectedChain.value,
          fromSelectedTokenValue: fromSelectedToken.value,
          toSelectedChainValue: toSelectedChain.value,
          toSelectedTokenValue: toSelectedToken.value,
        })
      );
    }
  }, [chainOptions, assetOptions, fromSelectedToken, fromSelectedChain, toSelectedToken, toSelectedChain]);

  const getPreFormatedSimulatedDataFromForm = () => {
    let fromAmount;
    fromAmount = fromTokenAmount.match(/[\d,.]+/);
    if (Array.isArray(fromAmount) && fromAmount.length > 0) {
      fromAmount = fromAmount[0].replace(/,/g, "");
    } else {
      fromAmount = "0";
    }
    if (Number.isNaN(fromAmount) || Number(fromAmount) <= 0) fromAmount = "0";
    const amount = Number(fromAmount) || 0;
    const slippageTolerance = Number(slippage);
    // check if its external Asset, meaning it is coming from external chains, so we have its state on the extraFields of the token
    const fromAsset = fromSelectedToken.extraFields?.externalAsset || assetsStored[fromSelectedToken.id];
    const toAsset = toSelectedToken.extraFields?.externalAsset || assetsStored[toSelectedToken.id];
    const amountForSwap = shouldValueBeInUSD
      ? BigNumber(amount / fromAsset.price)
          .decimalPlaces(fromAsset.decimals)
          .toNumber()
      : BigNumber(amount).decimalPlaces(fromAsset.decimals).toNumber();

    // if fromAsset is coming from external chain, the walletchaincontext should be the external chain context
    const fromAssetWalletChainContext =
      fromSelectedToken.extraFields?.walletChainExternalContext ||
      walletInfo?.connectedChains[assetsStored[fromAsset.id]?.contextChainId];
    const toAssetWalletChainContext =
      toSelectedToken.extraFields?.walletChainExternalContext ||
      walletInfo?.connectedChains[assetsStored[toAsset.id]?.contextChainId];

    return {
      amount,
      fromAsset,
      fromAssetWalletChainContext,
      amountForSwap,
      toAsset,
      toAssetWalletChainContext,
      slippageTolerance,
    };
  };

  const defaultTokenSelectAfterChainChange = (
    assetList: ListItemsProps[],
    selectedChain: ListItemsProps,
    setSelectedToken: (token: ListItemsProps) => void,
    autoSelectTargetItems = true
  ) => {
    // try to find native currently on our list to be selected
    let fromSelectedToken = assetList.find(
      asset => asset.id === chainsStored[selectedChain.value]?.currencies[0]?.coinMinimalDenom
    );
    // if no native currency of that chain is on our list, try to find the first asset of that chain
    if (!fromSelectedToken)
      fromSelectedToken = assetList.filter(asset => asset.extraFields.contextChainId === selectedChain.value)[0];

    if (fromSelectedToken) setSelectedToken(fromSelectedToken);
    if (autoSelectTargetItems && fromSelectedToken.extraFields?.externalAsset && walletInfo.isConnected) {
      // if asset is external, we also set the target asset to force a deposit action
      const { toChain, toToken } = depositTargetItems(chainsStored, fromSelectedToken, chainOptions, assetOptions);
      handleToChainChange(toChain);
      handleToTokenChange(toToken);
    }
  };

  const getParamsFromQuery = () => {
    const from = query.get("from");
    const fromChain =
      query.get("fromChain") ||
      Object.values(chainsStored).find(chain => "archway" === chain.bech32Config.bech32PrefixAccAddr)?.chainId; // if chain is not provided, default to archway
    const to = query.get("to");
    const toChain =
      query.get("toChain") ||
      Object.values(chainsStored).find(chain => "archway" === chain.bech32Config.bech32PrefixAccAddr)?.chainId; // if chain is not provided, default to archway
    const value = query.get("amount");

    let fromTokenOption = null;
    let fromChainOption = null;
    let toTokenOption = null;
    let toChainOption = null;

    if (from && fromChain) {
      fromChainOption = chainOptions?.find(chain => chain.value === fromChain);
      fromTokenOption = assetOptions?.find(token => token.value === from + "_" + chainsStored[fromChain].displayName);
    }
    if (to && toChain) {
      toTokenOption = assetOptions?.find(token => token.value === to + "_" + chainsStored[toChain].displayName);
      toChainOption = chainOptions?.find(chain => chain.value === toChain);
    }
    return { fromTokenOption, fromChainOption, toTokenOption, toChainOption, value };
  };

  const getExternalAssetData = async (selectedOption: ListItemsProps) => {
    setIsLoadingExternalAssetData(true);
    const optionUpdated = { ...selectedOption };
    const { client, balance, acc_address, isSecretNetworkViewingKeyError } = await getIbcClientSigner(
      chainsStored[selectedOption.extraFields?.externalAsset.contextChainId],
      selectedOption.extraFields?.externalAsset.denom
    );

    await dispatch(updateAssetBalances({ [selectedOption.extraFields?.externalAsset.denom]: balance }));

    optionUpdated.optionSuffix =
      BigNumber(balance).decimalPlaces(2).toString() +
      " ($" +
      BigNumber(balance)
        .times(optionUpdated.extraFields.externalAsset.price)
        .div(BigNumber(Math.pow(10, optionUpdated.extraFields.externalAsset.decimals)))
        .decimalPlaces(2)
        .toString(10) +
      ")";
    optionUpdated.extraFields = {
      ...optionUpdated.extraFields,
      assetBalance: BigNumber(balance).div(BigNumber(Math.pow(10, optionUpdated.extraFields.externalAsset.decimals))),
      assetBalanceUSD: BigNumber(balance)
        .times(optionUpdated.extraFields.externalAsset.price)
        .div(BigNumber(Math.pow(10, optionUpdated.extraFields.externalAsset.decimals)))
        .toString(10),
      isSecretNetworkViewingKeyError,
      walletChainExternalContext: {
        signingClient: client,
        address: acc_address,
        chainState: chainsStored[selectedOption.extraFields?.externalAsset.contextChainId],
      },
    };

    // we are modifying this asset options, so it is persistent in the state when dropdown is updated, we need to save it to another state variable and feed it to the dropdown maker function
    setWalletChainExternalContext(prevState => {
      return {
        ...prevState,
        [selectedOption.value]: optionUpdated.extraFields.walletChainExternalContext,
      };
    });
    setIsLoadingExternalAssetData(false);
    return optionUpdated;
  };

  const handleFromChainChange = async (selectedOption: ListItemsProps) => {
    setFromSelectedChain(selectedOption);
    updateBalances(assetOptions.filter(item => item.extraFields.contextChainId === selectedOption.value));
    defaultTokenSelectAfterChainChange(assetOptions, selectedOption, setFromSelectedToken);
    setSkipAPIError(null);
  };

  const handleFromTokenChange = async (selectedOption: ListItemsProps, autoSelectTargetItems = true) => {
    const option = { ...selectedOption };
    if (selectedOption.extraFields?.externalAsset && walletInfo.isConnected) {
      const updatedOption = await getExternalAssetData(selectedOption);
      setFromSelectedToken(updatedOption);
      // if asset is external, we also set the target asset to force a deposit action
      if (autoSelectTargetItems) {
        const { toChain, toToken } = depositTargetItems(chainsStored, updatedOption, chainOptions, assetOptions);
        handleToChainChange(toChain);
        handleToTokenChange(toToken);
      }
    } else {
      setFromSelectedToken(option);
      updateBalances([option]);
    }
    setSkipAPIError(null);
  };

  const handleToChainChange = async (selectedOption: ListItemsProps) => {
    setToSelectedChain(selectedOption);
    updateBalances(assetOptions.filter(item => item.extraFields.contextChainId === selectedOption.value));
    defaultTokenSelectAfterChainChange(assetOptions, selectedOption, setToSelectedToken, false);
    setSkipAPIError(null);
  };

  const handleToTokenChange = async (selectedOption: ListItemsProps) => {
    const option = { ...selectedOption };
    if (selectedOption.extraFields?.externalAsset && walletInfo.isConnected) {
      const updatedOption = await getExternalAssetData(selectedOption);
      setToSelectedToken(updatedOption);
    } else {
      setToSelectedToken(option);
      updateBalances([option]);
    }
    setSkipAPIError(null);
  };

  const updateBalances = async (selectedOptions: ListItemsProps[]) => {
    if (updateBalancesExecuting) return;
    setUpdateBalancesExecuting(true);
    const promises = [];
    for (const selectedOption of selectedOptions) {
      const asset = assetsStored[selectedOption.id];
      if (!asset || assetBalances[asset.id]) continue;
      const walletChainContext = walletInfo?.connectedChains[asset.contextChainId];
      if (!walletChainContext) continue;
      if (asset.isNative) {
        promises.push(
          dispatch(
            updateNativeBalance({
              client: walletChainContext.signingClient,
              userAddress: walletChainContext.address,
              denom: asset.denom,
            })
          )
        );
      } else {
        promises.push(
          dispatch(
            updateTokenBalance({
              client: walletChainContext.signingClient,
              tokenAddress: asset.address,
              userAddress: walletChainContext.address,
            })
          )
        );
      }
    }

    await Promise.all(promises);
    setUpdateBalancesExecuting(false);
  };

  const handleFromTokenAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    const amountForSwap = e.target.value;
    setFromTokenAmount(amountForSwap);
    setLargeFeeComponent({ visible: false, activated: false });
    // Changing the token should reset the temp state if it exists
    dispatch(
      setBackgroundTemporaryState({
        type: "crosschainSwap",
        data: null,
      })
    );
  };

  const reconnectWallet = async () => {
    const asset =
      submitButtonText().reconnectWalletAsset ||
      fromSelectedToken.extraFields?.externalAsset ||
      assetsStored[fromSelectedToken.id];
    setIsTransactionStarts(true);
    await dispatch(
      retryWalletWithDispatch({
        chainToConnect: chainsStored[asset.contextChainId],
      })
    );
    setIsTransactionStarts(false);
  };

  const handleTradeSubmit = async () => {
    dispatch(
      setBackgroundTemporaryState({
        type: "crosschainSwap",
        data: null,
      })
    );

    // when isolated trade is enabled and there is no route, clicking on the button would disable this option
    if ((simulationValues?.error || !tradeInfo.toFieldValue) && isolatedPoolTrading) {
      setIsolatedPoolTrading(false);
      dispatch(
        updateUserGlobalSettingsFields({
          tradeIsolatedPoolTrading: false,
        })
      );
      return;
    }

    if (!largeFeeComponent.visible && parseFloat(simulationValues?.feePer) >= highFeeConfirm) {
      setLargeFeeComponent({ visible: true, activated: false });
      return;
    }
    if (
      BigNumber(tradeInfo.fromFieldValue).gt(assetBalances[tradeInfo.fromAsset.address || tradeInfo.fromAsset.denom])
    ) {
      // snackbar.error(`You do not have enough ${tradeInfo.fromAsset.label || tradeInfo.fromAsset.symbol}`);
      return;
    }
    setIsTransactionStarts(true);
    try {
      // console.log({
      //   walletAddress: walletInfo.walletAddress,
      //   simulationValues,
      //   assets: assetsStored,
      //   contracts,
      //   fromAsset: tradeInfo.fromAsset,
      //   toAsset: tradeInfo.toAsset,
      // });

      let granterAddress = undefined;
      const fromTokenChainContext = walletInfo.connectedChains[tradeInfo.fromAsset.contextChainId];
      if (
        fromTokenChainContext &&
        (0 === fromTokenChainContext.connectSequence || null === fromTokenChainContext.connectSequence) &&
        "0" === assetBalances[fromTokenChainContext.chainState.feeCurrencies[0].coinMinimalDenom] &&
        feeGrantAssetsToTrade
      ) {
        const options = await getFeeGrantOptions(tradeInfo.fromAsset.contextChainId);
        if (0 === options.data.feeGrants.feeGranters.length) {
          dispatch(
            sendToast({
              type: "tx-fail",
              info: { msg: "All Fee granters are busy, try again later...", toastID: "" + new Date().getTime() },
            })
          );
          setIsTransactionStarts(false);
          return;
        }
        granterAddress =
          options.data.feeGrants.feeGranters[Math.floor(Math.random() * options.data.feeGrants.feeGranters.length)]
            .granterAddress;
      }

      console.log(simulationValues);
      // dispatch to generic function!
      if ("ibcTransfer" === simulationValues.actionType) {
        // check if the action is to try skip api to get the route
        if (simulationValues.error && !skipAPIError) {
          // eslint-disable-next-line
          const { fromAsset, fromAssetWalletChainContext, amountForSwap, toAsset, toAssetWalletChainContext } =
            getPreFormatedSimulatedDataFromForm();
          try {
            await simulateIbcTransferWithSkipAPI(dispatch, chainsStored, fromAsset, amountForSwap.toString(), toAsset);
          } catch (e) {
            console.error(e);
            if (!e.message.includes("Request rejected")) setSkipAPIError(e);
          }
          setIsTransactionStarts(false);
        } else if (simulationValues.tx.ibcTransfer) {
          await dispatch(
            executeIbcTransfer({
              ...simulationValues.tx.ibcTransfer,
              i18,
            })
          );
        } else {
          await dispatch(
            executeIbcOperatorAction({
              tradeSequenceRaw: simulatedTradeConverterToVisualizer(simulationValues, poolsStored),
              ...simulationValues.tx.ibcOperatorAction,
              i18,
            })
          );
        }
      } else if ("crosschainSwap" === simulationValues.actionType) {
        await dispatch(
          executeIbcOperatorAction({
            tradeSequenceRaw: simulatedTradeConverterToVisualizer(simulationValues, poolsStored),
            ...simulationValues.tx.ibcOperatorAction,
            i18,
          })
        );
      } else {
        await dispatch(
          executeAssetAction({
            chainId: tradeInfo.fromAsset.contextChainId,
            simulationValues,
            assets: assetsStored,
            pools: poolsStored,
            contracts,
            fromAsset: tradeInfo.fromAsset,
            toAsset: tradeInfo.toAsset,
            granterAddress,
            walletSignerType: fromTokenChainContext?.signerType,
            // use customSigner if the "from" token is on external chain
            customSigner: fromSelectedToken.extraFields?.walletChainExternalContext,
            i18,
          })
        );
      }
    } catch (err) {
      console.log(err);
      console.error(i18("Failed transaction. Please try later", "trade.form.failedTxn"), err);
    }
    setIsTransactionStarts(false);
    setLargeFeeComponent({ visible: false, activated: false });
  };

  const handleMaxButton = async () => {
    if (fromSelectedToken?.value) {
      const assetPrice =
        assetsStored[fromSelectedToken.id]?.price || fromSelectedToken.extraFields?.externalAsset?.price;
      const tokenAmount = BigNumber(fromSelectedToken.extraFields.assetBalance || "0").decimalPlaces(
        6,
        BigNumber.ROUND_FLOOR
      );
      setFromTokenAmount((shouldValueBeInUSD ? tokenAmount.times(assetPrice).toString() : tokenAmount).toString());
    }
  };

  const handleAssetValueSwitch = shouldValueBeInUSD => {
    setShouldValueBeInUSD(shouldValueBeInUSD);
    const assetPrice = assetsStored[fromSelectedToken.id]?.price || fromSelectedToken.extraFields?.externalAsset?.price;
    const fromTokenValue = Number(fromTokenAmount.replace(/[^0-9.]/g, "")) || 0;
    setFromTokenAmount((shouldValueBeInUSD ? fromTokenValue * assetPrice : fromTokenValue / assetPrice).toString());
  };

  const switchTokens = () => {
    setFromSelectedToken(toSelectedToken);
    setToSelectedToken(fromSelectedToken);
    setToTokenAmount(fromTokenAmount);
    setFromTokenAmount(toTokenAmount);
    setShouldValueBeInUSD(false);
    setExchangeRateSwitchFrom(true);
    setSkipAPIError(null);
  };

  const submitButtonText = () => {
    const disabledConfig = { disabled: true, extraClass: "" };
    if (!walletInfo.isConnected) {
      return {
        buttonText: i18("Connect wallet", "trade.submitBtn.text.connectWallet"),
        ...disabledConfig,
      };
    }

    const fromAsset = fromSelectedToken.extraFields?.externalAsset || assetsStored[fromSelectedToken.id];
    if (
      !fromSelectedToken.extraFields?.externalAsset &&
      !walletInfo.connectedChains[fromAsset?.contextChainId]?.signingStargateClient
    ) {
      const chain = chainsStored[fromAsset?.contextChainId]?.displayName;
      return {
        buttonText: i18(`${chain} not connected. Try to reconnect...`, "poolCardTable.submitBtn.text.notConnected", {
          chain,
        }),
        disabled: false,
        reconnectWalletAction: true,
        reconnectWalletAsset: fromAsset,
        extraClass: "",
      };
    }

    const toAsset = toSelectedToken.extraFields?.externalAsset || assetsStored[toSelectedToken.id];
    if (
      !toSelectedToken.extraFields?.externalAsset &&
      !walletInfo.connectedChains[toAsset?.contextChainId]?.signingStargateClient
    ) {
      const chain = chainsStored[toAsset?.contextChainId]?.displayName;
      return {
        buttonText: i18(`${chain} not connected. Try to reconnect...`, "poolCardTable.submitBtn.text.notConnected", {
          chain,
        }),
        disabled: false,
        reconnectWalletAction: true,
        reconnectWalletAsset: toAsset,
        extraClass: "",
      };
    }

    if (
      "halted" === chainsStored[fromSelectedChain.value].status ||
      "halted" === chainsStored[toSelectedChain.value].status
    ) {
      const haltedChainName =
        "halted" === chainsStored[fromSelectedChain.value].status
          ? chainsStored[fromSelectedChain.value].displayName
          : chainsStored[toSelectedChain.value].displayName;
      return {
        buttonText: i18(`${haltedChainName} chain is halted`, "trade.submitBtn.text.halted", { haltedChainName }),
        disabled: true,
        extraClass: "",
      };
    }

    if (!tradeInfo.fromFieldValue) {
      return {
        buttonText: i18("Enter Amount", "trade.submitBtn.text.enterAmount"),
        ...disabledConfig,
      };
    }

    const action = simulatedActionToHumanReadable(simulationValues?.actionType, i18);

    if ("transfer" === simulationValues?.actionType || "ibcTransfer" === simulationValues?.actionType) {
      let isInvalid = false;
      if (null === toAddr || "" === toAddr) {
        isInvalid = true;
      }
      try {
        const res = fromBech32(toAddr);
        if (res.prefix !== chainsStored[toSelectedToken.extraFields.contextChainId].bech32Config.bech32PrefixAccAddr)
          throw new Error("Invalid prefix");
      } catch (e) {
        isInvalid = true;
      }

      if (isInvalid) {
        return {
          buttonText: i18(action + ": Enter valid address", "trade.submitBtn.text.enterAddress"),
          ...disabledConfig,
        };
      }

      if (
        "transfer" === simulationValues?.actionType &&
        (toAddr === toSelectedToken.extraFields?.walletChainExternalContext?.address ||
          toAddr === walletInfo.connectedChains[toSelectedToken.extraFields.contextChainId]?.address)
      ) {
        return {
          buttonText: i18(action + ": Enter another address", "trade.submitBtn.text.enterAnotherAddress"),
          ...disabledConfig,
        };
      }
    }

    if (
      tradeInfo.fromAsset &&
      BigNumber(tradeInfo.fromFieldValue)
        .times(Math.pow(10, tradeInfo.fromAsset.decimals))
        .gt(BigNumber(assetBalances[tradeInfo.fromAsset.id]))
    ) {
      return {
        buttonText: i18(
          action + `: Not enough ${tradeInfo.fromAsset.symbol} balance`,
          "trade.submitBtn.text.notEnoughBalance",
          {
            symbol: tradeInfo.fromAsset.symbol,
          }
        ),
        ...disabledConfig,
      };
    }

    if (isTransactionStarts) {
      return {
        buttonText: i18(action + ": Executing...", "trade.submitBtn.text.executing"),
        ...disabledConfig,
      };
    }

    // check if with ibcTransfer action and error, try using skip api to get the route
    if ("ibcTransfer" === simulationValues?.actionType && simulationValues?.error && !skipAPIError) {
      return {
        buttonText: action + ": Attempt to use Skip API",
        extraClass: "",
        disabled: false,
      };
    }

    if ("ibcTransfer" === simulationValues?.actionType && simulationValues?.error && skipAPIError) {
      return {
        buttonText: action + ": Unsupported IBC path",
        ...disabledConfig,
      };
    }

    if ((simulationValues?.error || !tradeInfo.toFieldValue) && isolatedPoolTrading) {
      return {
        disabled: false,
        buttonText: i18(action + ": Disable isolated pool trading", "trade.submitBtn.text.disableIsolatedPoolTrading"),
        extraClass: "",
      };
    }

    if (simulationValues?.error || !tradeInfo.toFieldValue) {
      return {
        buttonText: simulationValues?.error?.buttonText
          ? action + ": " + simulationValues?.error?.buttonText
          : i18(action + ": No route for this action", "trade.submitBtn.text.noRoute"),
        ...disabledConfig,
      };
    }

    if (Number(simulationValues?.marketImpactPer) > 100) {
      return {
        ...disabledConfig,
        buttonText: i18(action + ": Market impact > 100%", "trade.submitBtn.text.marketImpact"),
        disabled: "MAINNET" === process.env.REACT_APP_MODE,
      };
    }

    if (Number(simulationValues?.priceImpactPer) > 100) {
      return {
        buttonText: i18(action + ": Price impact > 100%", "trade.submitBtn.text.priceImpact"),
        ...disabledConfig,
      };
    }

    // DISABLED THIS CHECK BECAUSE OF NEUTRON HAVING MULTIPLE GAS TOKENS
    // // check if user has sufficient gas fees to cover this trade
    // if (
    //   simulationValues.actionType === "swap" &&
    //   globalConfig.statusCounters[fromSelectedToken.extraFields.contextChainId]
    // ) {
    //   const calcEstimatedFees = estimatedFees(
    //     simulationValues?.route?.length === 1 && simulationValues?.route[0]?.derivativeOperation === "mint"
    //       ? GAS_ESTIMATION_STATIC_MINT_DERIVATIVE
    //       : simulationValues?.route?.reduce((acc, obj) => {
    //           if (poolsStored[obj.p].type === "stable") {
    //             return acc + GAS_ESTIMATION_STATIC_TRADE_1_HOP_STABLE;
    //           }
    //           if (poolsStored[obj.p].type === "standard") {
    //             return acc + GAS_ESTIMATION_STATIC_TRADE_1_HOP_STANDARD;
    //           }
    //           if (poolsStored[obj.p].type === "hybrid") {
    //             return acc + GAS_ESTIMATION_STATIC_TRADE_1_HOP_HYBRID;
    //           }
    //           return acc + 0;
    //         }, 0) +
    //           (simulationValues?.route?.length > 1
    //             ? GAS_ESTIMATION_STATIC_TRADE_ROUTER_OVERHEAD * simulationValues?.route.length
    //             : 0),
    //     globalConfig.statusCounters[fromSelectedToken.extraFields.contextChainId].estimatedFeesReference
    //   );
    //   const feeDenom =
    //     globalConfig.statusCounters[fromSelectedToken.extraFields.contextChainId].estimatedFeesReference.estimatedFee[0]
    //       .denom;
    //   if (
    //     !feeGrantAssetsToTrade &&
    //     assetBalances[feeDenom] &&
    //     BigNumber(assetBalances[feeDenom]).lt(calcEstimatedFees)
    //   ) {
    //     return {
    //       buttonText: i18(
    //         action + `: Wallet needs ${assetsStored[feeDenom].symbol} for ${action}`,
    //         "trade.submitBtn.text.walletNoFunds",
    //         { action, symbol: assetsStored[feeDenom].symbol }
    //       ),
    //       ...disabledConfig,
    //     };
    //   }

    //   // if the input is the source denom we need to check if it will consume more fees
    //   // current balance - input amount < estimated fees
    //   try {
    //     let fromAmount;
    //     fromAmount = fromTokenAmount.match(/[\d.]+/);
    //     if (Array.isArray(fromAmount) && fromAmount.length > 0) {
    //       fromAmount = fromAmount[0].replace(/,/g, "");
    //     } else {
    //       fromAmount = "0";
    //     }
    //     if (Number.isNaN(fromAmount) || Number(fromAmount) <= 0) fromAmount = "0";
    //     if (
    //       !feeGrantAssetsToTrade &&
    //       tradeInfo.fromAsset.id === feeDenom &&
    //       assetBalances[feeDenom] &&
    //       BigNumber(assetBalances[feeDenom])
    //         .minus(fromHumanAmountToTokenBalance(fromAmount.replaceAll(",", ""), assetsStored[feeDenom].decimals))
    //         .lt(calcEstimatedFees)
    //     ) {
    //       return {
    //         buttonText: i18(
    //           action + `: Wallet needs ${assetsStored[feeDenom].symbol} for ${action}`,
    //           "trade.submitBtn.text.walletNoFunds",
    //           { action, symbol: assetsStored[feeDenom].symbol }
    //         ),
    //         ...disabledConfig,
    //       };
    //     }
    //   } catch (e) {
    //     console.error("Failed to calculate fees", e);
    //     /* empty */
    //   }
    // }

    if (largeFeeComponent.visible) {
      if (!largeFeeComponent.activated) {
        return {
          buttonText: i18(action + `: Confirm HIGH FEE ${action}`, "trade.submitBtn.text.highFeeConfirm", { action }),
          ...disabledConfig,
        };
      }

      return {
        buttonText: i18(action + `: Yes, make this HIGH FEE ${action}`, "trade.submitBtn.text.highFeeAgree", {
          action,
        }),
        extraClass: "highFeeButton",
        disabled: false,
      };
    }

    return {
      buttonText: action,
      extraClass: "",
      disabled: false,
    };
  };

  const chainExtraButton = (assetSelected: ListItemsProps) => {
    const chain = chainsStored[assetSelected?.extraFields?.contextChainId];
    if (chain?.extraBridgeURL) {
      return (
        <ExternalLink
          style={{ width: "1.5em", height: "1.5em", cursor: "pointer" }}
          onClick={() => window.open(chain.extraBridgeURL, "_blank").focus()}
        />
      );
    }
    return null;
  };

  const showCrosschainSwapStatusContainer = () => {
    if (
      "crosschainSwap" === simulationValues?.actionType &&
      backgroundTemporaryState &&
      backgroundTemporaryState.crosschainSwap &&
      backgroundTemporaryState.crosschainSwap.length > 0
    ) {
      const { fromTokenOption, toTokenOption } = getParamsFromQuery();

      // check if url matches what is in the background state
      if (
        assetsStored[fromTokenOption.id].symbol ===
          backgroundTemporaryState.crosschainSwap[0].fullActionQueryParams.from &&
        assetsStored[toTokenOption.id].symbol === backgroundTemporaryState.crosschainSwap[0].fullActionQueryParams.to
      ) {
        return <CrosschainSwapStatusContainer />;
      }
    }

    return false;
  };

  const buttonConfig = submitButtonText();
  return (
    <div className="tradeForm">
      <fieldset className="tradeFromTokenFieldset withGradientBorder">
        <legend className="visuallyHidden">{i18("Trade from token", "trade.form.fromToken.section.legend")}</legend>

        <fieldset className="selectTokenSubFieldset tradeFromToken">
          <legend className="visuallyHidden">{i18("Select token", "trade.form.fromToken.legend")}</legend>

          <div className="flexbox">
            <CustomSelect
              name="trade_from_chain"
              extraClassName="tradeFromTokenFormGroupSelect"
              labelText={i18("From", "trade.form.fromToken.select.label")}
              hiddenLabel={true}
              items={chainOptions}
              placeholder={i18("Select chain", "trade.form.fromChain.select.placeholder")}
              value={chainOptions[findIndex(chainOptions, item => item.value === fromSelectedChain.value)]}
              onChange={(val: ListItemsProps) => {
                handleFromChainChange(val);
                // onChange(val);
                // Changing the token should reset the temp state if it exists
                dispatch(
                  setBackgroundTemporaryState({
                    type: "crosschainSwap",
                    data: null,
                  })
                );
              }}
              isDisabled={isTransactionStarts}
            />

            <CustomSelect
              name="trade_from_token"
              extraClassName="tradeFromTokenFormGroupSelect"
              labelText={i18("From", "trade.form.fromToken.select.label")}
              hiddenLabel={true}
              items={assetOptions.filter(item => item.extraFields.contextChainId === fromSelectedChain.value)}
              placeholder={i18("Select token", "trade.form.fromToken.select.placeholder")}
              value={assetOptions[findIndex(assetOptions, item => item.value === fromSelectedToken.value)]}
              onChange={(val: ListItemsProps) => {
                handleFromTokenChange(val);
                // Changing the token should reset the temp state if it exists
                dispatch(
                  setBackgroundTemporaryState({
                    type: "crosschainSwap",
                    data: null,
                  })
                );
              }}
              extraButton={chainExtraButton(
                assetOptions[findIndex(assetOptions, item => item.value === fromSelectedToken.value)]
              )}
              isDisabled={isTransactionStarts}
            />
            {!isMobileBreakpoint && (
              <CustomNumericInput
                disabled={isTransactionStarts}
                name="trade_from_usd"
                labelText={i18("Choose amount", "trade.form.fromToken.input.label")}
                value={fromTokenAmount}
                onChange={handleFromTokenAmountChange}
                hiddenLabel={true}
                placeholder={"0"}
                decimalScale={6}
                suffix={` ${shouldValueBeInUSD ? i18("USD", "trade.form.amountInUSD") : fromSelectedToken.label}`}
              />
            )}
          </div>

          {isMobileBreakpoint && (
            <div className="flexbox">
              <CustomNumericInput
                disabled={isTransactionStarts}
                name="trade_from_usd"
                labelText={i18("Choose amount", "trade.form.fromToken.input.label")}
                value={fromTokenAmount}
                onChange={handleFromTokenAmountChange}
                hiddenLabel={true}
                placeholder={"0"}
                decimalScale={6}
                suffix={` ${shouldValueBeInUSD ? i18("USD", "trade.form.amountInUSD") : fromSelectedToken.label}`}
              />
            </div>
          )}

          <div className="helpText selectTokenSubFieldsetHelpText">
            {chainOptions.length > 0 &&
              (isLoadingExternalAssetData ? (
                <div style={{ marginLeft: "2.5em" }}>
                  <CustomLoader size="xs" />
                </div>
              ) : (
                <ActionsRowGroupButtons
                  chainOptions={chainOptions}
                  assetOptions={assetOptions}
                  fromSelectedChain={fromSelectedChain}
                  setFromSelectedChain={setFromSelectedChain}
                  fromSelectedToken={fromSelectedToken}
                  setFromSelectedToken={setFromSelectedToken}
                  toSelectedChain={toSelectedChain}
                  setToSelectedChain={setToSelectedChain}
                  toSelectedToken={toSelectedToken}
                  setToSelectedToken={setToSelectedToken}
                />
              ))}
            <Button btnColor="gradientText" onClick={() => handleAssetValueSwitch(!shouldValueBeInUSD)} title="">
              <span className="textGradient">
                <NumericFormat
                  className="balanceUsdValue"
                  displayType="text"
                  thousandSeparator=","
                  decimalSeparator="."
                  decimalScale={6}
                  value={
                    (!shouldValueBeInUSD
                      ? BigNumber(simulationValues?.offerAmountInUSD || 0)
                          .decimalPlaces(6, BigNumber.ROUND_FLOOR)
                          .toNumber()
                      : tradeInfo.fromFieldValue) || 0
                  }
                  prefix={i18("Modify as ", "trade.form.fromToken.usd.prefix")}
                  suffix={` ${
                    !shouldValueBeInUSD ? i18("USD", "trade.form.amountInUSD") : fromSelectedToken.value.split("_")[0]
                  }`}
                />
              </span>
            </Button>
          </div>
        </fieldset>

        <div className="selectTokenBalanceAmount">
          <UserBalance
            balanceValue={fromSelectedToken?.extraFields?.assetBalance || 0}
            balanceUsdValue={fromSelectedToken?.extraFields?.assetBalanceUSD || 0}
            invalidAmount={fromSelectedToken?.extraFields?.isSecretNetworkViewingKeyError}
            secretViewingKey={
              fromSelectedToken &&
              fromSelectedToken.extraFields?.externalAsset &&
              fromSelectedToken.extraFields?.externalAsset.cw20_ics20 && // if cw20 on origin chain and secretViewingKeyError => secret network viewing keys special case
              fromSelectedToken?.extraFields?.isSecretNetworkViewingKeyError
                ? {
                    restURL: chainsStored[fromSelectedToken.extraFields?.externalAsset.contextChainId].rest,
                    chainId: fromSelectedToken.extraFields?.externalAsset.contextChainId,
                    sendingAddr: fromSelectedToken.extraFields?.walletChainExternalContext?.address,
                    contract_address: fromSelectedToken.extraFields?.externalAsset.cw20_ics20.cw20Address,
                    refreshBalance: () => handleFromTokenChange(fromSelectedToken),
                  }
                : null
            }
          />

          <div className="buttonContainer">
            <Button
              btnColor="dark-medium"
              text={i18("Max", "trade.form.fromToken.maxBtn.text")}
              title={i18("Select Max Value", "trade.form.fromToken.maxBtn.title")}
              onClick={handleMaxButton}
            />

            {("swap" === simulationValues?.actionType || "crosschainSwap" === simulationValues?.actionType) && (
              <Button
                extraClassName={clsx("btnTradeOpenSlippage", openSlippage && "isOpen")}
                btnColor="dark-medium"
                btnVariant="icon"
                title={i18("Set slippage tolerance", "trade.form.slippageBtn.title")}
                icon={openSlippage ? <X /> : <Settings />}
                onClick={handleToggleSlippage}
              />
            )}
          </div>
        </div>

        {("swap" === simulationValues?.actionType || "crosschainSwap" === simulationValues?.actionType) && (
          <SwapSettingsContainer
            actionType={simulationValues.actionType}
            slippage={slippage}
            slippageType={slippageType}
            setSlippage={setSlippage}
            setSlippageType={setSlippageType}
            i18={i18}
            openSlippage={openSlippage}
            setOpenSlippage={setOpenSlippage}
            TradeFromTokenSlippageList={TradeFromTokenSlippageList}
            isolatedPoolTrading={isolatedPoolTrading}
            setIsolatedPoolTrading={setIsolatedPoolTrading}
            dispatch={dispatch}
          />
        )}

        <Button
          extraClassName="switchIcon"
          btnColor="dark-medium"
          btnVariant="icon"
          title={i18("Switch trade tokens", "trade.form.switchBtn.title")}
          icon={<IcnTrade />}
          onClick={switchTokens}
          disabled={isTransactionStarts}
        />
      </fieldset>

      <fieldset className="withGradientBorder">
        <legend className="visuallyHidden">{i18("Trade to token", "trade.form.toToken.section.legend")}</legend>

        <div className="selectTokenSubFieldset tradeToToken">
          <div className="flexbox">
            <CustomSelect
              name="trade_to_chain"
              extraClassName="tradeToTokenFormGroupSelect"
              labelText={i18("From", "trade.form.toToken.select.label")}
              hiddenLabel={true}
              items={chainOptions}
              placeholder={i18("Select chain", "trade.form.toChain.select.placeholder")}
              value={chainOptions[findIndex(chainOptions, item => item.value === toSelectedChain.value)]}
              onChange={(val: ListItemsProps) => {
                handleToChainChange(val);
                // Changing the token should reset the temp state if it exists
                dispatch(
                  setBackgroundTemporaryState({
                    type: "crosschainSwap",
                    data: null,
                  })
                );
              }}
              isDisabled={isTransactionStarts}
            />

            <CustomSelect
              name="trade_to_token"
              isDisabled={isTransactionStarts}
              extraClassName="tradeToTokenFormGroupSelect"
              labelText={i18("To", "trade.form.toToken.select.label")}
              hiddenLabel={true}
              placeholder={i18("Select token", "trade.form.toToken.select.placeholder")}
              items={assetOptions.filter(item => item.extraFields.contextChainId === toSelectedChain.value)}
              value={assetOptions[findIndex(assetOptions, item => item.value === toSelectedToken.value)]}
              onChange={(val: ListItemsProps) => {
                handleToTokenChange(val);
                // Changing the token should reset the temp state if it exists
                dispatch(
                  setBackgroundTemporaryState({
                    type: "crosschainSwap",
                    data: null,
                  })
                );
              }}
              extraButton={chainExtraButton(
                assetOptions[findIndex(assetOptions, item => item.value === toSelectedToken.value)]
              )}
            />

            {!isMobileBreakpoint && (
              <CustomNumericInput
                extraClassName="tradeToTokenUsdFormGroup gradientText"
                name="trade_to_usd"
                labelText={i18("USD trade amount", "trade.form.toToken.input.label")}
                hiddenLabel={true}
                placeholder={"0"}
                value={
                  shouldValueBeInUSD && simulationValues
                    ? BigNumber(simulationValues.askAmountInUSD).decimalPlaces(6, BigNumber.ROUND_FLOOR).toNumber()
                    : toTokenAmount
                }
                //decimalScale={6}
                suffix={shouldValueBeInUSD && simulationValues ? " " + i18("USD", "trade.form.amountInUSD") : ""}
                disabled={true}
              />
            )}
          </div>
          {isMobileBreakpoint && (
            <div className="flexbox">
              <CustomNumericInput
                style={{
                  minWidth: "100%",
                }}
                extraClassName="tradeToTokenUsdFormGroup gradientText"
                name="trade_to_usd"
                labelText={i18("USD trade amount", "trade.form.toToken.input.label")}
                hiddenLabel={true}
                placeholder={"0"}
                value={
                  shouldValueBeInUSD && simulationValues
                    ? BigNumber(simulationValues.askAmountInUSD).decimalPlaces(6, BigNumber.ROUND_FLOOR).toNumber()
                    : toTokenAmount
                }
                //decimalScale={6}
                suffix={shouldValueBeInUSD && simulationValues ? " " + i18("USD", "trade.form.amountInUSD") : ""}
                disabled={true}
              />
            </div>
          )}
        </div>

        <div className="flexbox visualiser">
          <UserBalance
            balanceValue={toSelectedToken?.extraFields?.assetBalance || 0}
            balanceUsdValue={toSelectedToken?.extraFields?.assetBalanceUSD || 0}
            invalidAmount={toSelectedToken?.extraFields?.isSecretNetworkViewingKeyError}
            secretViewingKey={
              toSelectedToken &&
              toSelectedToken.extraFields?.externalAsset &&
              toSelectedToken.extraFields?.externalAsset.cw20_ics20 && // if cw20 on origin chain and secretViewingKeyError => secret network viewing keys special case
              toSelectedToken?.extraFields?.isSecretNetworkViewingKeyError
                ? {
                    restURL: chainsStored[toSelectedToken.extraFields?.externalAsset.contextChainId].rest,
                    chainId: toSelectedToken.extraFields?.externalAsset.contextChainId,
                    sendingAddr: toSelectedToken.extraFields?.walletChainExternalContext?.address,
                    contract_address: toSelectedToken.extraFields?.externalAsset.cw20_ics20.cw20Address,
                    refreshBalance: () => handleFromTokenChange(toSelectedToken),
                  }
                : null
            }
          />

          {simulationValues &&
            !simulationValues.error &&
            ("swap" === simulationValues.actionType || "crosschainSwap" === simulationValues.actionType) &&
            globalConfig.statusCounters[fromSelectedToken?.extraFields?.contextChainId] && (
              <TradeVisualiserModal
                source={"trade"}
                tradeSequenceRaw={simulatedTradeConverterToVisualizer(simulationValues, poolsStored)}
                gasFees={estimatedFees(
                  1 === simulationValues?.route?.length && "mint" === simulationValues?.route[0]?.derivativeOperation
                    ? GAS_ESTIMATION_STATIC_MINT_DERIVATIVE
                    : simulationValues?.route?.reduce((acc, obj) => {
                        if ("stable" === poolsStored[obj.p]?.type) {
                          return acc + GAS_ESTIMATION_STATIC_TRADE_1_HOP_STABLE;
                        }
                        if ("standard" === poolsStored[obj.p]?.type) {
                          return acc + GAS_ESTIMATION_STATIC_TRADE_1_HOP_STANDARD;
                        }
                        if ("hybrid" === poolsStored[obj.p]?.type) {
                          return acc + GAS_ESTIMATION_STATIC_TRADE_1_HOP_HYBRID;
                        }
                        return acc + 0;
                      }, 0) +
                        (simulationValues?.route?.length > 1
                          ? GAS_ESTIMATION_STATIC_TRADE_ROUTER_OVERHEAD * simulationValues?.route?.length
                          : 0),
                  globalConfig.statusCounters[fromSelectedToken?.extraFields?.contextChainId].estimatedFeesReference
                )}
              />
            )}
        </div>
      </fieldset>

      {("swap" === simulationValues?.actionType || "crosschainSwap" === simulationValues?.actionType) && (
        <SwapDetailsContainer
          simulationValues={simulationValues}
          tradeInfo={tradeInfo}
          slippage={slippage}
          setMarketImpactSwitch={setMarketImpactSwitch}
          marketImpactSwitch={marketImpactSwitch}
          setExchangeRateSwitchFrom={setExchangeRateSwitchFrom}
          exchangeRateSwitchFrom={exchangeRateSwitchFrom}
          i18={i18}
          setToAddr={setToAddr}
        />
      )}

      {"transfer" === simulationValues?.actionType && (
        <TransferDetailsContainer
          connectedChains={walletInfo.connectedChains}
          toAddr={toAddr}
          setToAddr={setToAddr}
          assetChain={chainsStored[fromSelectedToken.extraFields?.contextChainId]}
        />
      )}

      {"ibcTransfer" === simulationValues?.actionType && (
        <IbcTransferDetailsContainer
          connectedChains={walletInfo.connectedChains}
          fromSelectedToken={fromSelectedToken}
          toSelectedToken={toSelectedToken}
          toAddr={toAddr}
          setToAddr={setToAddr}
          simulationValues={simulationValues}
        />
      )}

      {showCrosschainSwapStatusContainer()}

      <Button
        type="submit"
        btnColor="gradient"
        isFullWidth={true}
        text={buttonConfig.buttonText}
        title={i18("Submit", "trade.submitBtn.title")}
        disabled={buttonConfig.disabled}
        extraClassName={buttonConfig.extraClass}
        onClick={submitButtonText().reconnectWalletAction ? reconnectWallet : handleTradeSubmit}
      />

      {largeFeeComponent.visible && (
        <section className="highFee withGradientBorder">
          <CustomSwitch
            name="feeConfirm"
            labelText={
              <>
                <div className={clsx("highFeeText", "inlineFlexbox")}>
                  <small>
                    <NumericFormat
                      displayType="text"
                      thousandSeparator=","
                      decimalSeparator="."
                      decimalScale={2}
                      prefix={i18("Warning HIGH FEE", "trade.form.highFee") + ": "}
                      suffix={`% ($${simulationValues ? Math.round(simulationValues?.feeInUSD * 100) / 100 || 0 : 0})`}
                      value={
                        simulationValues
                          ? parseFloat(simulationValues?.feePer) > 100
                            ? 100
                            : simulationValues?.feePer
                          : "0"
                      }
                    />
                  </small>
                </div>

                <div>
                  <small className="textGrey">
                    {i18("Do you REALLY want to make this HIGH FEE trade?", "trade.form.highFeeConfirm")}
                  </small>
                </div>
              </>
            }
            isToggled={largeFeeComponent.activated}
            onToggle={e => setLargeFeeComponent({ activated: e.target.checked, visible: true })}
          />
        </section>
      )}
    </div>
  );
}

export default TradeForm;
