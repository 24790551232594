import { useEffect, useState } from "react";
import clsx from "clsx";
import BigNumber from "bignumber.js";
import { getAnalytics } from "@axvdex/api/statistics";
import CustomLoader from "@axvdex/components/common/CustomLoader";
import VolumeChart from "@axvdex/components/statistics/VolumeChart";
import LiquidityChart from "@axvdex/components/statistics/LiquidityChart";
import POLChart from "@axvdex/components/statistics/POLChart";
import POLTable from "@axvdex/components/statistics/POLTable";
import StatisticsPoolsTable from "@axvdex/components/statistics/StatisticsPoolsTable";
import { abbreviateNumber, abbreviateUSD } from "@axvdex/utils/formatNumber";
import useLanguage from "@axvdex/hooks/useLanguage";
import StatisticsAssetsTable from "@axvdex/components/statistics/StatisticsAssetsTable";
import CustomInputButton from "@axvdex/components/form-element/CustomInputButton";
import { useAppSelector } from "@axvdex/state";
import { selectAssets, selectChains, selectPools } from "@axvdex/state/wallet/walletSelectors";
import MainLayout from "../layouts/MainLayout";
import styles from "../styles/Statistics.module.scss";

export interface PoolAnalyticsDataSamples {
  [key: string]: [number, number, number][];
}

export interface poolTableData {
  [key: string]: {
    liquidityInUSD: number;
    volume24hInUSD: number;
    volume7dInUSD: number;
    volume30dInUSD: number;
  };
}

export interface assetTableData {
  [key: string]: {
    name: string;
    symbol: string;
    curPriceInUSD: number;
    priceVariation: {
      variationSum: number;
      variationCount: number;
    };
    volume24hMintedInUSD: number;
    volume24hInUSD: number;
    liquidityInUSD: number;
  };
}

interface AnalyticsData {
  currentTVL: number;
  axvBurned: number;
  axvMarketCapitalization: string;
  current24hVolume: number;
  currentLiquidity: number;
  timestamp: string;
  poolsAnalyticsDataSamples: PoolAnalyticsDataSamples;
  poolsTableData: poolTableData;
  assetsTableData: assetTableData;
  polBalances: {
    [key: string]: {
      amount: string;
      amountInUSD: number;
    };
  };
}

const Statistics = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [statistics, setStatistics] = useState<AnalyticsData>(null);
  useEffect(() => {
    const run = async () => {
      setLoading(true);
      try {
        const { data } = await getAnalytics({});
        setStatistics(data);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };
    run();
  }, []);

  if (loading) {
    return <CustomLoader isFixed={true} showGRVT8 />;
  }

  return <StatisticsComponent statistics={statistics} />;
};

function StatisticsComponent({ statistics }: { statistics: AnalyticsData }) {
  const { i18 } = useLanguage();
  const [topFilter, setTopFilter] = useState("all");
  const chains = useAppSelector(selectChains);
  const pools = useAppSelector(selectPools);
  const assets = useAppSelector(selectAssets);

  const [preparedDataSamples, setPreparedDataSamples] = useState<PoolAnalyticsDataSamples | null>(null);
  const [polBalances, setPolBalances] = useState<{
    [key: string]: {
      amount: string;
      amountInUSD: number;
    };
  } | null>(null);
  const [assetTable, setAssetTable] = useState<assetTableData | null>(null);
  const [poolTable, setPoolTable] = useState<poolTableData | null>(null);

  // every time topFilter changes we filter the data according to the filter selected. So the data is already prepared sent to the subcomponents
  useEffect(() => {
    if (topFilter) {
      setPreparedDataSamples(prepareDataSamples());
      setPolBalances(preparePolBalances());
      setAssetTable(prepareAssetTable());
      setPoolTable(preparePoolTable());
    }
  }, [topFilter]);

  const getCurrentTVL = () => {
    switch (topFilter) {
      case "all":
        return Object.values(statistics.currentTVL).reduce((a, b) => a + b, 0);
      default:
        return statistics.currentTVL[topFilter];
    }
  };

  const getCurrentLiquidty = () => {
    switch (topFilter) {
      case "all":
        return Object.values(statistics.currentLiquidity).reduce((a, b) => a + b, 0);
      default:
        return statistics.currentLiquidity[topFilter];
    }
  };

  const getCurrentVolume = () => {
    switch (topFilter) {
      case "all":
        return Object.values(statistics.current24hVolume).reduce((a, b) => a + b, 0);
      default:
        return statistics.current24hVolume[topFilter];
    }
  };

  const prepareDataSamples = () => {
    const dataSamples = filterDataSamples();
    const allPoolsTimestampToData = {};

    // go through all the data samples of all pools, collect all timestamps and create an object with all the data for that timestamp
    Object.keys(dataSamples).forEach(poolID => {
      dataSamples[poolID].forEach(([timestamp, liquidity, volume]) => {
        if (!allPoolsTimestampToData[timestamp]) {
          allPoolsTimestampToData[timestamp] = [timestamp, liquidity, volume];
        } else {
          allPoolsTimestampToData[timestamp][1] += liquidity;
          allPoolsTimestampToData[timestamp][2] += volume;
        }
      });
    });

    const allPools = [];
    // with all data collected, we can now create the final object with all the data for all pools sorted by timestamp
    Object.keys(allPoolsTimestampToData)
      .sort((a, b) => parseInt(a) - parseInt(b))
      .forEach(timestamp => {
        allPools.push(allPoolsTimestampToData[timestamp]);
      });

    dataSamples.allPools = allPools;
    return dataSamples;
  };

  const filterDataSamples = () => {
    switch (topFilter) {
      case "all":
        return { ...statistics.poolsAnalyticsDataSamples };
      default: {
        const data: PoolAnalyticsDataSamples = {};
        Object.keys(statistics.poolsAnalyticsDataSamples).forEach(key => {
          if (pools[key] && pools[key].contextChainId === topFilter) {
            data[key] = statistics.poolsAnalyticsDataSamples[key];
          }
        });
        return data;
      }
    }
  };

  const preparePolBalances = () => {
    // we need to filter the polBalances according to the topFilter
    switch (topFilter) {
      case "all": {
        // for all we also need to group same assets together
        const data = {};
        const symbolMapToKey = {};

        Object.keys(statistics.polBalances).forEach(key => {
          if (assets[key]) {
            const symbol = assets[key].symbol;
            if (!symbolMapToKey[symbol]) {
              symbolMapToKey[symbol] = key;
              data[key] = {
                ...statistics.polBalances[key],
                // this is for cases where there are the same asset on multiple chains with different price variations we do an average of variations
                priceVariation: {
                  variationSum: assets[key].price_24h_change || 0,
                  variationCount: 1,
                },
              };
            } else {
              data[symbolMapToKey[symbol]].amount = BigNumber(data[symbolMapToKey[symbol]].amount)
                .plus(statistics.polBalances[key].amount)
                .toString(10);
              data[symbolMapToKey[symbol]].amountInUSD =
                data[symbolMapToKey[symbol]].amountInUSD + statistics.polBalances[key].amountInUSD;
              data[symbolMapToKey[symbol]].priceVariation = {
                variationSum:
                  data[symbolMapToKey[symbol]].priceVariation.variationSum + (assets[key].price_24h_change || 0),
                variationCount: data[symbolMapToKey[symbol]].priceVariation.variationCount + 1,
              };
            }
          }
        });
        return data;
      }
      default: {
        // chain specific filter, is just filtering in the relevant data to the chain
        const data = {};
        Object.keys(statistics.polBalances).forEach(key => {
          if (assets[key] && assets[key].contextChainId === topFilter) {
            data[key] = statistics.polBalances[key];
          }
        });
        return data;
      }
    }
  };

  const prepareAssetTable = () => {
    // we need to filter the assetTable according to the topFilter
    switch (topFilter) {
      case "all": {
        // for all we also need to group same assets together
        const data = {};
        const symbolMapToKey = {};

        Object.keys(statistics.assetsTableData).forEach(key => {
          if (assets[key]) {
            const symbol = assets[key].symbol;
            if (!symbolMapToKey[symbol]) {
              symbolMapToKey[symbol] = key;
              data[key] = {
                ...statistics.assetsTableData[key],
                // this is for cases where there are the same asset on multiple chains with different price variations we do an average of variations
                priceVariation: {
                  variationSum: assets[key].price_24h_change || 0,
                  variationCount: 1,
                },
              };
            } else {
              // curPriceInUSD => should be the same on same assets
              data[symbolMapToKey[symbol]].volume24hMintedInUSD =
                data[symbolMapToKey[symbol]].volume24hMintedInUSD +
                statistics.assetsTableData[key].volume24hMintedInUSD;
              data[symbolMapToKey[symbol]].volume24hInUSD =
                data[symbolMapToKey[symbol]].volume24hInUSD + statistics.assetsTableData[key].volume24hInUSD;
              data[symbolMapToKey[symbol]].liquidityInUSD =
                data[symbolMapToKey[symbol]].liquidityInUSD + statistics.assetsTableData[key].liquidityInUSD;
              data[symbolMapToKey[symbol]].priceVariation = {
                variationSum:
                  data[symbolMapToKey[symbol]].priceVariation.variationSum + (assets[key].price_24h_change || 0),
                variationCount: data[symbolMapToKey[symbol]].priceVariation.variationCount + 1,
              };
            }
          }
        });

        return data;
      }
      default: {
        // chain specific filter, is just filtering in the relevant data to the chain
        const data = {};
        Object.keys(statistics.assetsTableData).forEach(key => {
          if (assets[key] && assets[key].contextChainId === topFilter) {
            data[key] = statistics.assetsTableData[key];
          }
        });
        return data;
      }
    }
  };

  const preparePoolTable = () => {
    switch (topFilter) {
      case "all": {
        // for all we also need to group same assets together
        const data = {};
        const symbolMapToKey = {};

        Object.keys(statistics.poolsTableData).forEach(key => {
          if (pools[key]) {
            const symbol = pools[key].poolAssetSymbols.join("_");
            if (!symbolMapToKey[symbol]) {
              symbolMapToKey[symbol] = key;
              data[key] = { ...statistics.poolsTableData[key] };
            } else {
              data[symbolMapToKey[symbol]].liquidityInUSD =
                data[symbolMapToKey[symbol]].liquidityInUSD + statistics.poolsTableData[key].liquidityInUSD;
              data[symbolMapToKey[symbol]].volume24hInUSD =
                data[symbolMapToKey[symbol]].volume24hInUSD + statistics.poolsTableData[key].volume24hInUSD;
              data[symbolMapToKey[symbol]].volume7dInUSD =
                data[symbolMapToKey[symbol]].volume7dInUSD + statistics.poolsTableData[key].volume7dInUSD;
              data[symbolMapToKey[symbol]].volume30dInUSD =
                data[symbolMapToKey[symbol]].volume30dInUSD + statistics.poolsTableData[key].volume30dInUSD;
            }
          }
        });

        return data;
      }
      default: {
        const data: poolTableData = {};
        Object.keys(statistics.poolsTableData).forEach(key => {
          if (pools[key] && pools[key].contextChainId === topFilter) {
            data[key] = statistics.poolsTableData[key];
          }
        });
        return data;
      }
    }
  };

  return (
    <MainLayout
      pageClass={clsx(styles.statistics, "statistics")}
      headTitle={i18("Statistics", "statistics.headTitle")}
      headDescription={i18("Statistics for Liquidity and Volume", "statistics.headDescription")}
    >
      <div className="statsPageWrapper">
        <section className=" statsSectionHeader">
          <div className="statsSectionHeaderTitle flexbox">
            <h1>{i18("Statistics", "statistics.title")}</h1>
            <fieldset>
              <legend className="visuallyHidden">{i18("Display", "statistics.topFilter.legend")}</legend>
              <div className="btnGroup">
                <CustomInputButton
                  type="radio"
                  name="statistics_sort"
                  labelText={i18("All", "statistics.topFilter.all")}
                  id="all"
                  value="all"
                  checked={"all" === topFilter}
                  onChange={() => {}}
                  onClick={(e: any) => setTopFilter(e.target.value)}
                />
                {chains &&
                  Object.keys(chains).length > 0 &&
                  // use currentTVL to get all chains we have access on the statistics
                  Object.keys(statistics?.currentTVL)
                    .sort((a, b) => chains[a]?.displayName.localeCompare(chains[b]?.displayName))
                    .map(chainID =>
                      chains[chainID] ? (
                        <CustomInputButton
                          key={chainID}
                          type="radio"
                          id={chainID}
                          name="statistics_sort"
                          labelText={chains[chainID].displayName}
                          value={chainID}
                          checked={topFilter === chainID}
                          onChange={() => {}}
                          onClick={(e: any) => setTopFilter(e.target.value)}
                        />
                      ) : null
                    )}
              </div>
            </fieldset>
          </div>

          <div className="statsSectionGrid statsSectionHeaderGrid">
            <div className="statsSectionGridItem">
              <p className="statsSectionGridItemTitle textGrey">
                {i18("Total Value Locked (TVL)", "statistics.header.tvl")}
              </p>

              <span className="statsSectionGridItemValue pricing">{abbreviateUSD(getCurrentTVL())}</span>
            </div>

            <div className="statsSectionGridItem">
              <p className="statsSectionGridItemTitle textGrey">
                {i18("Market Capitalisation", "statistics.header.marketCapitalisation")}
              </p>

              <span className="statsSectionGridItemValue pricing">
                {abbreviateUSD(statistics?.axvMarketCapitalization)}
              </span>
            </div>
            <div className="statsSectionGridItem">
              <p className="statsSectionGridItemTitle textGrey">
                {i18("Burned AXV", "statistics.header.allTimeBurnedAXV")}
              </p>

              <span className="statsSectionGridItemValue">
                {abbreviateNumber(Object.values(statistics?.axvBurned).reduce((a, b) => a + b.amount, 0))}
              </span>
            </div>
          </div>
        </section>

        {preparedDataSamples && Object.keys(preparedDataSamples).length > 0 && (
          <section className="statsSectionGrid statsSectionGraphGrid">
            <LiquidityChart
              analyticsData={preparedDataSamples}
              curLiquidity={getCurrentLiquidty()}
              topFilter={topFilter}
            />
            <VolumeChart
              analyticsData={preparedDataSamples}
              tableData={statistics?.poolsTableData}
              current24hVolume={getCurrentVolume()}
              topFilter={topFilter}
            />
          </section>
        )}

        {polBalances && Object.keys(polBalances).length > 0 && (
          <section className="statsSectionGrid statsSectionTable">
            <div>
              <POLTable polBalances={polBalances} />
            </div>

            <div>
              <POLChart polBalances={polBalances} />
            </div>
          </section>
        )}

        {assetTable && Object.keys(assetTable).length > 0 && <StatisticsAssetsTable analyticsData={assetTable} />}
        {poolTable && Object.keys(poolTable).length > 0 && <StatisticsPoolsTable analyticsData={poolTable} />}
      </div>
    </MainLayout>
  );
}

export default Statistics;
