import { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { useReactTable, getCoreRowModel, flexRender, ColumnDef } from "@tanstack/react-table";
import { NumericFormat } from "react-number-format";
import BigNumber from "bignumber.js";
import {
  formatBalance,
  fromHumanAmountToTokenBalance,
  humanizeAmount,
  maxDecimalsToDisplay,
} from "@axvdex/utils/formatNumber";
import { standardLPTokenRepresentation } from "@axvdex/utils/unstakeSimulation";
import {
  selectAssetBalances,
  selectAssets,
  selectChains,
  // selectGlobalConfig,
  selectWalletInfo,
} from "@axvdex/state/wallet/walletSelectors";
import {
  executePoolAction,
  increasePoolAllowance,
  retryWalletWithDispatch,
  updateLpStakingBalance,
  updateNativeBalance,
  updateTokenBalance,
} from "@axvdex/state/wallet/walletThunks";
import { useAppDispatch, useAppSelector } from "@axvdex/state";
import Button from "@axvdex/components/common/Button";
import stakeSimulation from "@axvdex/utils/swapScripts/stakeSimulation";
import imgSanitize from "@axvdex/utils/imgSanitize";
// import estimatedFees, { GAS_ESTIMATION_STATIC_STANDARD_POOL_DEPOSIT } from "@axvdex/utils/estimatedFees";
import useLanguage from "@axvdex/hooks/useLanguage";
import rpcClientQuerySmartContractWrapper from "@axvdex/utils/rpcClientQuerySmartContractWrapper";
import { WHITE_LIST_PERSISTED_STATE_KEYS, loadState } from "@axvdex/state/persist";
import CustomNumericInput from "../form-element/CustomNumericInput";
import CustomLoader from "../common/CustomLoader";

// const DummyPoolCardDetailsTableList = [
//   { token: "ARCH", total: 10, deposits: 10 },
//   { token: "xARCH", total: 11, deposits: 15 },
// ];

interface PoolCardStandardDepositTableProps {
  id: string;
  symbol: string;
  image: string;
  total: number;
  deposited: {
    amount: number;
    usdConversion: number;
  };
  allowanceLoading: boolean;
  allowance?: {
    allowance: string;
    expires: any;
  } | null;
  input: string;
  balance: number | null;
}

interface ComponentProps {
  poolData: any;
  [key: string]: any;
}

function PoolCardStandardDepositTable({ poolData }: ComponentProps) {
  const { i18 } = useLanguage();
  const assetBalances = useAppSelector(selectAssetBalances);
  const assets = useAppSelector(selectAssets);
  const chains = useAppSelector(selectChains);
  // const globalConfig = useAppSelector(selectGlobalConfig);

  const [data, setData] = useState<PoolCardStandardDepositTableProps[]>([]);
  const [simulationValues, setSimulationValues] = useState<any>({});
  const [hasReconnectedChainContext, setHasReconnectedChainContext] = useState(false); // used when need to reconnect wallet to redo the useEffect that updates the initial state
  const [isTransactionStarts, setIsTransactionStarts] = useState(false);
  const dispatch = useAppDispatch();
  const walletInfo = useAppSelector(selectWalletInfo);
  const [isLoading, setIsLoading] = useState(false);

  const walletChainContext = walletInfo?.connectedChains[poolData.contextChainId];

  useEffect(() => {
    const update = async () => {
      // if there is no wallet connect and auto connect is enabled, we should show the loader as we are trying to auto connect
      if (!walletInfo.isConnected && loadState(WHITE_LIST_PERSISTED_STATE_KEYS.autoConnectWallet)) {
        setIsLoading(true);
        return;
      }
      if (0 === data.length) setIsLoading(true);

      // get asset balances of this pool that are not already in state
      const requests = [];
      for (const [i, poolAsset] of poolData.poolAssets.entries()) {
        if (!assetBalances[poolData.assetIDs[i]] && walletInfo.isConnected) {
          if (poolAsset.info.token) {
            requests.push(
              dispatch(
                updateTokenBalance({
                  client: walletChainContext.signingClient,
                  userAddress: walletChainContext.address,
                  tokenAddress: poolAsset.info.token.contract_addr,
                })
              )
            );
          } else {
            requests.push(
              dispatch(
                updateNativeBalance({
                  client: walletChainContext.signingClient,
                  userAddress: walletChainContext.address,
                  denom: poolAsset.info.native_token.denom,
                })
              )
            );
          }
        } else {
          requests.push(Promise.resolve());
        }
      }

      const poolAssetBalances = await Promise.all(requests);

      // get balance of this user on this pool
      let lpStakingBalance = assetBalances[poolData?.lp_staking];
      if (!assetBalances[poolData?.lp_staking] && walletInfo.isConnected) {
        lpStakingBalance = (
          await dispatch(
            updateLpStakingBalance({
              client: walletChainContext.signingClient,
              userAddress: walletChainContext.address,
              lpStakingAddress: poolData?.lp_staking,
            })
          )
        ).payload;
      }

      // get allowances of this user for tokens on this pool
      const allowanceResponses = await Promise.all([
        //across all tokens on this pool, check if the allowance was already provided
        ...poolData.poolAssets.reduce((acc, asset) => {
          if (asset.info.token && walletInfo.isConnected) {
            const allowanceResponse = rpcClientQuerySmartContractWrapper(
              walletChainContext.signingClient,
              asset.info.token.contract_addr,
              {
                allowance: {
                  owner: walletChainContext.address,
                  spender: poolData.address,
                },
              }
            );

            acc = [...acc, allowanceResponse];
            return acc;
          }

          return [...acc, null];
        }, []),
      ]);

      const pPoolData = poolData.poolAssets.map((asset, i) => {
        return {
          id: poolData?.assetIDs[i],
          total: humanizeAmount(asset.amount, assets[poolData?.assetIDs[i]]?.decimals),
          symbol: assets[poolData?.assetIDs[i]]?.symbol,
          deposited: formatBalance(
            standardLPTokenRepresentation(lpStakingBalance as string, poolData).refund_assets[i],
            assets[poolData?.assetIDs[i]]?.price,
            assets[poolData?.assetIDs[i]]?.decimals
          ),
          input: "",
          allowance: allowanceResponses[i],
          balance: poolAssetBalances[i]
            ? formatBalance(
                poolAssetBalances[i].payload,
                assets[poolData?.assetIDs[i]].price,
                assets[poolData?.assetIDs[i]].decimals
              ).amount || 0
            : formatBalance(
                assetBalances[poolData?.assetIDs[i]],
                assets[poolData?.assetIDs[i]].price,
                assets[poolData?.assetIDs[i]].decimals
              ).amount || 0,
        };
      });
      setData(pPoolData);
      setIsLoading(false);
    };
    update();
  }, [walletInfo.isConnected, hasReconnectedChainContext]);

  useEffect(() => {
    setData(prevData => {
      const newData = [...prevData];
      newData.map((row, i) => {
        row.deposited = formatBalance(
          standardLPTokenRepresentation(assetBalances[poolData?.lp_staking], poolData).refund_assets[i],
          assets[poolData?.assetIDs[i]]?.price,
          assets[poolData?.assetIDs[i]]?.decimals
        );
        row.balance = formatBalance(
          assetBalances[poolData?.assetIDs[i]],
          assets[poolData?.assetIDs[i]].price,
          assets[poolData?.assetIDs[i]].decimals
        ).amount;
        return row;
      });
      return newData;
    });
  }, [assetBalances]);

  const handleStandardPoolInputChange = (amount, index) => {
    setData(prevData => {
      let amounts = [null, null];
      if ("0" === poolData.total_share) {
        amounts = [prevData[0].input, prevData[1].input];
      }
      amounts[index] = amount.replaceAll(",", "");
      const newData = [...prevData];
      const transformData = stakeSimulation(poolData, amounts);
      if (!transformData) {
        return newData.map((row, _) => {
          return { ...row, input: "" };
        });
      }
      const returnAmounts = transformData.returnAmounts;
      delete transformData.returnAmounts;
      setSimulationValues({ ...transformData });
      return newData.map((row, index) => {
        return { ...row, input: returnAmounts[index] };
      });
    });
  };

  const handleIncreaseAllowance = async (tokenAddress, index) => {
    setData(prevData => {
      const newData = [...prevData];
      newData[index].allowanceLoading = true;
      return newData;
    });

    const res = await dispatch(
      increasePoolAllowance({
        poolData,
        tokenAddress,
        i18,
      })
    );

    setData(prevData => {
      const newData = [...prevData];
      newData[index].allowanceLoading = false;
      newData[index].allowance = res.payload as { allowance: string; expires: any } | null;
      return newData;
    });
  };

  const reconnectWallet = async () => {
    setIsTransactionStarts(true);
    await dispatch(
      retryWalletWithDispatch({
        chainToConnect: chains[poolData.contextChainId],
      })
    );
    setHasReconnectedChainContext(!hasReconnectedChainContext);
    setIsTransactionStarts(false);
  };

  const handleSubmit = async () => {
    setIsTransactionStarts(true);

    await dispatch(
      executePoolAction({
        poolData,
        simulationValues,
        isDeposit: true,
        i18,
      })
    );

    handleStandardPoolInputChange("", 0);
    handleStandardPoolInputChange("", 1);
    setIsTransactionStarts(false);
  };

  const defaultColumns = useMemo<ColumnDef<PoolCardStandardDepositTableProps>[]>(() => {
    return [
      {
        id: "token",
        header: () => <span>{i18("Token Balance", "poolCardTable.table.columns.token")}</span>,
        cell: ({ row }) => (
          <div className="flexbox">
            <span className="tokenIcon" title={row.original.symbol} aria-label={row.original.symbol}>
              <img
                src={imgSanitize(row.original.symbol)}
                alt={row.original.symbol}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  dispatch(
                    updateTokenBalance({
                      client: walletChainContext.signingClient,
                      userAddress: walletChainContext.address,
                      tokenAddress: row.original.id,
                    })
                  )
                }
              />
            </span>

            {row.original.balance !== null && <span>{row.original.balance}</span>}
          </div>
        ),
      },
      {
        id: "deposited",
        accessorKey: "deposited",
        header: () => <span>{i18("Deposited", "poolCardTable.table.columns.deposited")}</span>,
        cell: ({ row }) => (
          <div className="flexbox deposited">
            <NumericFormat
              displayType="text"
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={maxDecimalsToDisplay(BigNumber(row.original.deposited.amount || 0))}
              suffix=""
              value={BigNumber(row.original.deposited.amount || 0)
                .decimalPlaces(
                  maxDecimalsToDisplay(BigNumber(row.original.deposited.amount || 0)),
                  BigNumber.ROUND_FLOOR
                )
                .toString()}
            />

            <NumericFormat
              className="tableInputValueConversion"
              displayType="text"
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={2}
              prefix={"($"}
              suffix={")"}
              value={BigNumber(row.original.deposited.usdConversion || 0)
                .decimalPlaces(2, BigNumber.ROUND_FLOOR)
                .toString()}
            />
          </div>
        ),
      },
      {
        id: "actions",
        header: () => <span className="visuallyHidden">{i18("Actions", "poolCardTable.table.columns.actions")}</span>,
        cell: ({ row }) => {
          return (
            <>
              {row.original.allowance?.allowance !== "0" || assets[row.original.id].isNative ? (
                <>
                  <div className="formGroupInline">
                    {row.original.balance !== null && (
                      <Button
                        text={i18("Max", "poolCardTable.maxBtn.text")}
                        title={i18("Set Max Value", "poolCardTable.maxBtn.title")}
                        btnColor="gradientText"
                        onClick={() => handleStandardPoolInputChange(row.original.balance + "", row.index)}
                      />
                    )}
                    <CustomNumericInput
                      extraClassName="derivativeFromUsdFormGroup"
                      name={`amount_${row.index}`}
                      labelText={i18("Enter amount", "poolCardTable.input.label")}
                      hiddenLabel={true}
                      placeholder={"0"}
                      value={row.original.input}
                      decimalScale={6}
                      onChange={e => {
                        handleStandardPoolInputChange(e.target.value, row.index);
                        // setAmountInputs(amountInput => {
                        //   const newAmountInput = [...amountInputs];
                        //   newAmountInput[row.index] = e.target.value;
                        //   return newAmountInput;
                        // });
                      }}
                    />
                  </div>

                  <div className="tableInputValueConversion withMarginTop">
                    ($
                    {
                      formatBalance(
                        fromHumanAmountToTokenBalance(
                          row.original.input?.replaceAll(",", ""),
                          assets[row.original.id].decimals
                        ),
                        assets[row.original.id].price,
                        assets[row.original.id].decimals
                      ).usdConversion
                    }
                    )
                  </div>
                </>
              ) : (
                <>
                  {!row.original.allowanceLoading ? (
                    <Button
                      text={i18(`Approve ${assets[row.original.id].symbol}`, "poolCardTable.approveBtn.text", {
                        symbol: assets[row.original.id].symbol,
                      })}
                      title={i18(`Approve set ${assets[row.original.id].symbol}`, "poolCardTable.approveBtn.title", {
                        symbol: assets[row.original.id].symbol,
                      })}
                      btnColor="gradientText"
                      onClick={() => handleIncreaseAllowance(assets[row.original.id].address, row.index)}
                    />
                  ) : (
                    <CustomLoader text={i18("Processing...", "poolCardTable.loader.text")} size="xs" />
                  )}
                </>
              )}
            </>
          );
        },
      },
    ];
  }, [i18]);

  const [columns, setColumns] = useState(() => [...defaultColumns]);

  // useEffect(() => {
  //   setData(DummyPoolCardDetailsTableList);
  // }, [defaultColumns]);

  useEffect(() => {
    setColumns([...defaultColumns]);
  }, [defaultColumns]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const shouldDisableButton = amountWithinInput => {
    return !amountWithinInput.every((value, index) =>
      BigNumber(value)
        .times(Math.pow(10, assets[poolData.assetIDs[index]].decimals))
        .lte(assetBalances[poolData.assetIDs[index]])
    );
  };

  const submitButtonText = () => {
    const action = i18("Deposit", "poolCardTable.submitBtn.text.deposit");
    const chain = chains[poolData?.contextChainId]?.displayName;
    if (!walletInfo.connectedChains[poolData?.contextChainId]?.signingStargateClient) {
      return {
        buttonText: i18(`${chain} not connected. Try to reconnect...`, "poolCardTable.submitBtn.text.notConnected", {
          chain,
        }),
        disabled: false,
        reconnectWalletAction: true,
        extraClass: "",
      };
    }

    if (data.every(value => !value.input || !Number(value.input))) {
      return {
        buttonText: i18("Enter Amount", "poolCardTable.submitBtn.text.enterAmount"),
        disabled: true,
      };
    }

    if (!poolData?.owner_settings?.is_provide_liquidity_enabled) {
      return {
        buttonText: i18("Deposit disabled", "poolCardTable.submitBtn.text.disabled", { action }),
        disabled: true,
      };
    }

    if (isTransactionStarts) {
      return {
        buttonText: i18("Executing...", "poolCardTable.submitBtn.text.executing"),
        disabled: true,
      };
    }

    if (shouldDisableButton(data.map(row => row.input))) {
      return {
        buttonText: i18("Not enough balance", "poolCardTable.submitBtn.text.notEnoughBalance"),
        disabled: true,
      };
    }

    // DISABLED THIS CHECK BECAUSE OF NEUTRON HAVING MULTIPLE GAS TOKENS
    // // check if user has sufficient gas fees to cover this
    // const calcEstimatedFees = estimatedFees(
    //   GAS_ESTIMATION_STATIC_STANDARD_POOL_DEPOSIT,
    //   globalConfig.statusCounters[poolData.contextChainId].estimatedFeesReference
    // );
    // const feeDenom = globalConfig.statusCounters[poolData.contextChainId].estimatedFeesReference.estimatedFee[0].denom;
    // if (assetBalances[feeDenom] && BigNumber(assetBalances[feeDenom]).lt(calcEstimatedFees)) {
    //   return {
    //     buttonText: i18(
    //       `Wallet needs ${assets[feeDenom].symbol} for Deposit`,
    //       "poolCardTable.submitBtn.text.walletNoFunds",
    //       { action, symbol: assets[feeDenom].symbol }
    //     ),
    //     disabled: true,
    //   };
    // }

    // // if the input is the source denom we need to check if it will consume more fees than the user have balance
    // // current balance - input amount < estimated fees
    // if (
    //   data.find(row => row.id === feeDenom) &&
    //   assetBalances[feeDenom] &&
    //   BigNumber(assetBalances[feeDenom])
    //     .minus(
    //       fromHumanAmountToTokenBalance(
    //         data.find(row => row.id === feeDenom).input.replaceAll(",", ""),
    //         assets[feeDenom].decimals
    //       )
    //     )
    //     .lt(calcEstimatedFees)
    // ) {
    //   return {
    //     buttonText: i18(
    //       `Wallet needs ${assets[feeDenom].symbol} for Deposit`,
    //       "poolCardTable.submitBtn.text.walletNoFunds",
    //       { action, symbol: assets[feeDenom].symbol }
    //     ),
    //     disabled: true,
    //   };
    // }

    return {
      buttonText: action,
      disabled: false,
    };
  };

  return (
    <section className={clsx("poolCardModalSectionTable withGradientBorderBottom")}>
      {isLoading ? (
        <div style={{ textAlign: "center" }}>
          {walletChainContext?.signingStargateClient && <CustomLoader size="xs" />}
        </div>
      ) : (
        <table
          className={clsx(
            "table",
            "withGradientBorderRows",
            "poolCardModalDetailsTable",
            "isResponsive",
            "col-3",
            "standardDeposit"
          )}
        >
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <br />
      {!isTransactionStarts ? (
        <Button
          text={submitButtonText().buttonText}
          title={i18("Submit", "poolCardTable.submitBtn.title")}
          isFullWidth={true}
          btnColor="gradient"
          onClick={submitButtonText().reconnectWalletAction ? reconnectWallet : handleSubmit}
          disabled={submitButtonText().disabled}
        />
      ) : (
        <CustomLoader text={i18("Processing...", "poolCardTable.loader.text")} size="xs" />
      )}
    </section>
  );
}

export default PoolCardStandardDepositTable;
