import { createSlice } from "@reduxjs/toolkit";
import BigNumber from "bignumber.js";
//import { useDispatch } from "react-redux";
//import { useAppDispatch } from "..";
//import { updateNicknamesFromBlockchain } from "../outbid/saleThunks";
import nicknamesSanitization, {
  addToBannedNames,
  loadInitListOfBannedNames,
} from "@axvdex/utils/nicknamesSanitization";
import state from "./initialState";
//import { connectWalletWithDispatch, updateTokenBalance } from "./walletThunks";

export const walletSlice = createSlice({
  name: "wallet",
  initialState: state,
  reducers: {
    clearWallet: state => {
      state.walletInfo = {
        isConnected: false,
        name: "",
        pubKey: "",
        connectedChains: {},
        connectionStatus: "",
      };
      state.assetBalances = {};
      state.poolsLpBalance = {};
      state.user = {
        favoriteSwaps: [],
        favoritePools: [],
        favoriteFarms: [],
        priceWatch: [],
        poolsWithBalance: [],
        farmsWithBalance: [],
        swapLogs: [],
        myAssets: [],
        favoriteAssets: [],
        isUserDetailsLoad: false,
        grvt8Balance: null,
        salesBids: null,
        myDashboardGrid: null,
        myGlobalSettings: null,
        myToastMessages: [],
        vestingLockedAmount: null,
        myAssetUnmints: {},
        mySoftLockups: {},
        notifications: [],
        surveys: null,
      };

      state.toast = null;
      state.isLoadingWallet = false;
    },
    setLoadingWallet: (state, { payload }) => {
      state.isLoadingWallet = payload;
    },
    initWalletInfo: (state, action) => {
      const { wallet, assetBalances } = action.payload;
      state.walletInfo = wallet;
      // if there is a vesting locked amount, subtract it from the native denom balance...
      // this is only relevant on archway
      if (state.user.vestingLockedAmount && assetBalances.aarch) {
        const newBalance = BigNumber(assetBalances.aarch).minus(state.user.vestingLockedAmount);
        if (newBalance.gte(0)) {
          assetBalances.aarch = newBalance.toString(10);
        } else {
          assetBalances.aarch = "0";
        }
      }
      state.assetBalances = { ...state.assetBalances, ...assetBalances };
      state.isLoadingWallet = false;
    },
    addConnectedChainToWalletInfo: (state, action) => {
      const { chainId, chainConnectionDetails } = action.payload;
      state.walletInfo = {
        ...state.walletInfo,
        connectedChains: {
          ...state.walletInfo.connectedChains,
          [chainId]: chainConnectionDetails,
        },
      };
    },
    removeConnectedChainFromWalletInfo: (state, action) => {
      const { chainId } = action.payload;
      const connectedChains = { ...state.walletInfo.connectedChains };
      delete connectedChains[chainId];
      state.walletInfo = {
        ...state.walletInfo,
        connectedChains,
      };
    },
    // setWalletInfo: (_state, _) => {},
    updateConnectionStatus: (state, { payload }) => {
      const { isConnected, assetBalances, connectionStatus } = payload;
      state.walletInfo = {
        ...state.walletInfo,
        isConnected,
        connectionStatus,
      };
      state.assetBalances = { ...state.assetBalances, ...assetBalances };
      state.isLoadingWallet = false;
    },
    updateAssets: (state, action) => {
      state.assets = { ...state.assets, ...action.payload };
    },
    updateWsAssetFields: (state, action) => {
      // only update the fields if the item already exists on state
      if (state.assets[action.payload.id]) {
        state.assets = {
          ...state.assets,
          [action.payload.id]: {
            ...state.assets[action.payload.id],
            price: action.payload.price,
          },
        };
      }
    },
    updateWsContractFields: (state, action) => {
      // only update the fields if the item already exists on state
      const label = action.payload.label + "_" + action.payload.contextChainId;
      if (state.contracts[label]) {
        if (action.payload.extraFields)
          state.contracts = {
            ...state.contracts,
            [label]: {
              ...state.contracts[label],
              extraFields: action.payload.extraFields,
            },
          };
        if ("nicknames" === action.payload.label) {
          if (action.payload.extraFields?.uiToUpdate && action.payload.extraFields.uiToUpdate.length > 0) {
            const info = {};
            action.payload.extraFields.uiToUpdate.forEach(uiToUpdate => {
              const data = JSON.parse(uiToUpdate);
              info[data.address] = data.info;
            });
            state.nicknames = {
              ...state.nicknames,
              ...nicknamesSanitization(info),
            };
          }
          // when someone is banned, the BE emits an event with newBannedNickname field
          if (action.payload.newBannedNickname) {
            addToBannedNames(action.payload.newBannedNickname);
            state.nicknames = {
              ...nicknamesSanitization({
                ...state.nicknames,
              }),
            };
          }
        }
      }
    },
    updateWsFarmFields: (state, action) => {
      state.farms = {
        ...state.farms,
        [action.payload.address]: {
          ...state.farms[action.payload.address],
          tvl: action.payload.tvl,
        },
      };
    },
    updateWsOutbidSaleFields: (state, action) => {
      // only update the fields if the item already exists on state
      if (state.outbid[action.payload.label] && action.payload.saleAddress) {
        state.outbid = {
          ...state.outbid,
          [action.payload.label]: {
            ...state.outbid[action.payload.label],
            salesState: {
              ...state.outbid[action.payload.label].salesState,
              [action.payload.saleAddress]: action.payload.saleState,
            },
          },
        };
      }
      state.outbidServerTime = action.payload.serverTime;
    },
    updateWsServerTimeField: (state, action) => {
      state.outbidServerTime = action.payload;
    },
    updateAssetBalances: (state, action) => {
      // if there is a vesting locked amount, subtract it from the native denom balance...
      // this is only relevant on archway
      if (state.user.vestingLockedAmount && action.payload.aarch) {
        const newBalance = BigNumber(action.payload.aarch).minus(state.user.vestingLockedAmount);
        if (newBalance.gte(0)) {
          action.payload.aarch = newBalance.toString(10);
        } else {
          action.payload.aarch = "0";
        }
      }
      state.assetBalances = { ...state.assetBalances, ...action.payload };
    },
    removeAssetBalances: (state, action) => {
      const updatedBalances = { ...state.assetBalances };
      for (const assetId of action.payload.assetIdsToRemove) {
        delete updatedBalances[assetId];
      }
      state.assetBalances = updatedBalances;
    },
    updateAssetsUnmints: (state, action) => {
      state.user.myAssetUnmints = { ...state.user.myAssetUnmints, ...action.payload };
    },
    updateMySoftLockups: (state, action) => {
      state.user.mySoftLockups = { ...state.user.mySoftLockups, ...action.payload };
    },
    updatePools: (state, action) => {
      state.pools = { ...state.pools, ...action.payload };
    },
    updateFarms: (state, action) => {
      state.farms = { ...state.farms, ...action.payload };
    },
    updatePoolsLpBalance: (state, action) => {
      state.poolsLpBalance = { ...state.poolsLpBalance, ...action.payload };
    },
    updateFarmsLpBalance: (state, action) => {
      state.farmsLpBalance = { ...state.farmsLpBalance, ...action.payload };
    },
    updateWsPoolFields: (state, action) => {
      // only update the fields if the item already exits on state
      if (state.pools[action.payload.address]) {
        state.pools = {
          ...state.pools,
          [action.payload.address]: {
            ...state.pools[action.payload.address],
            poolAssets: action.payload.poolAssetsAmounts
              ? state.pools[action.payload.address].poolAssets.map((poolAsset, i) => {
                  return {
                    info: poolAsset.info,
                    amount: action.payload.poolAssetsAmounts[i],
                  };
                })
              : state.pools[action.payload.address].poolAssets,
            hybridRatioDetails: {
              ...state.pools[action.payload.address].hybridRatioDetails,
              ratio: action.payload.ratio,
            },
          },
        };
      }
    },
    setPoolInfo: (state, action) => {
      state.pools = {
        ...state.pools,
        [action.payload.poolAddress]: {
          ...state.pools[action.payload.poolAddress],
          poolAssets: state.pools[action.payload.poolAddress].poolAssets.map((asset, i) => {
            return {
              ...asset,
              amount: action.payload.amounts[i],
            };
          }),
          total_share: action.payload.total_share,
        },
      };
    },
    addFavouritePool: (state, { payload }) => {
      state.user.favoritePools = state.user.favoritePools.includes(payload.favoritePool)
        ? [...state.user.favoritePools]
        : [...state.user.favoritePools, payload.favoritePool];
    },
    deleteFavouritePool: (state, { payload }) => {
      state.user.favoritePools = state.user.favoritePools.filter(item => item !== payload.favoritePool);
    },
    addFavouriteFarm: (state, { payload }) => {
      state.user.favoriteFarms = state.user.favoriteFarms.includes(payload.favoriteFarm)
        ? [...state.user.favoriteFarms]
        : [...state.user.favoriteFarms, payload.favoriteFarm];
    },
    deleteFavouriteFarm: (state, { payload }) => {
      state.user.favoriteFarms = state.user.favoriteFarms.filter(item => item !== payload.favoriteFarm);
    },
    updateContracts: (state, action) => {
      // this is called on the initial load of contracts
      state.contracts = { ...state.contracts, ...action.payload };

      // try find nickname contracts
      try {
        const nicknameContractKeys = Object.keys(action.payload).filter(key => key.includes("nicknames"));

        for (const key of nicknameContractKeys) {
          if (
            action.payload[key].addedFields?.bannedNicknames &&
            JSON.parse(atob(action.payload[key].addedFields?.bannedNicknames)).length > 0
          ) {
            loadInitListOfBannedNames(JSON.parse(atob(action.payload[key].addedFields?.bannedNicknames)));
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    updateOutbidSales: (state, action) => {
      state.outbid = action.payload;
    },
    updateOutbidServerTime: (state, action) => {
      state.outbidServerTime = action.payload;
    },
    updateChains: (state, action) => {
      state.chains = action.payload;
    },
    updateGlobalConfig: (state, action) => {
      state.globalConfig = action.payload;
    },
    setStateUser: (state, { payload }) => {
      state.user = payload;
    },
    setStateBalances: (state, { payload }) => {
      state.assetBalances = payload;
    },
    setStateLpBalances: (state, { payload }) => {
      state.poolsLpBalance = payload;
    },
    setStateFarmLpBalances: (state, { payload }) => {
      state.farmsLpBalance = payload;
    },
    updatingUser: (state, { payload }) => {
      state.updatingUser = payload;
    },
    updateUser: (state, { payload }) => {
      const user = payload;
      const balanceOfUserPools = user.poolsWithBalance.reduce((acc, item) => {
        return { ...acc, [item.contract_addr]: item.balance || "0" };
      }, {});
      const transformedUser = { ...user, isUserDetailsLoad: !!user.pub_key };
      delete transformedUser.addresses;
      delete transformedUser.pub_key;

      state.assetBalances = { ...state.assetBalances, ...balanceOfUserPools };
      state.user = { ...state.user, ...transformedUser };
      // add globalSettings field and poolsWithBalance if it's undefined from the API
      // so the app knows things came from API but there are no configured globalSettings
      state.user.myGlobalSettings = state.user.myGlobalSettings ?? {};
      state.user.poolsWithBalance = state.user.poolsWithBalance ?? [];
      state.updatingUser = false;
    },
    setUserSwapLogs: (state, { payload }) => {
      state.user.swapLogs = payload;
    },
    updateSwapFavourites: (state, action) => {
      state.user.favoriteSwaps = action.payload;
    },
    updateFavouriteAssets: (state, action) => {
      state.user.favoriteAssets = action.payload;
    },
    updateMyAssets: (state, action) => {
      state.user.myAssets = action.payload;
    },
    updatePriceWatch: (state, { payload }) => {
      state.user.priceWatch = payload;
    },
    setGRVT8Balance: (state, { payload }) => {
      state.user.grvt8Balance = {
        ...state.user.grvt8Balance,
        [payload.grvt8Address]: payload.amount,
      };
    },
    setSalesBids: (state, { payload }) => {
      state.user.salesBids = {
        ...state.user.salesBids,
        [payload.saleAddress]: payload.saleUserBids,
      };
    },
    sendToast: (state, action) => {
      state.toast = action.payload;
    },
    updateToastMessages: (state, action) => {
      state.user.myToastMessages = action.payload;
    },
    setMyDashboardGrid: (state, action) => {
      state.user.myDashboardGrid = action.payload;
    },
    setMyGlobalSettings: (state, action) => {
      state.user.myGlobalSettings = action.payload;
    },
    setVestingLockedAmount: (state, action) => {
      state.user.vestingLockedAmount = action.payload;
    },
    updateNicknames: (state, { payload }) => {
      state.nicknames = {
        ...state.nicknames,
        ...nicknamesSanitization(payload),
      };
    },
    setBackgroundTemporaryState: (state, { payload }) => {
      state.backgroundTemporaryState = {
        ...state.backgroundTemporaryState,
        [payload.type]: payload.data,
      };
    },
  },
  // extraReducers: builder => {
  //   builder.addCase(connectWalletWithDispatch.fulfilled, (_state, _action) => {});
  //   builder.addCase(updateTokenBalance.rejected, (state, action) => {
  //     console.log(action.payload);
  //   });
  // },
});

// Action creators are generated for each case reducer function
export const {
  clearWallet,
  initWalletInfo,
  addConnectedChainToWalletInfo,
  removeConnectedChainFromWalletInfo,
  // setWalletInfo,
  updateConnectionStatus,
  updateAssetBalances,
  removeAssetBalances,
  updateAssetsUnmints,
  updateMySoftLockups,
  updateUser,
  updateSwapFavourites,
  updateFavouriteAssets,
  setStateUser,
  setStateBalances,
  setStateLpBalances,
  updateAssets,
  updateMyAssets,
  updatePools,
  updateFarms,
  updatePoolsLpBalance,
  updateFarmsLpBalance,
  updateWsPoolFields,
  updateWsAssetFields,
  updateWsContractFields,
  updateWsFarmFields,
  updateContracts,
  updateWsOutbidSaleFields,
  updateWsServerTimeField,
  updateOutbidSales,
  updateOutbidServerTime,
  updateChains,
  updateGlobalConfig,
  setPoolInfo,
  addFavouritePool,
  deleteFavouritePool,
  addFavouriteFarm,
  deleteFavouriteFarm,
  sendToast,
  updateToastMessages,
  setUserSwapLogs,
  setLoadingWallet,
  updatePriceWatch,
  setGRVT8Balance,
  setSalesBids,
  updatingUser,
  setMyDashboardGrid,
  setMyGlobalSettings,
  setVestingLockedAmount,
  updateNicknames,
  setStateFarmLpBalances,
  setBackgroundTemporaryState,
} = walletSlice.actions;

export default walletSlice.reducer;
