import { useState } from "react";
import clsx from "clsx";
import FeegrantContainer from "@axvdex/components/trade/FeegrantContainer";
import { useAppSelector } from "@axvdex/state";
import { selectAssetBalances, selectChains, selectWalletInfo } from "@axvdex/state/wallet/walletSelectors";
import StatusMessages from "@axvdex/components/StatusMessages";
import useLanguage from "@axvdex/hooks/useLanguage";
import TemplateContainer from "@axvdex/components/trade/TemplateContainer";
import { TradeInfo, initialTradeInfo } from "@axvdex/components/forms/tradeHelpers";
import Button from "@axvdex/components/common/Button";
import MainLayout from "../layouts/MainLayout";
import styles from "../styles/Trade.module.scss";
import TradeContainer from "components/trade/TradeContainer";

function Trade() {
  const walletInfo = useAppSelector(selectWalletInfo);
  const { i18 } = useLanguage();
  const [feeGrantAssetsToTrade, setFeeGrantAssetsToTrade] = useState(null);
  const [tradeInfo, setTradeInfo] = useState<TradeInfo>(initialTradeInfo);
  const [isTransactionStarts, setIsTransactionStarts] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [title, setTitle] = useState("");
  const chains = useAppSelector(selectChains);
  const assetBalances = useAppSelector(selectAssetBalances);
  const [isFeeGrantContainerExpand, setIsFeeGrantContainerExpand] = useState(false);

  const showFeeGrant = () => {
    // fee grant only available on archway chain
    const chain = Object.values(chains).find(chain => "archway" === chain.prefix);
    const otherDeployChainNatives = ["untrn", "unibi"];
    return (
      chain &&
      walletInfo &&
      walletInfo.isConnected &&
      walletInfo.connectedChains[chain.chainId] &&
      (0 === walletInfo.connectedChains[chain.chainId].connectSequence ||
        null === walletInfo.connectedChains[chain.chainId].connectSequence) &&
      // check if the user has no balance on the other chains native
      ("0" === assetBalances[otherDeployChainNatives[0]] || !assetBalances[otherDeployChainNatives[0]]) &&
      ("0" === assetBalances[otherDeployChainNatives[1]] || !assetBalances[otherDeployChainNatives[1]])
    );
  };

  return (
    <MainLayout
      pageClass={clsx(styles.trade, "trade")}
      headTitle={i18("Deposit, trade, move, send, or withdraw assets on Astrovault", "trade.head.title")}
      headDescription={i18("Deposit, trade, move, send, or withdraw assets here", "trade.head.description")}
    >
      <StatusMessages />
      <div className="tradePageWrapper">
        <section className="tradeSectionForm">
          <div
            className="inlineFlexbox"
            style={{ placeContent: "space-between", width: "100%", justifyContent: "right" }}
          >
            {/* {
              // hide the title for now
              <h1 className="tradeSectionFormTitle">
                {
                  ""
                  //title
                }
              </h1>
            } */}
            {showFeeGrant() && (
              <Button
                style={{ marginBottom: "0.5em" }}
                btnColor="dark-medium"
                title={i18("Get $ARCH", "trade.feeGrant.getArch")}
                onClick={() => {
                  setIsFeeGrantContainerExpand(!isFeeGrantContainerExpand);
                }}
              >
                <span>
                  {isFeeGrantContainerExpand
                    ? i18("Hide $ARCH", "trade.feeGrant.close")
                    : i18("Get $ARCH", "trade.feeGrant.getArch")}
                </span>
              </Button>
            )}
            {!isFeeGrantContainerExpand && (
              <TemplateContainer
                tradeInfo={tradeInfo}
                setTradeInfo={setTradeInfo}
                isTransactionStarts={isTransactionStarts}
              />
            )}
          </div>
          {/* <p style={{ display: "block", margin: "10px 0 5px", color: "var(--warm-grey)" }}>
            {i18("Deposit, trade, move, send, or withdraw assets here", "trade.body.description")}
          </p> */}
          {isFeeGrantContainerExpand && (
            <FeegrantContainer
              setFeeGrantAssetsToTrade={setFeeGrantAssetsToTrade}
              chainId={Object.values(chains).find(chain => "archway" === chain.prefix).chainId}
            />
          )}

          {(!isFeeGrantContainerExpand || (isFeeGrantContainerExpand && feeGrantAssetsToTrade)) && (
            <TradeContainer
              feeGrantAssetsToTrade={feeGrantAssetsToTrade}
              setTitle={setTitle}
              tradeInfo={tradeInfo}
              setTradeInfo={setTradeInfo}
              isTransactionStarts={isTransactionStarts}
              setIsTransactionStarts={setIsTransactionStarts}
            />
          )}
        </section>
      </div>
    </MainLayout>
  );
}

export default Trade;
